import axios from "axios";
import store from "./index";
import deploymentConfiguration from "../../configuration/load.js";

class Api {
  constructor() {
    //this.host = location.origin;
    this.host = deploymentConfiguration.api_base_url || location.origin;
  }
  /**
   * Send a HTTP request to the given route.
   *
   * @param {string} requestType
   * @param {string} route
   * @param {object} payload data
   * @param {object} ajaxConfig axios configuration
   * @param {object} headers additional headers
   */
  request(requestType, route, data = {}, ajaxConfig = {}, headers = {}, repeat = true) {
    const accessToken = store.getters.accessToken;
    let xtoken;
    if (accessToken) {
      xtoken = `Bearer ${accessToken}`;
    }

    const config = {
      headers: {
        "x-api-key": store.getters.xApiKey,
        "x-token": xtoken, 
        ...headers,
      },
      ...ajaxConfig,
    };
    console.log("config", config);
    return new Promise((resolve, reject) => {
      let promise;
      if (requestType === "get") {
        promise = axios[requestType](this.host + route, config);
      } else {
        promise = axios[requestType](this.host + route, data, config);
      }
      promise
        .then((response) => {
          resolve(response.data);
        })
        .catch(async (error) => {
          console.log(error);
          if (this.didTokenExpired(error) && repeat) {
            try {
              await this.renewToken();
              return this.request(requestType, route, data, ajaxConfig, headers, false);
            } catch {
              store.commit("setAccessToken", "");
              store.commit("setRefreshToken", "");
              store.commit("setApiKey", "");
              localStorage.clear();
              reject(error.response.data);
            }
          }
          reject(error.response.data);
        });
    });
  }

  didTokenExpired(error) {
    return error.response.status === 401
     && typeof error.response.data === 'string' 
     && error.response.data === "token expired"; 
  }

  async renewToken() {  
    const res = await axios.post("/api/auth/token", {token: store.getters.refreshToken});
    if (res.status === 200) {
      store.commit("setAccessToken", res.data.accessToken);
      store.commit("setRefreshToken", res.data.refreshToken);
    }
    else throw "bad token renew";
  }

  /**
   * Send a GET request to the given route.
   * .
   * @param {string} route
   */

  get(route) {
    return this.request("get", route);
  }

  /**
   * Send a POST request to the given route.
   * .
   * @param {string} route
   */
  post(route, data) {
    return this.request("post", route, data);
  }

  /**
   * Send a PUT request to the given route.
   * .
   * @param {string} route
   * @param {object} data
   */
  put(route, data) {
    return this.request("put", route, data);
  }

  /**
   * Send a PATCH request to the given route.
   * .
   * @param {string} route
   * @param {object} data
   */
  patch(route, data) {
    return this.request("patch", route, data);
  }

  /**
   * Send a DELETE request to the given route.
   * .
   * @param {string} route
   * @param {object} data
   */
  delete(route, data) {
    return this.request("delete", route, data);
  }
}

const api = new Api();
Object.seal(api);

export default api;
