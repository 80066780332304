<template>
  <div class="login flex-row">
      <form class="flex-column" @input="cleanErrors">
        <h1>Přihlášení</h1>
        <div class="flex-row">
            <label>Jméno</label>
            <input v-model="user.username" type="text">
        </div>
        <span class="error" v-show="hasError('username')" v-text="getError('username')"></span>

        <div class="flex-row">
            <label>Heslo</label>
            <input v-model="user.password" type="password">
        </div>
        <span class="error" v-show="hasError('password')" v-text="getError('password')"></span>
        <button @click.prevent="onLogin()" class="primary">přihlásit</button>
      </form>
  </div>
</template>

<script>
import api from "../store/api";
import {mapMutations} from 'vuex';
export default {
    data() {
        return {
            user: {
                username: "",
                password: "",
            },
            errors: {
            }
        };
    },
    methods: {
        onLogin() {
            api.post("/api/auth/login", this.user)
            .then(data => {
                this.setRefreshToken(data.refreshToken);
                this.setAccessToken(data.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken)
                localStorage.setItem('accessToken', data.accessToken)
                localStorage.setItem('configuratorOwner', 'gservis');
                this.setOwner("gservis");
                this.$store.dispatch("LOAD_STATIC_DATA");
            }).catch(errors => this.errors = errors);
        },
        hasError(param) {
            return this.errors[param];
        },
        getError(param) {
            return this.errors[param];
        },
        cleanErrors() {
            this.errors = {};
        },
        ...mapMutations(["setRefreshToken", "setAccessToken", "setOwner"])
    }
}
</script>

<style lang="scss" scope>
.login {
    justify-content: center;
    form {
        //justify-content: center;
        h1 {
            font-size: 4em;
            font-weight: 400;
        }
        .flex-row {
            //justify-content: center;
            label {
                font-size: 1.4em;
                flex: 1;
            }
            input {
                line-height: 30px;
                flex: 1;
            } 
        }
        .error {
            padding: 5px 10px ;
            color: red;
        }
        button {
            width: 300px
        }
    }
}
</style>