<template>
  <div class="manufacturers">
    <h2>Přidat výrobce</h2>
    <div class="value-pair">
      <h3>Jméno výrobce:</h3>
      <input type="text" v-model="name" />
    </div>
    <div class="value-pair">
      <h3>Logo výrobce:</h3>
      <input type="text" v-model="logo" />
    </div>
    <button class="primary" @click="addManufacturer">přidat</button>
    
    <div v-for="man in manufacturers" :key="man.id" v-show="man.name">
      <span v-text="man.index"></span>.
      <span v-text="man.name"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../store/api";
export default {
  components: {},
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const manufacturers = await api.get("/api/helper/manufacturers");
      this.$store.commit("setManufacturers", manufacturers);
    },
    async addManufacturer() {
        const data = {
            name: this.name,
            logo: this.logo,
        };
        const res = await api.post("/api/helper/add-manufacturer", data);
        res;
        this.load();
    }
  },
  computed: {
    ...mapGetters(["manufacturers"]),
  },
  data() {
      return {
          name: "",
          logo: "https://g-servis-dek-configurator.dejmas.cz/logos/DEK.png",
      };
  }
};
</script>

<style lang="scss" scoped>
.value-pair {
    display: flex;
    h3 {
        width: 200px;
        line-height: 0.9em;
    }
}
</style>