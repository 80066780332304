//const { updateWhatever } = require("../utils");

const buildings = {
  namespaced: true,
  state: () => ({
    house: {
      totalArea: 0,
      storeys: [
        {
          name: "Prizemi",
          rooms: [
            {
              identifier: "living room",
              action: {
                command: null,
                params: {},
              },
              name: "Obývací pokoj",
              price: 6000,
              area: 24,
              lock: false,
              id: 103,
            },
          ],
        },
      ],
    },
    detachedGarage: {
      present: false,
      area: 0,
      storeys: [
        {
          name: "",
          area: 0,
          rooms: [],
        },
      ],
    },
  }),
  getters: {
    house: (state) => state.house,
    hasGarage: (state) => state.detachedGarage.present,
    detachedGarage: (state) => state.detachedGarage,
  },
  mutations: {
    set(state, newState) {
      state.house = newState.house;
      state.detachedGarage = newState.detachedGarage;
    },
    unlockStorey(state) {
      state.house.storeys.forEach(storey => {
        storey.lock = false;
      });
    },
    editRoom(state, { room, value }) {
      state.house.storeys.forEach((storey) => {
        storey.rooms.forEach((r) => {
          if (r.id === room.id) {
            r.action = { command: "resize", params: { newSize: value } };
          }
        });
      });
    },
    editGarageRoom(state, { room, value }) {
      state.detachedGarage.storeys.forEach((storey) => {
        storey.rooms.forEach((r) => {
          if (r.id === room.id) {
            r.action = { command: "resize", params: { newSize: value } };
          }
        });
      });
    },
    redoRoom(state, room) {
      state.house.storeys.forEach((storey) => {
        storey.rooms.forEach((r) => {
          if (r.id === room.id) {
            r.lock = false;
          }
        });
      });
    },
    redoGarageRoom(state, room) {
      state.detachedGarage.storeys.forEach((storey) => {
        storey.rooms.forEach((r) => {
          if (r.id === room.id) {
            r.lock = false;
          }
        });
      });
    },
    removeRoom(state, room) {
      state.house.storeys.forEach((storey) => {
        storey.rooms.forEach((r) => {
          if (r.id === room.id) {
            //r.lock = false;
            //r.area = 0;
            r.action = { command: "remove", params: {}};
            //console.log("Assigned delete for", r);
          }
        });
      });
    },
    moveRoom(state, {room, target}) {
      state.house.storeys.forEach((storey) => {
        storey.rooms.forEach((r) => {
          if (r.id === room.id) {
            //r.lock = false;
            //r.area = 0;
            r.action.params = {
                target: target
            } 
            r.action.command = "move";
            //r.action.params.target = target;
            //console.log("Assigned move for", r, "to", target);
          }
        });
      });
    },
    removeGarageRoom(state, room) {
      state.detachedGarage.storeys.forEach((storey) => {
        storey.rooms.forEach((r) => {
          if (r.id === room.id) {
            //r.lock = false;
            //r.area = 0;
            r.action = { command: "remove" };
            //console.log("Assigned delete for", r);
          }
        });
      });
    },
  },
};

export default buildings;
