const recommandedHouses = {
    namespaced: true,
    state: () => ({
      recommandedHouses: []
    }),
    getters: {
      recommandedHouses: (state) => state.recommandedHouses,
      feedBuildingStyleLikeOptions: (state) => {
        const result = [];
        state.recommandedHouses.forEach((elem) => {
          const img = {
            name: "",
            image: elem.img,
            value: elem.id,
          };
          result.push(img);
        });
        return result;
      },
      previewHouse: (state, getters, rootState, rootGetters) => {
        if (state.recommandedHouses.length) {
          //console.log(rootGetters);
          const userClick =
            rootGetters["configuration/architecture/buildingStyleLike"];
          //console.log("userClick", userClick);
          const userHouse = state.recommandedHouses.filter(
            (house) => house.id === userClick
          );
          let { img, id } = state.recommandedHouses[0];
          if (userHouse.length > 0) {
            img = userHouse[0].img;
            id = userHouse[0].id;
          }
  
          return {
            image: img,
            value: id,
          };
        }
        return {
          image: "",
          value: "",
        };
      },
    },
  
    mutations: {
      updateRecommandedHouses(state, recommandedHouses) {
        state.recommandedHouses = recommandedHouses;
      },
    }
  };

  export default recommandedHouses;