<template>
  <div class="visualization">
    <VisualizationPopup v-if="false" />
    <img src="@/assets/results/visualization.png" />
  </div>
</template>

<script>
import VisualizationPopup from "./VisualizationPopup";

export default {
  data() {
    return {};
  },
  components: {
    VisualizationPopup,
  },
};
</script>

<style lang="scss">
.visualization {
  img {
    width: 100%;
    margin-top: 10px;
  }
}
</style>