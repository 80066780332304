<template>
  <div>   
    <nav class="main-navbar">
      <div class="row">
        <div
          class="hamburger-button"
          :class="{ close: showMenu }"
          @click.stop="toggleMenu"
        >
          <div class="menu-line"></div>
          <div class="menu-line"></div>
          <div class="menu-line"></div>
        </div>
        <div class="rest">
          <h1 class="app-name">
            <span v-text="getText('appName')"></span>
            {{ configuratorOwner.toUpperCase() }} 
            <span v-show="getText('technicalVersion')">
              (<span v-text="getText('technicalVersion')"></span>)
            </span>
          </h1>
        </div>
        <div class="diagram">
          <MyDialog :heading="heading" :show="showDialog" @input="showDialog = false" >
            <div v-if="dialogState == 'opened'">
              <button @click="onSave" class="primary">Uložit konfiguraci</button> <br />
              <button @click="onSetInitialConfiguration" class="primary">Použít jako počáteční konfiguraci</button>
            </div>
            <div v-else-if="dialogState == 'loading'">
              <LoadingComponent />
            </div>
            <div v-else-if="dialogState == 'done'">
              <div v-if="success" v-show="url">
                <p v-text="getText('storeConfiguration.ok')"></p>
                <div class="code flex-row" >
                  <p v-text="url"></p> 
                  <i @click="copyToClip(url)" class="fas fa-copy"></i>
                </div>
              </div>
              <div v-else>
                <p v-text="getText('storeConfiguration.fail')"></p>
                <p v-text="error"></p>
              </div>
            </div>
            <button @click="showDialog = false" class="primary" v-text="getText('ok')"></button>
          </MyDialog>
          <i @click="openSavingDialog" class="fa fa-save"></i>
        </div>
        <div v-show="false" class="help">
          <i class="fas fa-question-circle"></i>
        </div>
        <div @click="logout" v-show="refreshToken" class="diagram logout">
          odhlásit <i class="fas fa-sign-out-alt"></i>
        </div>
      </div>
    </nav>
    <div class="menu-dropdown" v-show="refreshToken" :class="{ show: showMenu }">
      <div class="menu-list">
        <router-link class="menu-item" to="/cards">Správa karet</router-link>
        <router-link class="menu-item" to="/backups">Změny a zálohy</router-link>
        <router-link class="menu-item" to="/settings">Nastavení aplikace</router-link>
        <router-link class="menu-item" to="/clone">Klonovat konfigurátor</router-link>
        <router-link class="menu-item" to="/texts">Systém textů</router-link>
        <router-link class="menu-item" to="/manufacturers">Výrobci</router-link>
        <a class="menu-item" href="https://configurator-doc.deksoft.eu/"
          >Dokumentace</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from '../store/api';
import MyDialog from './components/MyDialog.vue';
import LoadingComponent from './LoadingComponent.vue';
export default {
  components: { MyDialog, LoadingComponent },
  name: "TopNavbar",
  data() {
    return {
      showDialog: false,
      dialogState: "",
      heading: "",
      success: false,
      url: null,
      error: "",
      showMenu: false,
    };
  },
  created() {
    document.addEventListener('click', this.hideMenu);
  },
  methods: {
    openSavingDialog() {
      this.showDialog = true;
      this.dialogState = 'opened';
      this.heading = "Možnosti uložení konfigurace"
    },
    
    onSave() {
      this.dialogState = 'loading';
      const state = {
        configuration: this.$store.state.configuration,
        cards: this.$store.state.cards,
        configuratorOwner: this.$store.state.configuratorOwner,
        price: this.$store.state.result.computations.price,
        recommandedHouses: this.$store.state.recommandedHouses.recommandedHouses
      };
      api
        .post("/api/save-state", state)
        .then(data => {
          this.url = `${location.origin}/${this.configuratorOwner}/load/${data.hash}`;
          this.showDialog = true;
          this.heading = "Stav je uložen";
          this.success = true;
          this.dialogState = 'done';
        })
        .catch((error) => {
          this.showDialog = true;
          this.heading = "Stav se nepodařilo uložit";
          this.error = '' + error;
          this.success = false;
          this.dialogState = 'done';
        });
    },
    onSetInitialConfiguration() {
      this.dialogState = 'loading';
      const setting = {
        data: this.$store.state.configuration,
        name: 'initialConfiguration'
      };
      api
        .post("/api/maintenance/settings-save", setting)
        .then(() => {
          this.showDialog = true;
          this.heading = "Stav je uložen";
          this.success = true;
          this.dialogState = 'done';
        })
        .catch((error) => {
          this.showDialog = true;
          this.heading = "Stav se nepodařilo uložit";
          this.error = '' + error;
          this.success = false;
          this.dialogState = 'done';
        });
    },
    copyToClip(data) {
      const tempInput = document.createElement("input");
      tempInput.value = data;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    hideMenu() {
      if (this.showMenu) {
        this.showMenu = false;
      }
    },
    ...mapActions(["logout"])
  },
  computed: { ...mapGetters(["configuratorOwner", "refreshToken", 'getText']) },
  watch: {
    $route() {
      this.showMenu = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$navbar-padding: 8px;
$top-menu-height: 56px;
.main-navbar {
  background: $primary-color;
  height: $top-menu-height;
  margin: 0;

  .row {
    height: $top-menu-height;
    display: flex;

    .hamburger-button {
      margin: auto 19px;
      width: 18px;
      height: 12px;
      cursor: pointer;
      .menu-line {
        width: 100%;
        height: 2px;
        margin: 0px 0 3px 0;
        background: white;
        transition: all 300ms ease-out;
      }

      &.close .menu-line:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }

      &.close .menu-line:nth-child(2) {
        height: 2px;
        opacity: 0;
      }

      &.close .menu-line:nth-child(3) {
        transform: translateY(-5px) rotate(-45deg);
      }
    }

    .rest {
      flex-grow: 1;
      text-align: center;
      align-self: center;
      h1 {
        //font-size: 1.33em;
        font: 500 20px/26px Roboto;
        //font-weight: 500;
        margin: 0 0;
        color: white;
      }
    }

    .diagram,
    .help {
      align-self: center;
      margin-right: 19px;
      color: white;
    }
    .help > i {
      font-size: 19px;
    }
    .code {
      background-color: rgb(104, 101, 101);
      p {
        color: white;
      }
      padding: 8px;
      margin-bottom: 16px;
      .fa-copy {
        font-size: 1.2em;
        color: $primary-color;
        &:hover {
          color:lighten($color: $primary-color, $amount: 20%);
          cursor: pointer;
        }
      }
    }
    .logout,
    .diagram > i {
      font-size: 19px;
      &:hover {
        text-shadow: 0 0 5px rgb(255, 210, 199);
        cursor: pointer;
      }
      //transform: rotateZ(-30deg) translateY(5px);
    }
  }
}
div.menu-dropdown {
  transition: transform 200ms ease-out 0ms;
}
.menu-dropdown {
  position: absolute;
  padding: 12px;
  z-index: 100;
  background: white;
  transform: translateX(-100%);
  border: solid 1px $primary-color;

  &.show {
    transform: translateX(0);
  }

  .menu-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .menu-item {
      padding: 12px;
      color: $primary-color;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: white;
        background: $primary-color;
      }
    }
  }
}
</style>
