<template>
  <div class="detail-diff">
    <div :key="k" v-for="(v, k) in diff" class="diff-item">
      <div v-if="v.op == 'update'" class="update">
        <span class="key" v-text="k"></span>:
        <strike class="old">{{ v.old }}</strike>
        <span class="new" v-text="v.new"></span>
        <!-- <span class="key" v-text="v.path.join('')"></span>:
        <span class="key" v-text="v.val"></span>: -->

      </div>
      <div v-else-if="v.op == 'new'" class="new">
        <span class="key new" v-text="k"></span>:
        <span class="new" v-text="v.new"></span>
      </div>
      <div v-else-if="v.op == 'del'" class="del">
        <span class="key old" v-text="k"></span>:
        <strike class="old">{{ v.old }}</strike>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    diff: Object,
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style lang="scss">
.detail-diff {
  padding-left: 25px;
  .diff-item {
    margin: 3px 0 3px 0;
    background: cornsilk;
    color: black;
    .key {
      font-weight: bold;
    }
    strike {
      background: lightcoral;
      
    }
    .new {
      background: lightgreen;
    }
  }
}
</style>
