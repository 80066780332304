<template>
  <div class="energetics-traffic-lights">
    <div class="text">
      <p v-text="getText('energetics.legalCertification.version')"></p>
      <select
        :value="NZEBLevel"
        @input="setNZEBLevel($event.target.value)"
      >
        <option v-for="level in NZEBLevelOptions" :key="level" :value="level" v-text="level"></option>
      </select>
      <h1 class="title" v-text="getText(trafficLights.messageTextId)"></h1>
    </div>
    <div class="lights">
        <div class="gap"></div>
        <div class="light" :style="{background: colorIdToColor(trafficLights.lightTop)}">

        </div>
        <div class="light" :style="{background: colorIdToColor(trafficLights.lightMid)}">
          
        </div>
        <div class="light" :style="{background: colorIdToColor(trafficLights.lightBot)}">
          
        </div>
        <img class="template" :src="require('@/assets/traffic-light.png')" alt="">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    props: {trafficLights : {type: Object}},
    computed: {
      ...mapGetters("configuration/user", ["NZEBLevel"]),
      ...mapGetters(["getText"]),
    },
    methods: {
      ...mapMutations("configuration/user", ["setNZEBLevel"]),
      colorIdToColor(colorId) {
        return {
          0: 'black',
          1: 'green',
          2: 'yellow',
          3: 'red',
        }[colorId];
      },
    },
    data() {
      return {
        NZEBLevelOptions: [
          "NZEB I",
          "NZEB II"
        ]
      };
    }
}
</script>

<style lang="scss" scope>
.energetics-traffic-lights {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  .text {
    h1 {
      font: normal normal normal 20px/26px Roboto;
    }
  }
  .lights {
    width: 128px;
    position: relative;
    .template {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 150px;
    }
    .gap {
      height: 15px;
    }
    .light {
      margin: 0 auto;
      width: 45px;
      height: 45px;
    }
  }
}
</style>