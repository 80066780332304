<template>
  <div class="admin">
    <div class="card-management">
      <h1>Správa karet {{ configuratorOwner.toUpperCase() }}</h1>

      <CardView @edit="handleEdit" />

      <form
        class="new-card"
        @change.stop="clearErrors()"
        id="card-form"
      >
        <h2 class="subtitle" v-if="!newCard._id">VYTVOŘIT KARTU</h2>
        <h2 class="subtitle" v-else>EDITACE KARTY (id {{ newCard._id }})</h2>

        <div class="flex-column">
          <label>Záložka</label>
          <select
            v-model="newCard.superCategory"
            :class="{ custom: custom.superCategory }"
          >
            <option
              v-for="tab in staticData.tabs"
              :value="tab.value"
              :key="tab.value"
              v-text="tab.name"
            ></option>
          </select>
          <span
            class="error"
            v-show="hasError('superCategory')"
            v-text="getError('superCategory')"
          ></span>
        </div>

        <div class="flex-column">
          <label>Kategorie</label>
          <select
            v-model="newCard.category"
            :class="{ custom: custom.category }"
          >
            <option
              v-for="category in getCategories()"
              :value="category.value"
              :key="category.value"
              v-text="category.name"
            ></option>
          </select>
          <span
            class="error"
            v-show="hasError('category')"
            v-text="getError('category')"
          ></span>
        </div>

        <div class="flex-column">
          <label>Název</label>
          <input type="text" v-model="name" :class="{ custom: custom.name }" />
          <span
            class="error"
            v-show="hasError('name')"
            v-text="getError('name')"
          ></span>
        </div>

        <div class="flex-column">
          <label>Popis</label>
          <input
            type="text"
            v-model="brief"
            :class="{ custom: custom.brief }"
          />
          <span
            class="error"
            v-show="hasError('brief')"
            v-text="getError('brief')"
          ></span>
        </div>
        <div class="flex-column">
          <label>Hlavní dodavatel</label>
          <select
            v-model="manufacturerRef"
            :class="{ custom: custom.manufacturer }"
          >
            <option
              v-for="manufacturer in manufacturers"
              :value="manufacturer.index"
              :key="manufacturer.index"
              v-text="manufacturer.name"
            />
          </select>
          <span
            class="error"
            v-show="hasError('manufacturer')"
            v-text="getError('manufacturer')"
          ></span>
        </div>

        <div class="flex-column">
          <label>Priorita</label>
          <input type="number" v-model="newCard.priority" class="custom" />
          <span
            class="error"
            v-show="hasError('priority')"
            v-text="getError('priority')"
          ></span>
        </div>

        <div class="flex-column">
          <label>Body za environmentálně šetrné řešení</label>
          <input type="number" v-model="newCard.environmentalScore" class="custom" />
          <span
            class="error"
            v-show="hasError('environmentalScore')"
            v-text="getError('environmentalScore')"
          ></span>
        </div>

        <!-- <div class="flex-column">
          <label>dlouhý popis</label>
          <textarea
            type="text"
            v-model="description"
            :class="{ custom: custom.description }"
          />
          <span
            class="error"
            v-show="hasError('description')"
            v-text="getError('description')"
          ></span>
        </div> -->

        <!--         <div class="flex-column">
          <label>url</label>
          <input type="text" v-model="url" :class="{ custom: custom.url }" />
          <span
            class="error"
            v-show="hasError('url')"
            v-text="getError('url')"
          ></span>
        </div> -->

        <!--       <div class="flex-column">
          <label>cena</label>
          <input
            type="text"
            v-model="price"
            :class="{ custom: custom.price }"
          />
          <span
            class="error"
            v-show="hasError('price')"
            v-text="getError('price')"
          ></span>
        </div> -->

        <h2>Filtry</h2>

        <MySection name="Wienerbergerovská třída" v-show="isWienerberger()">
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.wienerbergerClassOptions"
            v-model="newCard.meta.wienerbergerClass"
            @input.capture="clearErrors()"
          />

          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.wienerbergerClass')"
              v-text="getError('meta.wienerbergerClass')"
            ></span>
          </template>
        </MySection>

        <MySection name="Typ domu" v-show="isActive('houseType')">
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.houseTypeOptions"
            v-model="newCard.meta.houseType"
            @input.capture="clearErrors()"
          />

          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.houseType')"
              v-text="getError('meta.houseType')"
            ></span>
          </template>
        </MySection>

        <MySection name="Technické řešení" v-show="isActive('technicalSolution')">
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.technicalSolutionOptions"
            v-model="newCard.meta.technicalSolution"
            @input.capture="clearErrors()"
          />

          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.technicalSolution')"
              v-text="getError('meta.technicalSolution')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Úroveň tepelné izolace konstrukcí"
          v-show="isActive('thermalInsulationLevel')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.thermalInsulationLevelOptions"
            v-model="newCard.meta.thermalInsulationLevel"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.thermalInsulationLevel')"
              v-text="getError('meta.thermalInsulationLevel')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Konstrukční systém"
          v-show="isActive('constructionSystem')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.constructionSystemOptions"
            v-model="newCard.meta.constructionSystem"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.constructionSystem')"
              v-text="getError('meta.constructionSystem')"
            ></span>
          </template>
        </MySection>

        <MySection name="Materiál oken" v-show="isActive('windowMaterial')">
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.windowMaterialOptions"
            v-model="newCard.meta.windowMaterial"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.windowMaterial')"
              v-text="getError('meta.windowMaterial')"
            ></span>
          </template>
        </MySection>

        <MySection name="Typ stínící techniky" v-show="isActive('windowShading')">
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.windowShadingOptions"
            v-model="newCard.meta.windowShading"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.windowShading')"
              v-text="getError('meta.windowShading')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Tvar střechy"
          v-show="isActive('roofType')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.roofTypeOptions"
            v-model="newCard.meta.roofType"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.roofType')"
              v-text="getError('meta.roofType')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Střešní krytina-šikmá střecha"
          v-show="isActive('slopingRoofCoveringType')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.slopingRoofCoveringTypeOptions"
            v-model="newCard.meta.slopingRoofCoveringType"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.slopingRoofCoveringType')"
              v-text="getError('meta.slopingRoofCoveringType')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Střešní krytina-plochá střecha"
          v-show="isActive('flatRoofCoveringType')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.flatRoofCoveringTypeOptions"
            v-model="newCard.meta.flatRoofCoveringType"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.flatRoofCoveringType')"
              v-text="getError('meta.flatRoofCoveringType')"
            ></span>
          </template>
        </MySection>

        <MySection name="Sklon střechy" v-show="isActive('roofSlope')">
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.roofSlopeOptions"
            v-model="newCard.meta.roofSlope"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.roofSlope')"
              v-text="getError('meta.roofSlope')"
            ></span>
          </template>
        </MySection>

        <!--  HVAC Filters  -->

        <MySection
          name="Primární zdroj"
          v-show="isActive('heatingPrimarySource')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.heatingPrimarySourceOptions"
            v-model="newCard.meta.heatingPrimarySource"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.heatingPrimarySource')"
              v-text="getError('meta.heatingPrimarySource')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Typ primárního zdroje (pro zroj elektřina)"
          v-show="isActive('heatingPrimarySourceType')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.heatingPrimarySourceTypeOptions"
            v-model="newCard.meta.heatingPrimarySourceType"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.heatingPrimarySourceType')"
              v-text="getError('meta.heatingPrimarySourceType')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Převažující otopná tělesa"
          v-show="isActive('heatingMainEmitter')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.heatingMainEmitterOptions"
            v-model="newCard.meta.heatingMainEmitter"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.heatingMainEmitter')"
              v-text="getError('meta.heatingMainEmitter')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Sekundární zdroj"
          v-show="isActive('heatingSecondarySource')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.heatingSecondarySourceOptions"
            v-model="newCard.meta.heatingSecondarySource"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.heatingSecondarySource')"
              v-text="getError('meta.heatingSecondarySource')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Typ fotovoltaického systému"
          v-show="isActive('photovoltaicSystemType')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.photovoltaicSystemTypeOptions"
            v-model="newCard.meta.photovoltaicSystemType"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.photovoltaicSystemType')"
              v-text="getError('meta.photovoltaicSystemType')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Typ fotovoltaického systému"
          v-show="isActive('wastewaterHeatRecoveryType')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.wastewaterHeatRecoveryTypeOptions"
            v-model="newCard.meta.wastewaterHeatRecoveryType"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.wastewaterHeatRecoveryType')"
              v-text="getError('meta.wastewaterHeatRecoveryType')"
            ></span>
          </template>
        </MySection>

        <!-- Surroundings -->
        <MySection
          name="Typ konstrukce bazénu"
          v-show="isActive('swimmingPoolConstructionType')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.swimmingPoolConstructionTypeOptions"
            v-model="newCard.meta.swimmingPoolConstructionType"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.swimmingPoolConstructionType')"
              v-text="getError('meta.swimmingPoolConstructionType')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Typ konstrukce oplocení"
          v-show="isActive('fenceConstructionType')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.fenceConstructionTypeOptions"
            v-model="newCard.meta.fenceConstructionType"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.fenceConstructionType')"
              v-text="getError('meta.fenceConstructionType')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Typ výplně oplocení"
          tab="surroundings"
          v-show="
            isActive('fenceFillingType') &&
              newCard.meta.fenceConstructionType.includes('combined wall fence')
          "
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.fenceFillingTypeOptions"
            v-model="newCard.meta.fenceFillingType"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.fenceFillingType')"
              v-text="getError('meta.fenceFillingType')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Způsob otevírání"
          tab="surroundings"
          v-show="
            isActive('gateOpener')
          "
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.gateOpenerOptions"
            v-model="newCard.meta.gateOpener"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.gateOpener')"
              v-text="getError('meta.gateOpener')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Materiál zpevněných ploch"
          v-show="isActive('pavedSurfaceMaterial')"
          v-if="isGservis()"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.pavedSurfaceMaterialOptions"
            v-model="newCard.meta.pavedSurfaceMaterial"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.pavedSurfaceMaterial')"
              v-text="getError('meta.pavedSurfaceMaterial')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Materiál zpevněných ploch"
          v-show="isActive('pavedSurfaceMaterial')"
          v-else
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.pavedSurfaceMaterialOldOptions"
            v-model="newCard.meta.pavedSurfaceMaterial"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.pavedSurfaceMaterial')"
              v-text="getError('meta.pavedSurfaceMaterial')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Typ zpevněných beronových ploch"
          v-show="isActive('pavedSurfaceConcretePavingType')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.pavedSurfaceConcretePavingTypeOptions"
            v-model="newCard.meta.pavedSurfaceConcretePavingType"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.pavedSurfaceConcretePavingType')"
              v-text="getError('meta.pavedSurfaceConcretePavingType')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Materiál dětského hřiště"
          v-show="isActive('playgroundMaterial')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.playgroundMaterialOptions"
            v-model="newCard.meta.playgroundMaterial"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.playgroundMaterial')"
              v-text="getError('meta.playgroundMaterial')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Maximální počet dětí"
          v-show="isActive('playgroundMaxNumberOfChildren')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.playgroundMaxNumberOfChildrenOptions"
            v-model="newCard.meta.playgroundMaxNumberOfChildren"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.playgroundMaxNumberOfChildren')"
              v-text="getError('meta.playgroundMaxNumberOfChildren')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Materál zahradního domku"
          v-show="isActive('gardenHouseMaterial')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.gardenHouseMaterialOptions"
            v-model="newCard.meta.gardenHouseMaterial"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.gardenHouseMaterial')"
              v-text="getError('meta.gardenHouseMaterial')"
            ></span>
          </template>
        </MySection>

        <MySection
          name="Velikost zahradního domku"
          v-show="isActive('gardenHouseSize')"
        >
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.gardenHouseSizeOptions"
            v-model="newCard.meta.gardenHouseSize"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.gardenHouseSize')"
              v-text="getError('meta.gardenHouseSize')"
            ></span>
          </template>
        </MySection>

        <MySection name="Materíál pergoly" v-show="isActive('pergolaMaterial')">
          <MultipleChoiceArray
            multiple-answer
            :options="staticData.pergolaMaterialOptions"
            v-model="newCard.meta.pergolaMaterial"
            @input.capture="clearErrors()"
          />
          <template v-slot:footer>
            <span
              class="error"
              v-show="hasError('meta.pergolaMaterial')"
              v-text="getError('meta.pergolaMaterial')"
            ></span>
          </template>
        </MySection>

        <h2 class="subtitle">Prerekvizity</h2>
        <PrerequisityList :prerequisites="newCard.prerequisites" />

        <h2 class="subtitle">Přiřazené konstrukce ze stavební knihovny</h2>

        <ProductView
          v-show="isGoodsActive('externalWalls')"
          :title="goodsTitle('externalWalls')"
          subCategory="externalWalls"
          :list="newCard.products.externalWalls"
          v-model="newCard.defaultProducts.externalWalls"
        />
        <ProductView
          v-show="isGoodsActive('internalBearingWalls')"
          :title="goodsTitle('internalBearingWalls')"
          subCategory="internalBearingWalls"
          :list="newCard.products.internalBearingWalls"
          v-model="newCard.defaultProducts.internalBearingWalls"
        />
        <ProductView
          v-show="isGoodsActive('partition')"
          :title="goodsTitle('partition')"
          subCategory="partition"
          :list="newCard.products.partition"
          v-model="newCard.defaultProducts.partition"
        />
        <ProductView
          v-show="isGoodsActive('internalFinishPlaster')"
          :title="goodsTitle('internalFinishPlaster')"
          subCategory="internalFinishPlaster"
          :list="newCard.products.internalFinishPlaster"
          v-model="newCard.defaultProducts.internalFinishPlaster"
        />
        <ProductView
          v-show="isGoodsActive('internalFinishCladding')"
          :title="goodsTitle('internalFinishCladding')"
          subCategory="internalFinishCladding"
          :list="newCard.products.internalFinishCladding"
          v-model="newCard.defaultProducts.internalFinishCladding"
        />
        <ProductView
          v-show="isGoodsActive('slopingRoofStructure')"
          :title="goodsTitle('slopingRoofStructure')"
          subCategory="slopingRoofStructure"
          :list="newCard.products.slopingRoofStructure"
          v-model="newCard.defaultProducts.slopingRoofStructure"
        />
        <ProductView
          v-show="isGoodsActive('windowVertical')"
          :title="goodsTitle('windowVertical')"
          subCategory="windowVertical"
          :list="newCard.products.windowVertical"
          v-model="newCard.defaultProducts.windowVertical"
        />
        <ProductView
          v-show="isGoodsActive('windowShading')"
          :title="goodsTitle('windowShading')"
          subCategory="windowShading"
          :list="newCard.products.windowShading"
          v-model="newCard.defaultProducts.windowShading"
        />
        <ProductView
          v-show="isGoodsActive('entranceDoor')"
          :title="goodsTitle('entranceDoor')"
          subCategory="entranceDoor"
          :list="newCard.products.entranceDoor"
          v-model="newCard.defaultProducts.entranceDoor"
        />
        <ProductView
          v-show="isGoodsActive('wallFinishExternal')"
          :title="goodsTitle('wallFinishExternal')"
          subCategory="wallFinishExternal"
          :list="newCard.products.wallFinishExternal"
          v-model="newCard.defaultProducts.wallFinishExternal"
        />
        <ProductView
          v-show="isGoodsActive('groundFloor')"
          :title="goodsTitle('groundFloor')"
          subCategory="groundFloor"
          :list="newCard.products.groundFloor"
          v-model="newCard.defaultProducts.groundFloor"
        />
        <ProductView
          v-show="isGoodsActive('groundFloorWithFloorHeating')"
          :title="goodsTitle('groundFloorWithFloorHeating')"
          subCategory="groundFloorWithFloorHeating"
          :list="newCard.products.groundFloorWithFloorHeating"
          v-model="newCard.defaultProducts.groundFloorWithFloorHeating"
        />
        <ProductView
          v-show="isGoodsActive('slabInternalCeiling')"
          :title="goodsTitle('slabInternalCeiling')"
          subCategory="slabInternalCeiling"
          :list="newCard.products.slabInternalCeiling"
          v-model="newCard.defaultProducts.slabInternalCeiling"
        />
        <ProductView
          v-show="isGoodsActive('subfloorOnTheSlab')"
          :title="goodsTitle('subfloorOnTheSlab')"
          subCategory="subfloorOnTheSlab"
          :list="newCard.products.subfloorOnTheSlab"
          v-model="newCard.defaultProducts.subfloorOnTheSlab"
        />
        <ProductView
          v-show="isGoodsActive('subfloorOnTheSlabWithFloorHeating')"
          :title="goodsTitle('subfloorOnTheSlabWithFloorHeating')"
          subCategory="subfloorOnTheSlabWithFloorHeating"
          :list="newCard.products.subfloorOnTheSlabWithFloorHeating"
          v-model="newCard.defaultProducts.subfloorOnTheSlabWithFloorHeating"
        />
        <ProductView
          v-show="isGoodsActive('flatRoofConstruction')"
          :title="goodsTitle('flatRoofConstruction')"
          subCategory="flatRoofConstruction"
          :list="newCard.products.flatRoofConstruction"
          v-model="newCard.defaultProducts.flatRoofConstruction"
        />
        <ProductView
          v-show="isGoodsActive('floorCoveringDryOperation')"
          :title="goodsTitle('floorCoveringDryOperation')"
          subCategory="floorCoveringDryOperation"
          :list="newCard.products.floorCoveringDryOperation"
          v-model="newCard.defaultProducts.floorCoveringDryOperation"
        />
        <ProductView
          v-show="isGoodsActive('floorCoveringWetOperation')"
          :title="goodsTitle('floorCoveringWetOperation')"
          subCategory="floorCoveringWetOperation"
          :list="newCard.products.floorCoveringWetOperation"
          v-model="newCard.defaultProducts.floorCoveringWetOperation"
        />
        <ProductView
          v-show="isGoodsActive('roofCoveringSlopingRoof')"
          :title="goodsTitle('roofCoveringSlopingRoof')"
          subCategory="roofCoveringSlopingRoof"
          :list="newCard.products.roofCoveringSlopingRoof"
          v-model="newCard.defaultProducts.roofCoveringSlopingRoof"
        />
        <ProductView
          v-show="isGoodsActive('flatRoofInsulatingLayers')"
          :title="goodsTitle('flatRoofInsulatingLayers')"
          subCategory="flatRoofInsulatingLayers"
          :list="newCard.products.flatRoofInsulatingLayers"
          v-model="newCard.defaultProducts.flatRoofInsulatingLayers"
        />
        <ProductView
          v-show="isGoodsActive('skylight')"
          :title="goodsTitle('skylight')"
          subCategory="skylight"
          :list="newCard.products.skylight"
          v-model="newCard.defaultProducts.skylight"
        />
        <ProductView 
          v-show="isGoodsActive('foundationSlab')"
          :title="goodsTitle('foundationSlab')"
          subCategory="foundationSlab"
          :list="newCard.products.foundationSlab"
          v-model="newCard.defaultProducts.foundationSlab"
        />
        <ProductView 
          v-show="isGoodsActive('dampProofLayer')"
          :title="goodsTitle('dampProofLayer')"
          subCategory="dampProofLayer"
          :list="newCard.products.dampProofLayer"
          v-model="newCard.defaultProducts.dampProofLayer"
        />
        <ProductView 
          v-show="isGoodsActive('interiorDoors')"
          :title="goodsTitle('interiorDoors')"
          subCategory="interiorDoors"
          :list="newCard.products.interiorDoors"
          v-model="newCard.defaultProducts.interiorDoors"
        />
        <ProductView 
          v-show="isGoodsActive('garageDoor')"
          :title="goodsTitle('garageDoor')"
          subCategory="garageDoor"
          :list="newCard.products.garageDoor"
          v-model="newCard.defaultProducts.garageDoor"
        />


        <!-- HVAC -->


        <ProductView
          v-show="isGoodsActive('heatingSystem')"
          :title="goodsTitle('heatingSystem')"
          subCategory="heatingSystem"
          :list="newCard.products.heatingSystem"
          v-model="newCard.defaultProducts.heatingSystem"
        />
        <ProductView
          v-show="isGoodsActive('chimney')"
          :title="goodsTitle('chimney')"
          subCategory="chimney"
          :list="newCard.products.chimney"
          v-model="newCard.defaultProducts.chimney"
        />
        <ProductView
          v-show="isGoodsActive('ventilationSystem')"
          :title="goodsTitle('ventilationSystem')"
          subCategory="ventilationSystem"
          :list="newCard.products.ventilationSystem"
          v-model="newCard.defaultProducts.ventilationSystem"
        />
        <ProductView
          v-show="isGoodsActive('coolingSystem')"
          :title="goodsTitle('coolingSystem')"
          subCategory="coolingSystem"
          :list="newCard.products.coolingSystem"
          v-model="newCard.defaultProducts.coolingSystem"
        />
        <ProductView
          v-show="isGoodsActive('photovoltaicSystem')"
          :title="goodsTitle('photovoltaicSystem')"
          subCategory="photovoltaicSystem"
          :list="newCard.products.photovoltaicSystem"
          v-model="newCard.defaultProducts.photovoltaicSystem"
        />

        <ProductView
          v-show="isGoodsActive('wastewaterHeatRecovery')"
          :title="goodsTitle('wastewaterHeatRecovery')"
          subCategory="wastewaterHeatRecovery"
          :list="newCard.products.wastewaterHeatRecovery"
          v-model="newCard.defaultProducts.wastewaterHeatRecovery"
        />

        <ProductView
          v-show="isGoodsActive('chargingStation')"
          :title="goodsTitle('chargingStation')"
          subCategory="chargingStation"
          :list="newCard.products.chargingStation"
          v-model="newCard.defaultProducts.chargingStation"
        />

        

        <!-- Surroundings -->

        <ProductView
          v-show="isGoodsActive('swimmingPool')"
          :title="goodsTitle('swimmingPool')"
          :list="newCard.products.swimmingPool"
          v-model="newCard.defaultProducts.swimmingPool"
        />

        <ProductView
          v-show="isGoodsActive('fence')"
          :title="goodsTitle('fence')"
          :list="newCard.products.fence"
          v-model="newCard.defaultProducts.fence"
        />

        <ProductView 
          v-show="isGoodsActive('gate')"
          :title="goodsTitle('gate')"
          :list="newCard.products.gate"
          v-model="newCard.defaultProducts.gate"
        />

        <ProductView 
          v-show="isGoodsActive('fenceDoor')"
          :title="goodsTitle('fenceDoor')"
          :list="newCard.products.fenceDoor"
          v-model="newCard.defaultProducts.fenceDoor"
        />

        <ProductView
          v-show="isGoodsActive('pavedSurfacePaving')"
          :title="goodsTitle('pavedSurfacePaving')"
          :list="newCard.products.pavedSurfacePaving"
          v-model="newCard.defaultProducts.pavedSurfacePaving"
        />

        <ProductView
          v-show="isGoodsActive('playground')"
          :title="goodsTitle('playground')"
          :list="newCard.products.playground"
          v-model="newCard.defaultProducts.playground"
        />

        <ProductView
          v-show="isGoodsActive('gardenHouse')"
          :title="goodsTitle('gardenHouse')"
          :list="newCard.products.gardenHouse"
          v-model="newCard.defaultProducts.gardenHouse"
        />

        <ProductView
          v-show="isGoodsActive('pergola')"
          :title="goodsTitle('pergola')"
          :list="newCard.products.pergola"
          v-model="newCard.defaultProducts.pergola"
        />

        <div class="section image">
          <h2 v-text="'OBRÁZEK KARTY'" />
          <MultipleChoice
            single-answer
            :options="imageOptions"
            v-model="newCard.image"
            @input.capture="clearErrors()"
          />
          
          <span
            class="error"
            v-show="hasError('image')"
            v-text="getError('image')"
          ></span>
        </div>

        <div class="flex-column">
          <label>OBRÁZEK KARTY</label>
          <input
            type="text"
            v-model="image"
            :class="{ custom: custom.image }"
          />
          <span
            class="error"
            v-show="hasError('image')"
            v-text="getError('image')"
          ></span>
        </div>

        <div class="flex-row-from-right">
          <button
            @click="onSubmit"
            :disabled="submiting"
            class="primary form-button"
            :class="{ progress: submiting }"
          >
            ULOŽIT KARTU
          </button>
          <button
            @click="clearForm"
            :disabled="submiting"
            class="primary form-button"
            :class="{ progress: submiting }"
            v-show="newCard._id"
          >
            ZRUŠIT EDITACI
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../store/api";
import CardView from "./cards/CardView";
import ProductView from "./cards/ProductView";
import PrerequisityList from "./cards/PrerequisityList";
import MySection from "../components/MySection.vue";
import {
  MultipleChoice,
  MultipleChoiceArray,
} from "../components/customElements";
import cardStaticData from "./cards/cardStaticData";
import filterOptions from "./cards/filterOptions";

const CardData = () => {
  return {
    _id: null,
    name: "",
    superCategory: "constructions",
    category: "verticalConstructionTemplate",
    manufacturer: "",
    manufacturerRef: 0,
    brief: "",
    priority: 0,
    environmentalScore: 0,
    image: "",
    meta: {
      wienerbergerClass: [""],
      houseType: [""],
      roofType: [""],
      technicalSolution: [""],
      thermalInsulationLevel: [""],
      constructionSystem: [""],
      windowMaterial: [""],
      windowShading: [""],
      slopingRoofCoveringType: [""],
      flatRoofCoveringType: [""],
      roofSlope: [""],
      // hvac
      heatingPrimarySource: [""],
      heatingPrimarySourceType: [""],
      heatingMainEmitter: [""],
      heatingSecondarySource: [""],
      photovoltaicSystemType: [""],
      wastewaterHeatRecoveryType: [""],
      // surroundings
      swimmingPoolConstructionType: [""],
      fenceConstructionType: [""],
      fenceFillingType: [""],
      gateOpener: [""],
      pavedSurfaceMaterial: [""],
      pavedSurfaceConcretePavingType: [""],
      playgroundMaterial: [""],
      playgroundMaxNumberOfChildren: [""],
      gardenHouseMaterial: [""],
      gardenHouseSize: [""],
      pergolaMaterial: [""],
    },
    products: {
      externalWalls: [],
      internalBearingWalls: [],
      partition: [],
      internalFinishPlaster: [],
      internalFinishCladding: [],
      slopingRoofStructure: [],
      windowVertical: [],
      windowShading: [],
      entranceDoor: [],
      wallFinishExternal: [],
      groundFloor: [],
      groundFloorWithFloorHeating: [],
      slabInternalCeiling: [],
      subfloorOnTheSlab: [],
      subfloorOnTheSlabWithFloorHeating: [],
      flatRoofConstruction: [],
      floorCoveringDryOperation: [],
      floorCoveringWetOperation: [],
      roofCoveringSlopingRoof: [],
      flatRoofInsulatingLayers: [],
      skylight: [],
      foundationSlab: [],
      dampProofLayer: [],
      interiorDoors: [],
      garageDoor: [],
      /// HVAC
      heatingSystem: [],
      chimney: [],
      ventilationSystem: [],
      coolingSystem: [],
      photovoltaicSystem: [],
      wastewaterHeatRecovery: [],
      chargingStation: [],
      /// suroundings
      swimmingPool: [],
      fence: [],
      gate: [],
      fenceDoor: [],
      pavedSurfacePaving: [],
      playground: [],
      gardenHouse: [],
      pergola: [],
    },
    defaultProducts: {
      externalWalls: "",
      internalBearingWalls: "",
      partition: "",
      internalFinishPlaster: "",
      internalFinishCladding: "",
      slopingRoofStructure: "",
      windowVertical: "",
      windowShading: "",
      entranceDoor: "",
      wallFinishExternal: "",
      groundFloor: "",
      groundFloorWithFloorHeating: "",
      slabInternalCeiling: "",
      subfloorOnTheSlab: "",
      subfloorOnTheSlabWithFloorHeating: "",
      flatRoofConstruction: "",
      floorCoveringDryOperation: "",
      floorCoveringWetOperation: "",
      roofCoveringSlopingRoof: "",
      flatRoofInsulatingLayers: "",
      skylight: "",
      foundationSlab: "",
      dampProofLayer: "",
      interiorDoors: "",
      garageDoor: "",
      /// HVAC
      heatingSystem: "",
      chimney: "",
      ventilationSystem: "",
      coolingSystem: "",
      photovoltaicSystem: "",
      wastewaterHeatRecovery: "",
      chargingStation: "",
      /// suroundings
      swimmingPool: "",
      fence: "",
      gate: "",
      fenceDoor: "",
      pavedSurfacePaving: "",
      playground: "",
      gardenHouse: "",
      pergola: "",
    },
    prerequisites: [],
  };
};

export default {
  components: {
    MySection,
    MultipleChoice,
    MultipleChoiceArray,
    CardView,
    ProductView,
    PrerequisityList,
  },
  data() {
    return {
      staticData: {
        ...cardStaticData,
        /*tabs: cardStaticData.tabs,
        categories: cardStaticData.categories,
        categoryToFilters: cardStaticData.categoryToFilters,
        categoryToProducts: cardStaticData.categoryToProducts,
        productsToNames: cardStaticData.productsToNames,
        manufacturers: cardStaticData.manufacturers,*/
        ...filterOptions,
      },
      newCard: CardData(),
      custom: {
        name: true,
        superCategory: true,
        category: true,
        manufacturer: true,
        brief: true,
        description: true,
        price: true,
        image: true,
        url: true,
      },
      errors: {},
      constructions: [],
      loading: false,
      submiting: false,
    };
  },
  mounted() {
    this.$store.dispatch("cards/loadAllCards");
  },
  watch: {},
  computed: {
    ...mapGetters("cards", ["cards"]),
    ...mapGetters("configuration/featureFlags", ["hasChargingStations"]),
    ...mapGetters([
      "configuratorOwner",
      "hasWienerbergerFamily",
      "hasGservisFamily",
      "manufacturers"
    ]),
    name: {
      get() {
        return this.newCard.name;
      },
      set(val) {
        this.newCard.name = val;
        this.custom.name = true;
      },
    },
    manufacturerRef: {
      get() {
        return this.newCard.manufacturerRef;
      },
      set(val) {
        this.newCard.manufacturerRef = val;
        this.manufacturer = this.manufacturers.filter(m => m.index == val)[0].name;
      }
    },
    manufacturer: {
      get() {
        return this.newCard.manufacturer;
      },
      set(val) {
        this.newCard.manufacturer = val;
        this.custom.manufacturer = true;
      },
    },
    brief: {
      get() {
        return this.newCard.brief;
      },
      set(val) {
        this.newCard.brief = val;
        this.custom.brief = true;
      },
    },
    description: {
      get() {
        return this.newCard.description;
      },
      set(val) {
        this.newCard.description = val;
        this.custom.description = true;
      },
    },
    image: {
      get() {
        return this.newCard.image;
      },
      set(val) {
        this.newCard.image = val;
        this.custom.image = true;
      },
    },
    imageOptions() {
      const images = [];
      const used = {};
      Object.keys(this.newCard.products).forEach((goods) => {
        if (this.isGoodsActive(goods)) {
          const collect = this.newCard.products[goods]
            .map((product) => ({
              value: product.image,
              image: product.image,
              name: product.name,
            }))
            .filter((product) => product.image != "");
          
          collect.forEach(item => {
            if (used[item.image]) return;
            used[item.image] = 1;
            images.push(item);
          });
        }
      });
      return images;
    },
  },
  methods: {
    isGservis() {
      return this.hasGservisFamily;
    },
    isWienerberger() {
      return this.hasWienerbergerFamily;
    },
    getCategories() {
      const categories = this.staticData.categories[this.newCard.superCategory];
      return categories.filter(cat => {
        if (!this.hasChargingStations) {
          if (cat.value === 'chargingStationTemplate') return false;
        }
        return true;
      })
    },
    isActive(filter) {
      if (this.isWienerberger() && filter === 'wienerbergerClass') return true;
      return this.staticData.categoryToFilters[this.newCard.category].includes(
        filter
      );
    },
    isGoodsActive(goods) {
      //console.log("isGoodsActive", this.newCard.category, goods)
      return this.staticData.categoryToProducts[this.newCard.category].includes(
        goods
      );
    },
    goodsTitle(goods) {
      return this.staticData.productsToNames[goods];
    },
    onSubmit() {
      this.submiting = true;
      const data = {
        ...this.newCard,
      };
      const meta = {};
      Object.keys(this.newCard.meta).forEach((filter) => {
        if (this.isActive(filter)) {
          meta[filter] = this.newCard.meta[filter];
        }
      });
      data.meta = meta;
      const products = {};
      Object.keys(this.newCard.products).forEach((goods) => {
        if (this.isGoodsActive(goods)) {
          products[goods] = this.newCard.products[goods];
        }
      });
      data.products = products;
      this.autofillDefaultProductIfNotSelected(data, products);
      console.log(data);
      api
        .post("/api/cards/save-card", data)
        .then((response) => {
          this.submiting = false;
          this.clearForm();
          console.log("sent", response);
          this.$store.dispatch("cards/loadAllCards");
        })
        .catch((errors) => {
          this.errors = errors;
          console.log(errors);
          this.submiting = false;
        });
    },
    autofillDefaultProductIfNotSelected(card, activeProducts) {
      Object.keys(activeProducts).forEach(goods => {
        if (activeProducts[goods].length > 0
         && card.defaultProducts[goods] == "") {
          card.defaultProducts[goods] = String(activeProducts[goods][0].id);
        }
      });
    },
    clearForm() {
      this.newCard = CardData();
    },
    hasError(param) {
      return param in this.errors;
    },
    getError(param) {
      return this.errors[param];
    },
    clearErrors() {
      this.errors = {};
    },
    extendSavedCard(card) {
      const empty = CardData();
      const fillMissing = (destination, source) => {
        Object.keys(source).forEach((parameter) => {
          if (!Object.hasOwnProperty.call(destination, parameter)) {
            destination[parameter] = source[parameter];
          }
        });
      };
      fillMissing(card, empty);
      fillMissing(card.meta, empty.meta);
      fillMissing(card.products, empty.products);
      fillMissing(card.defaultProducts, empty.defaultProducts);
    },
    handleEdit(item) {
      this.newCard = JSON.parse(JSON.stringify(item));
      this.extendSavedCard(this.newCard);
      //console.log(this.newCard);
      //location.href = "#card-form";
      document.querySelector("#card-form").scrollIntoView({
        behavior: "smooth",
      });
      //console.log("Handle edit", this.newCard);
    },
  },
};
</script>

<style lang="scss">
.admin > .card-management {
  h1 {
    font: 500 20px/26px Roboto;
  }
  h2 {
    font: 500 16px/20px Roboto;
  }
  .constructions {
    .db-item {
      margin: 5px;
      padding: 10px;
      border: solid 1px grey;
      img {
        width: 200px;
        height: 200px;
      }
      p.id {
        font-size: 0.8em;
      }
    }
  }
  .new-card {
    .error {
      color: red;
    }
    //background: lightblue;
    margin: 0px;
    padding: 12px;
  }
  textarea.custom,
  input.custom {
    color: black;
  }
  textarea:not(.custom),
  input:not(.custom) {
    color: blue;
  }
  input,
  select {
    padding: 5px;
  }
  button.primary {
    &:disabled {
      background: $secondary-color;
    }
    &.form-button {
      margin: 10px 0 10px 10px;
    }
  }

  .image {
    .choice {
      width: 100px;
    }

    .multiple-choice {
      flex-flow: wrap;
    }
  }
}
</style>
