<template>
  <div>
    <MyDialog
      :heading="getText('welcomeHeading')"
      :show="welcomeDialog.show"
      @input="onSubmit"
    >
      <LoadingComponent v-if="loadingContent" />
      <div v-else>
        <p v-text="getText('entrance.welcome')"></p>
        <div class="flex-row presets">
          <!-- <div class="item people">
            
          </div> -->

          <div class="item flex-column email">
            <p v-text="getText('email.question')"></p>
            <input :value="email" @input="setEmail($event.target.value)" type="email" alt="test@deksoft.eu" />
            <CheckboxInput :value="gdpr" @input="setGdpr">{{getText('gdpr.question')}}</CheckboxInput>
            <button style="text-transform: uppercase;" class="primary" type="button" @click="onSubmit" v-text="getText('startNewConfiguration')"></button>
          </div>
        </div>
      </div>
    </MyDialog>
  </div>
</template>

<script>
import MyDialog from "../components/MyDialog";
import {
  
  CheckboxInput,
  //  MultipleChoice,
} from "../components/customElements";
import { mapGetters, mapMutations } from "vuex";
import LoadingComponent from '../LoadingComponent.vue';

export default {
  components: { MyDialog, CheckboxInput, LoadingComponent },
  data() {
    return {
      staticData: {
        heating: {
          primarySourceOptions: [
            {
              name: "elektřina",
              value: "electricity",
            },
            {
              name: "zemni plyn",
              value: "natural gas",
            },
            {
              name: "dřevo",
              value: "wood",
            },
            {
              name: "uhlí",
              value: "coal",
            },
            {
              name: "ostatní",
              value: "other",
            },
          ],
        },
      },
      welcomeDialog: {
        show: false,
      },
    };
  },
  created() {
    if (this.$route.matched.some((route) => {
        return route.name === "admin"
         || route.name === "settings";
    })) {
      this.welcomeDialog.show = false;
    }
  },
  computed: {
    ...mapGetters(['getText', 'loadingContent', 'getSchema']),
    ...mapGetters("configuration/user", ["email", "gdpr"]),
    ...mapGetters("configuration/household/people", [
      "children",
      "parents",
      "grandparents",
      "others",
    ]),
    ...mapGetters("configuration/hvac", ["heatingPrimarySource"]),
  },
  methods: {
    event(event) {
      console.log(event);
    },
    onSubmit() {
      //this.$store.
      this.welcomeDialog.show = false;
    },
    ...mapMutations("configuration/user", ["setEmail", "setGdpr"]),
    ...mapMutations("configuration/household/people", [
      "setChildren",
      "setParents",
      "setGrandparents",
      "setOthers",
    ]),
    ...mapMutations("configuration/hvac", ["setHeatingPrimarySource"]),
  },
};
</script>

<style lang="scss">
.presets {
  justify-content: space-evenly;
  .people {
    .people-item {
      .counter {
        margin: 10px;
      }
    }
  }
  .heating {
    .multiple-choice {
      flex-direction: column;
      .choice {
        margin: 10px;
      }
    }
  }

  .email {
    input[type="email"] {
      height: 40px;
    }
    .checkbox {
      margin: 10px 0;
    }
  }
}
</style>
