<template>
  <div class="product-view">
    <h2 class="title" v-text="title.toUpperCase()"></h2>
    <div class="view flex-row wrap">
      <div
        class="product"
        v-for="(product, index) in list"
        :key="product.name + index"
        :class="{default: isDefault(product)}"
        @click="setDefault(product)"
      >
        <div class="flex-row">
          <img :src="product.image"  :width="80"/>
          <div class="product-info">
            <div class="flex-column">
              <strong class="ellipsis" v-text="product.name"></strong>
              <span class="ellipsis nowrap" v-text="product.manufacturer"></span>
              <span><a :href="product.url" target="_blank"  rel="noopener noreferrer">link</a></span>
              <span class="ellipsis nowrap" v-text="product.brief"></span>
              <span
                >Param. cena:
                {{ product.price ? parseFloat(product.price).toFixed(2) : "neuvedeno" }} {{getText('currency')}}</span
              >
              <span
                >fixní cena:
                {{ product.fixedPrice ? parseFloat(product.fixedPrice).toFixed(2) : "neuvedeno" }} {{getText('currency')}}</span
              >
              <span
                >Deksoft ID:
                {{ product.deksoftId ? product.deksoftId : "neuvedeno" }}
              </span>
              <span v-show="isActive('thermalTransmittance')" :class="{missing: !product.thermalTransmittance}">U value: 
                {{ product.thermalTransmittance? parseFloat(product.thermalTransmittance).toFixed(3) : 'neuvedeno'}} </span> 
            </div>
          </div>
          <i class="remove fa fa-edit" @click.stop="onEdit(product)"></i>
          <i class="remove fa fa-trash" @click.stop="onRemove(index)"></i>
        </div>
      </div>
    </div>
    <button
      class="button toggle-form"
      @click.prevent="showForm = !showForm"
      :class="{ primary: !showForm, secondary: showForm }"
    >
      <i
        class="plus fa"
        :class="{ 'fa-plus': !showForm, 'fa-minus': showForm }"
      ></i>
    </button>
    <form class="add-new" v-show="showForm">
      <h2 v-show="!editing">PŘIDAT DALŠÍ PRVEK</h2>
      <h2 v-show="editing">EDITOVAT PRVEK (id {{newProduct.id}})</h2>
      <div class="how flex-row">
        <label>ZE STAVEBNÍ KNIHOVNY </label>
        <ToggleButtonVModel v-model="fromBuildingLibrary" />
      </div>

      <div class="load flex-row" v-show="fromBuildingLibrary">
        <label>DEKSOFT ID:</label>
        <input type="text" class="custom" ref="dsid" />
        <button
          @click.prevent="onLoad"
          :disabled="loading"
          :class="{ progress: loading }"
        >
          načíst
        </button>
      </div>

      <div class="product-data flex-column">
        <div class="flex-column" v-show="fromBuildingLibrary">
          <label>ID SKD</label>
          <input
            type="text"
            v-model="newProduct.deksoftId"
            :disabled="fromBuildingLibrary"
          />
        </div>

        <div class="attribute">
          <label>Název</label>
          <input
            type="text"
            v-model="newProduct.name"
            :disabled="fromBuildingLibrary"
          />
          <span
            class="error"
            v-show="hasError('name')"
            v-text="getError('name')"
          ></span>
        </div>

        <div class="attribute">
          <label>Výrobce</label>
          <input
            type="text"
            v-model="newProduct.manufacturer"
            :disabled="fromBuildingLibrary"
          />
          <span
            class="error"
            v-show="hasError('manufacturer')"
            v-text="getError('manufacturer')"
          ></span>
        </div>
        <div class="attribute">
          <label>Obrázek</label>
          <input
            type="text"
            v-model="newProduct.image"
            :disabled="fromBuildingLibrary"
          />
        </div>
        <div class="attribute">
          <label>Popis</label>
          <input
            type="text"
            v-model="newProduct.brief"
            :disabled="fromBuildingLibrary"
          />
        </div>
        <div class="attribute">
          <label>Dlouhý popis</label>
          <input
            type="text"
            v-model="newProduct.description"
            :disabled="fromBuildingLibrary"
          />
        </div>
        <div class="attribute">
          <label>URL</label>
          <input
            type="text"
            v-model="newProduct.url"
            :disabled="fromBuildingLibrary"
          />
        </div>
        <div class="attribute">
          <label>Parametrická cena</label>
          <input
            type="text"
            v-model.number="newProduct.price"
            :disabled="fromBuildingLibrary"
          />
          <span
            class="error"
            v-show="hasError('price')"
            v-text="getError('price')"
          ></span>
        </div>

        <div class="attribute">
          <label>Fixní cena</label>
          <input
            type="text"
            v-model.number="newProduct.fixedPrice"
            :disabled="fromBuildingLibrary"
          />
          <span
            class="error"
            v-show="hasError('fixedPrice')"
            v-text="getError('fixedPrice')"
          ></span>
        </div>

        <div class="attribute" v-show="isActive('thermalTransmittance')">
          <label>U value (thermal transmittance)</label>
          <input
            type="text"
            v-model.number="newProduct.thermalTransmittance"
            :disabled="fromBuildingLibrary"
          />
          <span
            class="error"
            v-show="hasError('thermalTransmittance')"
            v-text="getError('thermalTransmittance')"
          ></span>
        </div>
        <!-- Energetics -->
        <div class="attribute" v-show="isActive('heatingRecoveryEfficiency')">
          <label>Sezónní účinnost rekuperace</label>
          <input
            type="text"
            v-model.number="newProduct.energetics.heatingRecoveryEfficiency"
            :disabled="fromBuildingLibrary"
          />
          <span
            class="error"
            v-show="hasError('heatingRecoveryEfficiency')"
            v-text="getError('heatingRecoveryEfficiency')"
          ></span>
        </div>

        <div class="attribute" v-show="isActive('heatingEnergyCarrier')">
          <label>Energonositel systému vytápění</label>
          <select
            v-model.number="newProduct.energetics.heatingEnergyCarrier"
            :disabled="fromBuildingLibrary"
          >
            <option v-for="carry in energyCarriers" :key="carry.id" :value="carry.id" v-text="getText(carry.id)"></option>
          </select>
          <span
            class="error"
            v-show="hasError('heatingEnergyCarrier')"
            v-text="getError('heatingEnergyCarrier')"
          ></span>
        </div>

        <div class="attribute" v-show="isActive('DHWEnergyCarrier')">
          <label>Energonositel systému přípravý TV</label>
          <select
            v-model.number="newProduct.energetics.DHWEnergyCarrier"
            :disabled="fromBuildingLibrary"
          >
            <option v-for="carry in energyCarriers" :key="carry.id" :value="carry.id" v-text="getText(carry.id)"></option>
          </select>
          <span
            class="error"
            v-show="hasError('DHWEnergyCarrier')"
            v-text="getError('DHWEnergyCarrier')"
          ></span>
        </div>

        <div class="attribute" v-show="isActive('heatingSourceEfficiency')">
          <label>Účinnost systému vytápění</label>
          <input
            type="number"
            v-model.number="newProduct.energetics.heatingSourceEfficiency"
            :disabled="fromBuildingLibrary"
          />
          <span
            class="error"
            v-show="hasError('heatingSourceEfficiency')"
            v-text="getError('heatingSourceEfficiency')"
          ></span>
        </div>

        <div class="attribute" v-show="isActive('DHWSourceEfficiency')">
          <label>Účinnost systému přípravy TV</label>
          <input
            type="number"
            v-model.number="newProduct.energetics.DHWSourceEfficiency"
            :disabled="fromBuildingLibrary"
          />
          <span
            class="error"
            v-show="hasError('DHWSourceEfficiency')"
            v-text="getError('DHWSourceEfficiency')"
          ></span>
        </div>

        <div class="attribute" v-show="isActive('PVGenerationPerYear')">
          <label>Průmerná roční produkce elektrické energie (kWh)</label>
          <input
            type="number"
            v-model.number="newProduct.energetics.PVGenerationPerYear"
            :disabled="fromBuildingLibrary"
          />
          <span
            class="error"
            v-show="hasError('PVGenerationPerYear')"
            v-text="getError('PVGenerationPerYear')"
          ></span>
        </div>

        <div class="attribute" v-show="isActive('wastewaterHeatRecoveryEff')">
          <label>Sezónní účinnost rekuperace TV</label>
          <input
            type="number"
            v-model.number="newProduct.energetics.wastewaterHeatRecoveryEff"
            :disabled="fromBuildingLibrary"
          />
          <span
            class="error"
            v-show="hasError('wastewaterHeatRecoveryEff')"
            v-text="getError('wastewaterHeatRecoveryEff')"
          ></span>
        </div>

        <div class="attribute" v-show="isActive('equippedWindowsRatio')">
          <label>Podíl oken vybavených stínicí technikou</label>
          <input
            type="number"
            v-model.number="newProduct.equippedWindowsRatio"
            :disabled="fromBuildingLibrary"
          />
          <span
            class="error"
            v-show="hasError('equippedWindowsRatio')"
            v-text="getError('equippedWindowsRatio')"
          ></span>
        </div>

        
        
        <div class="attribute" v-show="isActive('windowShadeCoolCoef')">
          <label>Koeficient pro výpočet potřeby chladu</label>
          <input
            type="number"
            v-model.number="newProduct.energetics.windowShadeCoolCoef"
            :disabled="fromBuildingLibrary"
          />
          <span
            class="error"
            v-show="hasError('windowShadeCoolCoef')"
            v-text="getError('windowShadeCoolCoef')"
          ></span>
        </div>
        
      </div>
      <button @click.prevent="onAdd" v-show="!editing" class="button primary">přidat</button>
      <button @click.prevent="onSave" v-show="editing" class="button primary">uložit</button>
      <button @click.prevent="onCancel" v-show="editing" class="button primary">zrušit</button>
    </form>
  </div>
</template>

<script>
import api from "../../../store/api";
import { ToggleButtonVModel } from "../../components/customElements";
import { mapGetters } from 'vuex';
import cardStaticData from './cardStaticData';

const ProductData = (vm) => {
  const energetics = {};
  [
    'heatingRecoveryEfficiency',
    'heatingEnergyCarrier',
    'DHWEnergyCarrier',
    'heatingSourceEfficiency',
    'DHWSourceEfficiency',
    'PVGenerationPerYear',
    'wastewaterHeatRecoveryEff',
    'windowShadeCoolCoef',
  ].filter(param => vm.isActive(param)).forEach(param => {
    energetics[param] = 0;
  });
  return {
    deksoftId: 0,
    name: "",
    manufacturer: "",
    brief: "",
    description: "",
    url: "",
    image: "",
    thermalTransmittance: 0,
    price: 0,
    fixedPrice: 0,
    equippedWindowsRatio: 1,
    energetics,
    /* shortDescription and long description in really swapped in deksoft lib  */
    precedingConstructions: [],
    followingConstructions: [],
  };
}

export default {
  components: { ToggleButtonVModel },
  props: {
    list: { type: Array, required: true },
    title: { type: String },
    modelValue: { type: String },
    subCategory: {type: String},
  },
  methods: {
    isDefault(product) {
      return this.modelValue == product.id;
    },
    setDefault(product) {
      this.$emit('update:modelValue', String(product.id));
    },
    onRemove(index) {
      this.list.splice(index, 1);
    },
    validate() {
      this.errors = {};
      if (!this.newProduct.name || this.newProduct.name.length < 2) {
        this.errors.name = "Název je potřeba vyplnit.";
      }
      if (this.newProduct.price <= 0 && this.newProduct.fixedPrice <= 0) {
        this.errors.price = "Alespoň jeden typ ceny musí být vyplněný.";
        this.errors.fixedPrice = "Alespoň jeden typ ceny musí být vyplněný.";
      }
      const isValidEfficiency = (efficiency) => efficiency > 0;
      const isValidSourceType = sourceType => this.energyCarriers.some(carry => carry.id == sourceType);
      const isBetween = (value, from, to) => from <= value && value <= to;

      if (this.isActive('thermalTransmittance') && this.newProduct.thermalTransmittance < 0) {
        this.errors.thermalTransmittance = `thermalTransmittance has to be >= 0.`
      }

      if (this.isActive('heatingRecoveryEfficiency') && !isValidEfficiency(this.newProduct.energetics.heatingRecoveryEfficiency)) {
        this.errors.heatingRecoveryEfficiency = `heatingRecoveryEfficiency has to be > 0.`
      }

      if (this.isActive('heatingEnergyCarrier') && !isValidSourceType(this.newProduct.energetics.heatingEnergyCarrier)) {
        this.errors.heatingEnergyCarrier = `heatingEnergyCarrier must be one of ${this.energyCarriers.map(carry => carry.name).join(',')}`
      }

      if (this.isActive('DHWEnergyCarrier') && !isValidSourceType(this.newProduct.energetics.DHWEnergyCarrier)) {
        this.errors.DHWEnergyCarrier = `DHWEnergyCarrier must be one of ${this.energyCarriers.map(carry => carry.name).join(',')}`
      }

      if (this.isActive('heatingSourceEfficiency') && !isValidEfficiency(this.newProduct.energetics.heatingSourceEfficiency)) {
        this.errors.heatingSourceEfficiency = `heatingSourceEfficiency has to be > 0.`
      }

      if (this.isActive('DHWSourceEfficiency') && !isValidEfficiency(this.newProduct.energetics.DHWSourceEfficiency)) {
        this.errors.DHWSourceEfficiency = `DHWSourceEfficiency has to be > 0.`
      }

      if (this.isActive('PVGenerationPerYear') && this.newProduct.energetics.PVGenerationPerYear <= 0) {
        this.errors.PVGenerationPerYear = `PVGenerationPerYear has to be > 0.`
      }

      if (this.isActive('wastewaterHeatRecoveryEff') && this.newProduct.energetics.wastewaterHeatRecoveryEff < 0) {
        this.errors.wastewaterHeatRecoveryEff = `wastewaterHeatRecoveryEff has to be >= 0.`
      }

      if (this.isActive('equippedWindowsRatio') && !isBetween(this.newProduct.equippedWindowsRatio, 0, 1)) {
        this.errors.equippedWindowsRatio = `equippedWindowsRatio has to be between 0 and 1.`
      }

      if (this.isActive('windowShadeCoolCoef') && this.newProduct.energetics.windowShadeCoolCoef < 0) {
        this.errors.windowShadeCoolCoef = `windowShadeCoolCoef has to be >= 0.`;
      }
      
      return Object.keys(this.errors).length === 0;
    },
    hasError(param) {
      return param in this.errors;
    },
    getError(param) {
      return this.errors[param];
    },
    isConstruction(product) {
      const categoryToProducts = cardStaticData.categoryToProducts
      let category;
      for (category in categoryToProducts) {
        if (categoryToProducts[category].some(p => p === product)) {
          break;
        }
      }
      return cardStaticData.categories.constructions.filter(cat => cat.value === category).length > 0;
    },
    isActive(param) {
      const constrains = {
        heatingRecoveryEfficiency: kind => kind === 'ventilationSystem',
        heatingEnergyCarrier: kind => kind === 'heatingSystem',
        DHWEnergyCarrier: kind => kind === 'heatingSystem',
        heatingSourceEfficiency: kind => kind === 'heatingSystem',
        DHWSourceEfficiency: kind => kind === 'heatingSystem',
        PVGenerationPerYear: kind => kind === 'photovoltaicSystem',
        wastewaterHeatRecoveryEff: kind => kind === 'wastewaterHeatRecovery',
        equippedWindowsRatio: kind => kind === 'windowShading',
        windowShadeCoolCoef: kind => kind === 'windowShading',
      };
      if (param === 'thermalTransmittance') {
        return this.isConstruction(this.subCategory) && this.subCategory != 'windowShading';
      }
      if (param in constrains) {
        return constrains[param](this.subCategory);
      }
      return true;
    },
    onAdd() {
      if (!this.validate()) return;
      const product = { ...this.newProduct };
      product.id = 1 + Math.max(0, ...this.list.map(item => item.id));
      console.log(product);
      product.origine = "deksoft-building-library";
      if (!this.fromBuildingLibrary) {
        product.origine = "local";
        product.deksoftId = 0;
      }

      this.list.push(product);
      this.clear();
    },
    onLoad() {
      // 6720
      const dsid = this.$refs.dsid.value;
      this.loading = true;
      api
        .post("/api/cards/load-deksoft-by-id", { dsid })
        .then((response) => {
          console.log(response);
          this.newProduct = {
            ...response,
          };
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    clear() {
      this.newProduct = ProductData(this);
    },
    trimText(text, size) {
      if (!text || !(typeof text === 'string' || text instanceof String)) return '';
      if (text.length < size) {
        return text;
      }
      return text.slice(0, Math.max(0, size - 3)) + "...";
    },
    onEdit(product) {
      this.clear();
      this.editing = true;
      this.showForm = true;
      for(const key in product) {
        if (Object.prototype.hasOwnProperty.call(product, key)) {
          this.newProduct[key] = product[key];
        }
      }
    },
    onCancel() {
      this.clear();
      this.editing = false;
      this.showForm = false;
    },
    onSave() {
      if (!this.validate()) return;
      const product = { ...this.newProduct };
      console.log(product);
      product.origine = "deksoft-building-library";
      if (!this.fromBuildingLibrary) {
        product.origine = "local";
        product.deksoftId = 0;
      }

      const index = this.list.findIndex(p => p.id == product.id);
      this.list[index] = product;
      this.clear();
    }
  },
  computed: {...mapGetters(['energyCarriers', 'getText'])},
  data() {
    return {
      fromBuildingLibrary: true,
      loading: false,
      showForm: false,
      constructions: [],
      errors: {},
      editing: false,
      newProduct: ProductData(this),
    };
  },
};
</script>

<style lang="scss">
.product-view {
  span.missing {
    color: $primary-color;
  }
  .view {
    background: white;
    min-height: 100px;
    .product {
      .product-info {
        width: 200px;
      }
      margin-right: 2px;
      border: 2px solid $primary-color;
      &.default {
        border-top: 8px solid darken($primary-color, 0%);
      }
    }
  }
  button.toggle-form {
    width: 180px;
    .plus {
      font-size: 2em;
    }
  }
  .add-new {
    .how {
      margin: 10px 0;
    }
    .load {
      label {
        //align-self: center;
        //font: 400 14px/14px Roboto;
        margin: 10px 10px 10px 0;
      }
      margin: 10px 0;
    }
    button.button.primary {
      width: 60px;
      height: 2em;
      padding: auto;
      margin: 5px 5px 15px 5px;
    }
  }
  .toggle-button {
    margin: auto 10px;
  }
  .product-data {
    flex-wrap: wrap;
    .attribute {
      display: flex;
      flex-direction: column;
    }
  }
  .remove {
    position: relative;
    color: $primary-color;
    cursor: pointer;
    padding-left: 5px;
    &:hover {
      color: lighten($primary-color, 20%);

    }
  }
}
</style>
