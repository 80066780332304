<template>
    <div class="energetic-test">
        <button @click="test()">test energetiky</button>
        <span class="error" v-show="error" v-text="error"></span>
        
        <MyDialog :show="showDialog" @input="showDialog = false" >
            <h1>Detaily z vypoctu</h1>
            <JsonViewer 
                :value="res" 
                :expand-depth=1
                copyable
                boxed
            />
        </MyDialog>
    </div>
</template>

<script>
import api from '../../../store/api';
import MyDialog from '../../components/MyDialog.vue';
import JsonViewer from 'vue-json-viewer/ssr'
import 'vue-json-viewer/style.css'


export default {
  components: { MyDialog, JsonViewer },
    data() {
        return {
            showDialog: false,
            res: {},
            error: ""
        }
    },
    methods: {
        async test() {
            try {
                const res = await api.get('/api/energy-test');
                this.res = res.energetics;
                this.showDialog = true;
                this.error = "";
            } catch (error) {
                this.error = error.toString();
                console.error(error);
            }
        }
    }
}
</script>

<style>

</style>