<template>
  <div class="construction">
    <h1 class="title" v-text="getText('construction.heading')"></h1>
    <p class="description" v-text="getText('construction.editorial')"></p>
    
    <CardDialog ref="cardDialog" :card="cardToOpen" :cardSlot="cardToOpenSlot" />
    <div class="filters" v-show="isWienerberger()">
      <MySection :name="getText('wienerbergerClass.question')" :information="getText('wienerbergerClass.info')">
        <MultipleChoice
          single-answer
          :options="getSchemaOptions('construction.wienerbergerClass')"
          :modelValue="wienerbergerClass"
          @update:modelValue="setWienerbergerClass"
        />
      </MySection>
    </div>

    <AdvancedQuestionsToggleButton v-if="isWienerberger()" v-model="advancedMode" />
    <div v-show="advancedMode || isGservis()">
      <div class="filters" v-show="isGservis()">
        <MySection :name="getText('technicalSolution.question')" :information="getText('technicalSolution.info')">
          <MultipleChoice
            single-answer
            :options="getSchemaOptions('construction.technicalSolution')"
            :modelValue="technicalSolution"
            @update:modelValue="setTechnicalSolution"
          />
        </MySection>
        
        <MySection :name="getText('thermalInsulationLevel.question')" :information="getText('thermalInsulationLevel.info')">
          <MultipleChoice
            single-answer
            :options="getSchemaOptions('construction.thermalInsulationLevel')"
            :modelValue="thermalInsulationLevel"
            @update:modelValue="setThermalInsulationLevel"
          />
        </MySection>

        <MySection :name="getText('constructionSystem.question')" :information="getText('constructionSystem.info')">
          <MultipleChoice
            single-answer
            :options="getSchemaOptions('construction.constructionSystem')"
            :modelValue="constructionSystem"
            @update:modelValue="setConstructionSystem"
          />
        </MySection>
      </div>

      <h1 class="title" v-text="getText('verticalConstructionTemplateCardSelection')"></h1>
      <p v-text="getText('verticalConstructionTemplateCardSelection.info')"></p>
      <div class="section">
        <CardSelection
          ref="verticalConstructionTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('verticalConstructionTemplate')"
          :value="getActiveCard('verticalConstructionTemplate')"
          @input="setActiveCard({ slot: 'verticalConstructionTemplate', id: $event })"
          @editCard="onEditCard({ slot: 'verticalConstructionTemplate', card: $event })"
          @change="setupCustom()"
        />
      </div>
      <pre v-show="false">
      {{ getCardSetup("verticalConstructionTemplate") }}
      </pre>

      <div class="filters" v-show="isGservis()">
        <!--Střešní konstrukce -->
        <h1 class="title" v-text="getText('verticalConstructionTemplateCardSelection')"></h1>
        <MySection
          :name="getText('slopingRoofCoveringType.question')"
          v-show="roofType === `sloping`"
          :information="getText('slopingRoofCoveringType.info')"
          tab="construction"
        >
          <MultipleChoice
            single-answer
            :options="getSchemaOptions('construction.roof.slopingRoofCoveringType', 'slopingRoofCoveringType')"
            :modelValue="slopingRoofCoveringType"
            @update:modelValue="setSlopingRoofCoveringType"
          />
        </MySection>

        <MySection
          :name="getText('flatRoofCoveringType.question')"
          v-show="roofType === `flat`"
          :information="getText('flatRoofCoveringType.info')"
          tab="construction"
        >
          <MultipleChoice
            single-answer
            :options="getSchemaOptions('construction.roof.flatRoofCoveringType', 'flatRoofCoveringType')"
            :modelValue="flatRoofCoveringType"
            @update:modelValue="setFlatRoofCoveringType"
          />
        </MySection>
      </div>

      <div v-show="roofType === `sloping`">
        <h1 class="title" v-text="getText('slopingRoofSystemTemplateCardSelection')"></h1>
        <div class="section">
          <CardSelection
            ref="slopingRoofSystemTemplateCardSelection"
            single-answer
            gab="20px"
            :options="cardsFor('slopingRoofSystemTemplate')"
            :value="getActiveCard('slopingRoofSystemTemplate')"
            @input="setActiveCard({ slot: 'slopingRoofSystemTemplate', id: $event })"
            @editCard="onEditCard({ slot: 'slopingRoofSystemTemplate', card: $event })"
            @change="setupCustom()"
          />
        </div>
      </div>

      <div v-show="roofType === `flat`">
        <h1 class="title" v-text="getText('flatRoofCoveringTemplateCardSelection')"></h1>
        <div class="section">
          <CardSelection
            ref="flatRoofCoveringTemplateCardSelection"
            single-answer
            gab="20px"
            :options="cardsFor('flatRoofCoveringTemplate')"
            :value="getActiveCard('flatRoofCoveringTemplate')"
            @input="setActiveCard({ slot: 'flatRoofCoveringTemplate', id: $event })"
            @editCard="onEditCard({ slot: 'flatRoofCoveringTemplate', card: $event })"
            @change="setupCustom()"
          />
        </div>
      </div>

      <h1 class="title" v-text="getText('windowTemplateCardSelection')"></h1>

      <div v-show="isGservis()">
        <MySection :name="getText('windowMaterial.question')" :information="getText('windowMaterial.info')">
          <MultipleChoice
            single-answer
            :options="getSchemaOptions('construction.windows.windowMaterial', 'windowMaterial')"
            :modelValue="windowMaterial"
            @update:modelValue="setWindowMaterial"
          />
        </MySection>
      </div>
      
      <div class="section">
        <CardSelection
          ref="windowTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('windowTemplate')"
          :value="getActiveCard('windowTemplate')"
          @input="setActiveCard({ slot: 'windowTemplate', id: $event })"
          @editCard="onEditCard({ slot: 'windowTemplate', card: $event })"
          @change="setupCustom()"
        />
      </div>

      <MySection
        :name="getText('windowShading.question')"
        :information="getText('windowShading.info')"
      >
        <MultipleChoice
          single-answer
          :options="getSchemaOptions('construction.windows.windowShading', 'windowShading')"
          :modelValue="windowShading"
          @update:modelValue="setWindowShading"
        />
      </MySection>
      
      <div class="section" v-show="windowShading != 'none'">
        <h1 class="title" v-text="getText('windowShadingTemplateCardSelection')"></h1>
        <CardSelection
          ref="windowShadingTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('windowShadingTemplate')"
          :value="getActiveCard('windowShadingTemplate')"
          @input="setActiveCard({ slot: 'windowShadingTemplate', id: $event })"
          @editCard="onEditCard({ slot: 'windowShadingTemplate', card: $event })"
          @change="setupCustom()"
        />
      </div>
    </div>
    <!-- Advanced questions -->
    <AdvancedQuestionsToggleButton v-if="isGservis()" v-model="advancedMode" />
    <div v-show="advancedMode">
      <h1 class="title" v-text="getText('facadeTemplateCardSelection')"></h1>
      <p v-text="getText('facadeTemplateCardSelection.info')"></p>
      <div class="section">
        <CardSelection
          ref="facadeTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('facadeTemplate')"
          :value="getActiveCard('facadeTemplate')"
          @input="setActiveCard({ slot: 'facadeTemplate', id: $event })"
          @editCard="onEditCard({ slot: 'facadeTemplate', card: $event })"
          @change="setupCustom()"
        />
      </div>

      <h1 class="title">
        {{getText('horizontalConstructionTemplateCardSelection')}} 
        <span v-show="roofType === `flat`" v-text="getText('horizontalConstructionTemplateCardSelectionFlat')"></span>
      </h1>
      <div class="section">
        <CardSelection
          ref="horizontalConstructionTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('horizontalConstructionTemplate')"
          :value="getActiveCard('horizontalConstructionTemplate')"
          @input="setActiveCard({ slot: 'horizontalConstructionTemplate', id: $event })"
          @editCard="onEditCard({ slot: 'horizontalConstructionTemplate', card: $event })"
          @change="setupCustom()"
        />
      </div>
      

      <div v-show="roofType === `sloping`">
        <h1 class="title" v-text="getText('slopingRoofCoveringTemplateCardSelection')"></h1>
        <div class="section">
          <CardSelection
            ref="slopingRoofCoveringTemplateCardSelection"
            single-answer
            gab="20px"
            :options="cardsFor('slopingRoofCoveringTemplate')"
            :value="getActiveCard('slopingRoofCoveringTemplate')"
            @input="setActiveCard({ slot: 'slopingRoofCoveringTemplate', id: $event })"
            @editCard="onEditCard({ slot: 'slopingRoofCoveringTemplate', card: $event })"
            @change="setupCustom()"
          />
        </div>
      </div>

      <div v-show="roofType === `sloping`">
        <h1 class="title" v-text="getText('roofWindowTemplateCardSelection')"></h1>
        <div class="section">
          <CardSelection
            ref="roofWindowTemplateCardSelection"
            single-answer
            gab="20px"
            :options="cardsFor('roofWindowTemplate')"
            :value="getActiveCard('roofWindowTemplate')"
            @input="setActiveCard({ slot: 'roofWindowTemplate', id: $event })"
            @editCard="onEditCard({ slot: 'roofWindowTemplate', card: $event })"
            @change="setupCustom()"
          />
        </div>
      </div>

    </div>
    <div class="section-controls">
      <button @click="$router.push('/architecture')" class="secondary transparent" v-text="getText('previousButton')"></button>
      <button @click="$router.push('/hvac')" class="primary" v-text="getText('nextButton')"></button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";
import MySection from "../components/MySection";
import CardDialog from "../components/cardDialog";
import {
  MultipleChoice,
  //MultipleChoiceGalery,
  AdvancedQuestionsToggleButton,
  CardSelection,
} from "../components/customElements";

export default {
  name: "ConstructionTab",
  components: {
    MySection,
    MultipleChoice,
    //MultipleChoiceGalery,
    AdvancedQuestionsToggleButton,
    CardSelection,
    CardDialog,
  },
  data() {
    return {
      staticData: {
        technicalSolutionOptions: [
          {
            name: "Standardní",
            value: "standard",
          },
          {
            name: "Prémiové",
            value: "premium",
          },
          {
            name: "Vlastní",
            value: "",
          },
        ],
        wienerbergerClassOptions: [
          {
            name: "Cenově dostupné",
            value: "affordable",
          },
          {
            name: "Vyvážené",
            value: "smart",
          },
          {
            name: "Nejlepší na trhu",
            value: "premium",
          },
          {
            name: "Vlastní",
            value: "custom",
          }
        ],
        thermalInsulationLevelOptions: [
          {
            name: "Normální",
            image: require("@/assets/image-holder.png"),
            value: "standard",
          },
          {
            name: "Zvýšená",
            image: require("@/assets/image-holder.png"),
            value: "passive house",
          },
        ],
        constructionSystemOptions: [
          {
            name: "Zděný",
            image: require("@/assets/image-holder.png"),
            value: "masonry",
          },
          {
            name: "Dřevostavba",
            image: require("@/assets/image-holder.png"),
            value: "wooden",
          }
        ],
        windowMaterialOptions: [
          {
            name: "Dřevo",
            image: require("@/assets/image-holder.png"),
            value: "wooden",
          },
          {
            name: "Plast",
            image: require("@/assets/image-holder.png"),
            value: "plastic",
          },
          {
            name: "Hliník",
            image: require("@/assets/image-holder.png"),
            value: "aluminium",
          },
        ],
        slopingRoofCoveringTypeOptions: [
          {
            name: "Pálená taška",
            image: require("@/assets/image-holder.png"),
            value: "clay tiles",
          },
          {
            name: "Betonová taška",
            image: require("@/assets/image-holder.png"),
            value: "concrete tiles",
          },
          {
            name: "Plechová krytina",
            image: require("@/assets/image-holder.png"),
            value: "metal roofing",
          },
        ],
        flatRoofCoveringTypeOptions: [
          {
            name: "Hydroizolační fólie",
            image: require("@/assets/image-holder.png"),
            value: "hydroinsulation foil",
          },
          {
            name: "Asfaltové pásy",
            image: require("@/assets/image-holder.png"),
            value: "built-up roofing",
          },
          {
            name: "Zelená střecha",
            image: require("@/assets/image-holder.png"),
            value: "green roof",
          }
        ],
        /*flatRoofCoveringTypeOptionsWienerberger: [
          {
            name: "Hydroizolační fólie",
            image: require("@/assets/image-holder.png"),
            value: "hydroinsulation foil",
          },
          {
            name: "Asfaltové pásy",
            image: require("@/assets/image-holder.png"),
            value: "built-up roofing",
          },
          {
            name: "Zelená střecha",
            image: require("@/assets/image-holder.png"),
            value: "green roof",
          },
          {
            name: "Pochůzná střecha",
            image: require("@/assets/image-holder.png"),
            value: "walkable roof",
          },
          {
            name: "Kačírek",
            image: require("@/assets/image-holder.png"),
            value: "pebble",
          },
        ],*/
        windowShadingOptions: [
          {
            name: "Žádné",
            image: require("@/assets/image-holder.png"),
            value: "none",
          },
          {
            name: "Žaluzie",
            image: require("@/assets/image-holder.png"),
            value: "slatted blinds",
          },
          /*{
            name: "Venkovní žaluzie",
            image: require("@/assets/image-holder.png"),
            value: "external slatted blinds",
          },*/
          {
            name: "Rolety",
            image: require("@/assets/image-holder.png"),
            value: "shade rolls",
          },
          {
            name: "Slunolamy",
            image: require("@/assets/image-holder.png"),
            value: "louvered overhangs",
          },
        ],
      },
      wienerbergerClassParam: "affordable",
      cardToOpen: {},
      cardToOpenSlot: "",
    };
  },
  computed: {
    advancedMode: {
      get() {
        return this.$store.getters.advanced("construction");
      },
      set(v) {
        this.$store.commit("advanced", { value: v, tab: "construction" });
      },
    },
    /*wienerbergerClass: {
      get() {
        return this.wienerbergerClassParam;
      },
      set(v) {
        this.wienerbergerClassParam = v;
        this.changeWienerbergerClass(v);
      },
    },*/
    ...mapGetters([
      "hasWienerbergerFamily",
      "hasGservisFamily",
      "getText",
      'getSchema',
      'getSchemaOptions',
    ]),
    ...mapGetters("cards", ["cardsFor"]),
    ...mapGetters("configuration/goods", ["getActiveCard", "getCardSetup"]),
    ...mapGetters("configuration/architecture", ["roofType"]),
    ...mapGetters("configuration/construction", [
      "wienerbergerClass",
      "technicalSolution",
      "thermalInsulationLevel",
      "constructionSystem",
      "windowMaterial",
      "slopingRoofCoveringType",
      "flatRoofCoveringType",
      "windowShading",
    ]),
  },
  methods: {
    setupCustom() {
      this.setWienerbergerClass('custom');
    },
    onEditCard({card, slot}) {
      this.cardToOpen = card;
      this.cardToOpenSlot = slot;
      this.$refs.cardDialog.show = true;
    },
    isGservis() {
      return this.hasGservisFamily;
    },
    isWienerberger() {
      return this.hasWienerbergerFamily;
    },
    changeWienerbergerClass(val) {
      const classToIndex = {
        affordable: 0,
        smart: 1,
        premium: 2,
      };
      const index = classToIndex[val];
      console.log("wienerbergerClass", val);
      this.$refs.verticalConstructionTemplateCardSelection.selectByIndex(index);
      this.$refs.slopingRoofSystemTemplateCardSelection.selectByIndex(index);
      this.$refs.windowTemplateCardSelection.selectByIndex(index);
      this.$refs.facadeTemplateCardSelection.selectByIndex(index);
      this.$refs.horizontalConstructionTemplateCardSelection.selectByIndex(
        index
      );
      this.$refs.slopingRoofCoveringTemplateCardSelection.selectByIndex(index);
      this.$refs.flatRoofCoveringTemplateCardSelection.selectByIndex(index);
      this.$refs.roofWindowTemplateCardSelection.selectByIndex(index);
    },
    ...mapMutations("result", ["setCurrentTab"]),
    ...mapMutations("configuration/goods", ["setActiveCard"]),
    ...mapMutations("configuration/construction", [
      "setWienerbergerClass",
      "setTechnicalSolution",
      "setThermalInsulationLevel",
      "setConstructionSystem",
      "setWindowMaterial",
      "setSlopingRoofCoveringType",
      "setFlatRoofCoveringType",
      "setWindowShading",
    ]),
  },
};
</script>

<style lang="scss">
.multiple-choice-galery .choice {
  background: white;
  .template {
    padding: 10px;
    p {
      color: #666;
    }
  }
}
</style>
