<template>
  <div class="techinical-facility">
    <!-- Technické zařízení -->
    <h1 class="title" v-text="getText('hvac.heading')"></h1>
    <!-- <div class="flex-row heading-row">
      <div class="tool-item mode">
        <span class="progressbar-string">
          <strong>Režim konfigurace:</strong>
        </span>
        <div class="setup flex-inline">
          <p class="mode-basic">Základní</p>
          <ToggleButton v-model="advancedMode" />
          <p class="mode-advanced">Pokročilý</p>
        </div>
      </div>
    </div> -->
    <p class="description" v-text="getText('hvac.editorial')"></p>

    <CardDialog ref="cardDialog" :card="cardToOpen" :cardSlot="cardToOpenSlot" />

     <div class="filters" v-if="false" v-show="isWienerberger()">
      <MySection name="Úroveň technického řešení(wienerbergerClass)" :information="getText('info.hvac.wienerbergerClass')">
        <MultipleChoice
          single-answer
          :options="staticData.wienerbergerClassOptions"
          :modelValue="wienerbergerClass"
          @update:modelValue="setWienerbergerClass"
        />
      </MySection>
    </div>

    <MySection 
      :name="getText('hvac.technicalSolution.question')" 
      :information="getText('hvac.technicalSolution.info')"
      v-show="isGservis()" 
    >
      <MultipleChoice
        single-answer
        :options="getSchemaOptions('hvac.technicalSolution')"
        :modelValue="technicalSolution"
        @update:modelValue="setTechnicalSolution"
      />
    </MySection>

    <MySection :name="getText('heating.primarySource.question')" :information="getText('heating.primarySource.info')">
      <MultipleChoice
        single-answer
        :options="getSchemaOptions('hvac.heating.primarySource')"
        :modelValue="heatingPrimarySource"
        @update:modelValue="setHeatingPrimarySource"
      />
    </MySection>

    <MySection v-if="isGservis()"  name="Typ zdroje" v-show="heatingPrimarySource === 'electricity'" tab="hvac">
      <MultipleChoice
        single-answer
        :options="staticData.heating.sourceTypeOptions"
        :modelValue="heatingSourceType"
        @update:modelValue="setHeatingSourceType"
      />
    </MySection>
    <!--
    <MySection v-else name="Typ zdroje" v-show="heatingPrimarySource === 'electricity'" tab="hvac">
      <MultipleChoice
        single-answer
        :options="staticData.heating.sourceTypeOptionsWienerberger"
        :modelValue="heatingSourceType"
        @update:modelValue="setHeatingSourceType"
      />
    </MySection>
    -->
    <MySection
      :name="getText('heating.emitter.question')" 
      :information="getText('heating.emitter.info')"
    >
      <MultipleChoice
        single-answer
        :options="getSchemaOptions('hvac.heating.emitter')"
        :modelValue="heatingEmitter"
        @update:modelValue="setHeatingEmitter"
      />
    </MySection>

    <h1 class="title" v-text="getText('heatingSystemTemplateCardSelection')"></h1>

    <div class="section">
      <CardSelection
        ref="heatingSystemTemplateCardSlection"
        single-answer
        gab="20px"
        :options="cardsFor('heatingSystemTemplate')"
        :value="getActiveCard('heatingSystemTemplate')"
        @input="setActiveCard({ slot: 'heatingSystemTemplate', id: $event })"
        @editCard="onEditCard({ slot: 'heatingSystemTemplate', card: $event })"
      />
    </div>

    <MySection :name="getText('heating.secondarySource.question')" :information="getText('heating.secondarySource.info')">
      <MultipleChoice
        single-answer
        :options="getSchemaOptions('hvac.heating.secondarySource')"
        :modelValue="heatingSecondarySource"
        @update:modelValue="setHeatingSecondarySource"
      />
    </MySection>

    <h1 class="title" v-text="getText('chimneyTemplateCardSelection')"></h1>

    <div class="section">
      <CardSelection
        ref="chimneyTemplateCardSelection"
        single-answer
        gab="20px"
        :options="cardsFor('chimneyTemplate')"
        :value="getActiveCard('chimneyTemplate')"
        @input="setActiveCard({ slot: 'chimneyTemplate', id: $event })"
        @editCard="onEditCard({ slot: 'chimneyTemplate', card: $event })"
      />
    </div>

    <MySection :name="getText('ventilation.hasMechanicalVentilation.question')" :information="getText('ventilation.hasMechanicalVentilation.info')">
      <ToggleButton 
      :value="ventilationHasMechanicalVentilation" 
      @input="setVentilationHasMechanicalVentilation"
      />
    </MySection>
    <div v-show="ventilationHasMechanicalVentilation"
    >
      <h1 class="title" v-text="getText('ventilationSystemTemplateCardSelection')"></h1>

      <div class="section">
        <CardSelection
          ref="ventilationSystemTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('ventilationSystemTemplate')"
          :value="getActiveCard('ventilationSystemTemplate')"
          @input="setActiveCard({ slot: 'ventilationSystemTemplate', id: $event })"
          @editCard="onEditCard({ slot: 'ventilationSystemTemplate', card: $event })"
        />
      </div>
    </div>

    <!-- Advanced questions -->
    <AdvancedQuestionsToggleButton v-model="advancedMode" />
    <div v-show="advancedMode">
      <MySection :name="getText('cooling.hasCooling.question')" :information="getText('cooling.hasCooling.info')">
        <ToggleButton :value="coolingHasCooling" 
        @input="setCoolingHasCooling"/>
      </MySection>
      <div v-show="coolingHasCooling">
        <h1 class="title" v-text="getText('coolingSystemTemplateCardSelection')"></h1>

        <div class="section">
          <CardSelection
            ref="coolingSystemTemplateCardSelection"
            single-answer
            gab="20px"
            :options="cardsFor('coolingSystemTemplate')"
            :value="getActiveCard('coolingSystemTemplate')"
            @input="setActiveCard({ slot: 'coolingSystemTemplate', id: $event })"
            @editCard="onEditCard({ slot: 'coolingSystemTemplate', card: $event })"
          />
        </div>
      </div>

      <MySection :name="getText('hasSolarCollectors.question')" :information="getText('hasSolarCollectors.info')">
        <ToggleButton
          :value="hasSolarConnectors"
          @input="setHasSolarConnectors"
        />
      </MySection>

      <MySection :name="getText('photovoltaic.hasPhotovoltaic.question')" :information="getText('photovoltaic.hasPhotovoltaic.info')">
        <ToggleButton :value="hasPhotovoltaic" @input="setHasPhotovoltaic" />
      </MySection>

      <div v-show="hasPhotovoltaic">
        <MySection :name="getText('photovoltaic.systemType.question')" :information="getText('photovoltaic.systemType.info')">
          <MultipleChoice
            single-answer
            :options="getSchemaOptions('hvac.photovoltaic.photovoltaicSystemType', 'photovoltaic.systemType')"
            :modelValue="photovoltaicSystemType"
            @update:modelValue="setPhotovoltaicSystemType"
          />
        </MySection>

        <h1 class="title" v-text="getText('photovoltaicSystemTemplateCardSelection')"></h1>

        <div class="section">
          <CardSelection
            ref="photovoltaicSystemTemplateCardSelection"
            single-answer
            gab="20px"
            :options="cardsFor('photovoltaicSystemTemplate')"
            :value="getActiveCard('photovoltaicSystemTemplate')"
            @input="setActiveCard({ slot: 'photovoltaicSystemTemplate', id: $event })"
            @editCard="onEditCard({ slot: 'photovoltaicSystemTemplate', card: $event })"
          />
        </div>
      </div>

      <MySection :name="getText('specialTechnologies.hasCentralVacuumCleaner.question')" :information="getText('specialTechnologies.hasCentralVacuumCleaner.info')">
        <toggle-button
          :value="hasCentralVacuumCleaner"
          @input="setHasCentralVacuumCleaner"
        />
      </MySection>

      <MySection :name="getText('specialTechnologies.hasSecuritySystem.question')" :information="getText('specialTechnologies.hasSecuritySystem.info')">
        <toggle-button
          :value="hasSecuritySystem"
          @input="setHasSecuritySystem"
        />
      </MySection>

      <MySection :name="getText('specialTechnologies.smartHouse.question')" :information="getText('specialTechnologies.smartHouse.info')">
        <MultipleChoice
          single-answer
          :options="getSchemaOptions('hvac.specialTechnologies.smartHouse')"
          :modelValue="smartHouse"
          @update:modelValue="setSmartHouse"
        />
      </MySection>
      <!-- Hospodaření s vodou -->
      <h1 class="title" v-text="getText('waterManagement.title')"></h1>
      <MySection :name="getText('wastewaterHeatRecovery.hasWastewaterHeatRecovery')">
        <ToggleButton 
          :value="hasWastewaterHeatRecovery" 
          @input="setHasWastewaterHeatRecovery"  
        />
      </MySection>
      <div class="recuperation-section" v-if="hasWastewaterHeatRecovery">

        <div v-if="getFeatureFlag('hasWastewaterHeatRecoveryType')">
          <MySection 
            :name="getText('wastewaterHeatRecovery.wastewaterHeatRecoveryType.question')" 
            :information="getText('wastewaterHeatRecovery.wastewaterHeatRecoveryType.info')">
            <MultipleChoice
              single-answer
              :options="getSchemaOptions('hvac.wastewaterHeatRecovery.wastewaterHeatRecoveryType')"
              :modelValue="wastewaterHeatRecoveryType"
              @update:modelValue="setWastewaterHeatRecoveryType"
            />
          </MySection>
        </div>

        <h1 class="title" v-text="getText('wastewaterHeatRecoveryTemplateCardSelection')"></h1>
        <CardSelection
          ref="wastewaterHeatRecoveryTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('wastewaterHeatRecoveryTemplate')"
          :value="getActiveCard('wastewaterHeatRecoveryTemplate')"
          @input="setActiveCard({ slot: 'wastewaterHeatRecoveryTemplate', id: $event })"
          @editCard="onEditCard({ slot: 'wastewaterHeatRecoveryTemplate', card: $event })"
        />
      </div>

      <MySection :name="getText('specialTechnologies.wastewaterReuse.question')" :information="getText('specialTechnologies.wastewaterReuse.info')">
        <MultipleChoice
          single-answer
          :options="getSchemaOptions('hvac.specialTechnologies.wastewaterReuse')"
          :modelValue="wastewaterReuse"
          @update:modelValue="setWastewaterReuse"
        />
      </MySection>
      <div v-if="getFeatureFlag('hasChargingStations')">
        <h1 class="title" v-text="getText('chargingStation.title')"></h1>

        <MySection :name="getText('chargingStation.numberOfChargingStations.question')" :information="getText('chargingStation.numberOfChargingStations.info')"> 
          <CounterInput 
            single-answer
            :value="numberOfChargingStations"
            @input="setNumberOfChargingStations"
          />
        </MySection>

        <div class="recuperation-section" v-if="numberOfChargingStations > 0">
          <h1 class="title" v-text="getText('chargingStationTemplateCardSelection')"></h1>
          <CardSelection
            ref="chargingStationTemplateCardSelection"
            single-answer
            gab="20px"
            :options="cardsFor('chargingStationTemplate')"
            :value="getActiveCard('chargingStationTemplate')"
            @input="setActiveCard({ slot: 'chargingStationTemplate', id: $event })"
            @editCard="onEditCard({ slot: 'chargingStationTemplate', card: $event })"
          />
        </div>
      </div>

    </div>
    <!--advanced ends-->

    <div class="section-controls">
      <button @click="$router.push('/construction')" class="secondary transparent" v-text="getText('previousButton')"></button>
      <button @click="$router.push('/surroundings')" class="primary" v-text="getText('nextButton')"></button>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";
import MySection from "../components/MySection";
import CardDialog from "../components/cardDialog";

import {
  ToggleButton,
  MultipleChoice,
  // MultipleChoiceGalery,
  AdvancedQuestionsToggleButton,
  CardSelection,
} from "../components/customElements";
import CounterInput from '../components/fundamentals/CounterInput.vue';

export default {
  name: "HVACTab",
  components: {
    ToggleButton,
    MultipleChoice,
    // MultipleChoiceGalery,
    AdvancedQuestionsToggleButton,
    MySection,
    CardSelection,
    CardDialog,
    CounterInput,
  },
  data() {
    return {
      staticData: {
        technicalSolutionOptions: [
          {
            name: "Standardní",
            value: "standard",
          },
          {
            name: "Prémiové",
            value: "premium",
          },
          {
            name: "Vlastní",
            value: "",
          },
        ],
        wienerbergerClassOptions: [
          {
            name: "Affordable",
            value: "affordable",
          },
          {
            name: "Smart",
            value: "smart",
          },
          {
            name: "Premium",
            value: "premium",
          },
        ],
        heating: {
          waterUsageOptions: [
            {
              name: "Běžná",
              value: "usual",
            },
            {
              name: "Hodně se koupu",
              value: "high",
            },
          ],
          primarySourceOptions: [
            {
              name: "Elektřina",
              value: "electricity",
            },
            {
              name: "Zemní plyn",
              value: "natural gas",
            },
            {
              name: "Dřevo",
              value: "wood",
            },
            /*{
              name: "Uhlí",
              value: "coal",
            },*/
          ],
          sourceTypeOptions: [
            {
              name: "Elektrokotel",
              image: require("@/assets/image-holder.png"),
              value: "eletric boiler",
            },
            {
              name: "Tepelné čerpadlo vzduch / voda",
              image: require("@/assets/image-holder.png"),
              value: "air source heat pump",
            },
            {
              name: "Tepelné čerpadlo země / voda",
              image: require("@/assets/image-holder.png"),
              value: "ground source heat pump",
            },
            {
              name: "Topné rohože",
              image: require("@/assets/image-holder.png"),
              value: "heating mats",
            },
            {
              name: "Přímotopy",
              image: require("@/assets/image-holder.png"),
              value: "electric heater",
            },
          ],
          sourceTypeOptionsWienerberger: [
            {
              name: "Tepelné čerpadlo vzduch / voda",
              image: require("@/assets/image-holder.png"),
              value: "air source heat pump",
            },
            {
              name: "Tepelné čerpadlo země / voda",
              image: require("@/assets/image-holder.png"),
              value: "ground source heat pump",
            },
          ],
          templateOptions: [
            {
              image: require("@/assets/technicke-zarizeni/Image.png"),
              value: "eletric boiler",
              html: `
              <div class="Template">
              <div class="flex-row">
              <img class="logo" height="40px" src="${require("@/assets/technicke-zarizeni/DEKSOFT_logo_transparent.png")}" style="align-self: center"/>
              <div>
              <h1>Regulus EcoAir</h1>
              <h5>Regulus</h5>
              </div>
              <div class="full-gap"><i class="fa fa-edit"></i></div>
              </div>
              <p>Greyhound divisively hello coldly wonderfully marginally far…</p>
              </div>`,
            },
            {
              image: require("@/assets/technicke-zarizeni/Image.png"),
              value: "heat pump",
              html: `
              <div class="template">
              <div class="flex-row">
              <img class="logo" height="40px" src="${require("@/assets/konstrukce/DEKSOFT_logo_transparent-3.png")}" style="align-self: center"/>
              <div>
              <h1>Vytápění 2</h1>
              <h5>Hlavní dodavatel</h5>
              </div>
              <div class="full-gap"><i class="fa fa-edit"></i></div>
              </div>
              <p>Greyhound divisively hello coldly wonderfully marginally far…</p>
              </div>`,
            },
            {
              image: require("@/assets/technicke-zarizeni/Image.png"),
              value: "heating mats",
              html: `
              <div class="template">
              <div class="flex-row">
              <img class="logo" height="40px" src="${require("@/assets/konstrukce/DEKSOFT_logo_transparent-3.png")}" style="align-self: center"/>
              <div>
              <h1>Vytápění 3</h1>
              <h5>Hlavní dodavatel</h5>
              </div>
              <div class="full-gap"><i class="fa fa-edit"></i></div>
              </div>
              <p>Greyhound divisively hello coldly wonderfully marginally far…</p>
              </div>`,
            },
          ],
          emitterOptions: [
            {
              name: "Podlahové vytápění",
              image: require("@/assets/image-holder.png"),
              value: "floor heating",
            },
            {
              name: "Radiátory",
              image: require("@/assets/image-holder.png"),
              value: "radiators",
            }
          ],
          secondarySourceOptions: [
            {
              name: "Žádné",
              value: "none",
            },
            {
              name: "Krbová kamna",
              value: "stove",
            },
            {
              name: "Krbová vložka",
              value: "fireplace",
            },
          ],
        },
        cooling: {
        },
        ventilation: {
          typeOptions: [
            {
              name: "Přirozené",
              value: "natural",
            },
            {
              name: "Hybridní",
              value: "hybrid",
            },
            {
              name: "Nucené",
              value: "mechanical",
            },
          ],
        },
        flags: [],
        photovoltaic: {
          systemType: [
            {
              name: "Přímý ohřev teplé vody",
              image: require("@/assets/image-holder.png"),
              value: "direct water heating",
            },
            /*{
              name: "Využítí elektrické energie v domě bez akumulace",
              image: require("@/assets/image-holder.png"),
              value: "electrical without accumulation",
            },*/
            {
              name: "Využítí elektrické energie v domě s akumulací",
              image: require("@/assets/image-holder.png"),
              value: "electrical with accumulation",
            },
          ],
        },
        smartHouseOptions: [
          {
            name: "Standardní",
            value: "standard",
          },
          {
            name: "Nadstandard",
            value: "premium",
          },
          {
            name: "Bez inteligentních systémů",
            value: "none"
          },
        ],
        wastewaterReuseOptions: [
          {
            name: "Splachování dešťovou vodou",
            image: require("@/assets/image-holder.png"),
            value: "flush with rainwater",
          },
          {
            name: "Splachování šedou vodou",
            image: require("@/assets/image-holder.png"),
            value: "flush with greywater",
          },
          {
            name: "Zálivka zahradní dešťovou vodou",
            image: require("@/assets/image-holder.png"),
            value: "watering with rainwater",
          },
          {
            name: "Bez hospodaření vodou",
            image: require("@/assets/image-holder.png"),
            value: "none",
          },
        ],
      },
      wienerbergerClassParam: "affordable",
      cardToOpen: {},
      cardToOpenSlot: "",
    };
  },
  computed: {
    advancedMode: {
      get() {
        return this.$store.getters.advanced("hvac");
      },
      set(v) {
        this.$store.commit("advanced", { value: v, tab: "hvac" });
      },
    } /**
        "heatingSystemTemplate",
        "ventilationSystemTemplate",
        "coolingSystemTemplate",
        "photovoltaicSystemTemplate",
      */,
    ...mapGetters([
      "hasWienerbergerFamily",
      "hasGservisFamily",
      "getText",
      'getSchema',
      'getSchemaOptions',
    ]),
    ...mapGetters("cards", ["cardsFor"]),
    ...mapGetters("configuration/featureFlags", ["getFeatureFlag"]),
    ...mapGetters("configuration/goods", ["getActiveCard", "getCardSetup"]),
    ...mapGetters("configuration/hvac", [
      "technicalSolution",
      "wienerbergerClass",
      "heatingPrimarySource",
      "heatingWaterUsage",
      "heatingSourceType",
      "heatingEmitter",
      "heatingSecondarySource",
      "ventilationHasMechanicalVentilation",
      "coolingHasCooling",
      "hasPhotovoltaic",
      "photovoltaicSystemType",
      "hasSolarConnectors",
      "hasCentralVacuumCleaner",
      "hasSecuritySystem",
      "smartHouse",
      "wastewaterReuse",
      "hasWastewaterHeatRecovery",
      "wastewaterHeatRecoveryType",
      "numberOfChargingStations",
    ]),
  },
  methods: {
    onEditCard({card, slot}) {
      this.cardToOpen = card;
      this.cardToOpenSlot = slot;
      this.$refs.cardDialog.show = true;
    },
    isGservis() {
      return this.hasGservisFamily;
    },
    isWienerberger() {
      return this.hasWienerbergerFamily;
    },
    ...mapMutations("configuration/goods", ["setActiveCard"]),
    ...mapMutations("configuration/hvac", [
      "setTechnicalSolution",
      "setWienerbergerClass",
      "setHeatingPrimarySource",
      "setHeatingWaterUsage",
      "setHeatingSourceType",
      "setHeatingEmitter",
      "setHeatingSecondarySource",
      "setVentilationHasMechanicalVentilation",
      "setCoolingHasCooling",
      "setHasPhotovoltaic",
      "setPhotovoltaicSystemType",
      "setHasSolarConnectors",
      "setHasCentralVacuumCleaner",
      "setHasSecuritySystem",
      "setSmartHouse",
      "setWastewaterReuse",
      "setHasWastewaterHeatRecovery",
      "setWastewaterHeatRecoveryType",
      "setNumberOfChargingStations"
    ]),
  },
};
</script>
