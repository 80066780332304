<template>
  <div class="multiple-choice-galery">
    <div class="scrollable no-scrollbar" ref="container">
      <div class="flex-row">
        <div
          v-for="option in options"
          class="choice flex-column"
          @click="click(option.value)"
          :key="option.value"
          :class="{
            active: value === option.value,
          }"
          ref="optionElements"
        >
          <img :src="option.image" :width="width" :height="height" />
          <p
            class="label text-center"
            v-text="option.name"
            v-show="option.name"
          ></p>
          <div v-show="option.html" v-html="option.html"></div>
          <div class="overlay">
            <i class="fas fa-check-circle"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="button-set">
      <div
        @click="moveRight"
        ref="rightArrow"
        :class="{ arrow: true, 'arrow-right': true, hide: hideRight }"
      >
        <i class="fa fa-chevron-right"></i>
      </div>
      <div
        @click="moveLeft"
        ref="leftArrow"
        :class="{ arrow: true, 'arrow-left': true, hide: hideLeft }"
      >
        <i class="fa fa-chevron-left"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    "single-answer": Boolean,
    "multiple-answer": Boolean,
    // scrollable: Boolean,
    options: { type: Array, required: true },
    value: { type: String },
    width: Number,
    height: Number,
  },
  data() {
    return {
      scrollable: null,
      hideLeft: false,
      hideRight: false,
    };
  },
  mounted() {
    this.scrollable = this.$refs.container;
    this.onScroll();
    this.scrollable.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);
  },
  deactivated() {
    this.scrollable.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    click(option) {
      if (this.multipleAnswer) {
        console.warn(
          "This component does not support 'multiple-answer'"
        );
      }
        this.choose(option);    
    },
    choose(option) {
      const newValue = option;
      this.$emit("input", newValue);
    },

    moveRight() {
      this.scrollable.scrollLeft += (2 * this.scrollable.clientWidth) / 3;
    },
    moveLeft() {
      this.scrollable.scrollLeft -= (2 * this.scrollable.clientWidth) / 3;
    },
    onScroll() {
      const left = this.scrollable.scrollLeft;
      const scrollWidth = this.scrollable.scrollWidth;
      const clientWidth = this.scrollable.clientWidth;

      if (left === 0) {
        this.hideLeft = true;
      } else {
        this.hideLeft = false;
      }
      //console.log("onScroll", left, scrollWidth, clientWidth);
      if (left === scrollWidth - clientWidth) {
        this.hideRight = true;
      } else {
        this.hideRight = false;
      }
    },
    onResize() {
      this.onScroll(); // actually scroll detects both ends, it just need to be called on resize.
    },
  },
};
</script>

<style lang="scss">
.multiple-choice-galery {
  position: relative;
  .arrow {
    position: absolute;
    width: 56px;
    height: 56px;
    background-color: $primary-color;
    text-align: center;
    border-radius: 50%;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    transition: opacity 500ms ease-out;
    box-shadow: 0px 5px 3px 0px rgba($color: #000000, $alpha: 0.08);

    &.hide {
      opacity: 0;
    }
    i {
      color: white;
      font-size: 20px;
      line-height: 56px;
      margin: auto;
    }
    &.arrow-right {
      right: 0;
    }
    &.arrow-left {
      left: 0;
    }
  }
  .scrollable {
    scroll-behavior: smooth;
    overflow: auto;
  }

  .choice {
    margin: 0 10px;
    position: relative;
  }
  .overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($color: $primary-color, $alpha: 0.12);
    i {
      float: right;
      padding: 6px 6px;
      color: $primary-color;
      font-size: 1.5em;
    }
  }

  .choice.active .overlay {
    display: block;
  }
}
</style>
