<template>
  <GChart type="PieChart" :data="chartData" :options="chartOptions" :settings="{packages: ['corechart']}" />
</template>

<script>
import {GChart} from 'vue-google-charts'
export default {
  name: 'App',
  components: {
    GChart
  },
  props: {
      chartData: {type: Array, required: true},
      title: {type: String}
  },
  mounted() {
      this.chartOptions.chart.title = this.title;
  },
  data() {
    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartOptions: {
        pieHole: 0.2,
        chart: {
          title: ''
        }
      }
    }
  }
}
</script>