const { updateWhatever } = require("../utils");
const HVAC = {
  namespaced: true,
  state: () => ({
    technicalSolution: "standard",
    wienerbergerClass: "",
    heating: {
      primarySource: "electricity",
      waterUsage: "usual",
      sourceType: "electric heater",
      emitter: "radiators",
      secondarySource: "none",
    },
    ventilation: {
      hasMechanicalVentilation: false,
    },
    cooling: {
      hasCooling: true,
    },
    hasSolarConnectors: false,
    photovoltaic: {
      hasPhotovoltaic: true,
      photovoltaicSystemType: "direct water heating",
    },
    wastewaterHeatRecovery: {
      hasWastewaterHeatRecovery: false,
      wastewaterHeatRecoveryType: 'centralized',
    },
    specialTechnologies: {
      hasCentralVacuumCleaner: true,
      hasSecuritySystem: true,
      smartHouse: "standard",
      wastewaterReuse: "flush with greywater",
    },
    chargingStations: {
      numberOfChargingStations: 0,
    }
  }),
  getters: {
    technicalSolution: (state) => state.technicalSolution,
    wienerbergerClass: state => state.wienerbergerClass,
    heatingPrimarySource: (state) => state.heating.primarySource,
    heatingWaterUsage: (state) => state.heating.waterUsage,
    heatingSourceType: (state) => state.heating.sourceType,
    heatingEmitter: (state) => state.heating.emitter,
    heatingSecondarySource: (state) => state.heating.secondarySource,
    ventilationHasMechanicalVentilation: (state) =>
      state.ventilation.hasMechanicalVentilation,
    coolingHasCooling: (state) => state.cooling.hasCooling,
    hasPhotovoltaic: (state) => state.photovoltaic.hasPhotovoltaic,
    photovoltaicSystemType: (state) =>
      state.photovoltaic.photovoltaicSystemType,
    hasSolarConnectors: (state) => state.hasSolarConnectors,
    hasCentralVacuumCleaner: (state) =>
      state.specialTechnologies.hasCentralVacuumCleaner,
    hasSecuritySystem: (state) => state.specialTechnologies.hasSecuritySystem,
    smartHouse: (state) => state.specialTechnologies.smartHouse,
    wastewaterReuse: (state) => state.specialTechnologies.wastewaterReuse,
    hasWastewaterHeatRecovery: (state) => state.wastewaterHeatRecovery.hasWastewaterHeatRecovery,
    wastewaterHeatRecoveryType: (state) => state.wastewaterHeatRecovery.wastewaterHeatRecoveryType,
    numberOfChargingStations: (state) => state.chargingStations.numberOfChargingStations,
  },
  mutations: {
    set(state, newState) {
      state = updateWhatever(state, newState);
    },
    setTechnicalSolution(state, val) {
      state.technicalSolution = val;
    },
    setWienerbergerClass(state, val) {
      state.wienerbergerClass = val;
    },
    setHeatingPrimarySource(state, val) {
      state.heating.primarySource = val;
    },
    setHeatingWaterUsage(state, val) {
      state.heating.waterUsage = val;
    },
    setHeatingSourceType(state, val) {
      state.heating.sourceType = val;
    },
    setHeatingEmitter(state, val) {
      state.heating.emitter = val;
    },
    setHeatingSecondarySource(state, val) {
      state.heating.secondarySource = val;
    },
    setVentilationHasMechanicalVentilation(state, val) {
      state.ventilation.hasMechanicalVentilation = val;
    },
    setCoolingHasCooling(state, val) {
      state.cooling.hasCooling = val;
    },
    setHasPhotovoltaic(state, val) {
      state.photovoltaic.hasPhotovoltaic = val;
    },
    setPhotovoltaicSystemType(state, val) {
      state.photovoltaic.photovoltaicSystemType = val;
    },
    setHasSolarConnectors(state, val) {
      state.hasSolarConnectors = val;
    },
    setHasCentralVacuumCleaner(state, val) {
      state.specialTechnologies.hasCentralVacuumCleaner = val;
    },
    setHasSecuritySystem(state, val) {
      state.specialTechnologies.hasSecuritySystem = val;
    },
    setSmartHouse(state, val) {
      state.specialTechnologies.smartHouse = val;
    },
    setWastewaterReuse(state, val) {
      state.specialTechnologies.wastewaterReuse = val;
    },
    setHasWastewaterHeatRecovery(state, val) {
      state.wastewaterHeatRecovery.hasWastewaterHeatRecovery = val;
    },
    setWastewaterHeatRecoveryType(state, val) {
      state.wastewaterHeatRecovery.wastewaterHeatRecoveryType = val
    },
    setNumberOfChargingStations(state, val) {
      state.chargingStations.numberOfChargingStations = val;
    }
  },
  actions: {},
};

export default HVAC;
