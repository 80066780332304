<template>
  <div class="process-navbar">
    <nav class="flex">
      <router-link to="/household" class="nav-item flex-inline">
        <i class="icon fa fa-couch"></i>
        <span class="progressbar-string" v-text="getText('household')"></span>
      </router-link>
      <router-link to="/architecture" class="nav-item flex-inline">
        <i class="icon fa fa-dice-d20"></i>
        <span class="progressbar-string" v-text="getText('architecture')"></span>
      </router-link>
      <router-link to="/construction" class="nav-item flex-inline">
        <i class="icon fa fa-cubes"></i>
        <span class="progressbar-string" v-text="getText('construction')"></span>
      </router-link>
      <router-link to="/hvac" class="nav-item flex-inline" @click.native="setCurrentTab('computations')">
        <i class="icon fa fa-burn"></i>
        <span class="progressbar-string" v-text="getText('hvac')"></span>
      </router-link>
      <router-link to="/surroundings" class="nav-item flex-inline">
        <i class="icon fa fa-umbrella-beach"></i>
        <span class="progressbar-string" v-text="getText('surroundings')"></span>
      </router-link>
      <router-link to="/location" class="nav-item flex-inline">
        <i class="icon fa fa-map-marker-alt"></i>
        <span class="progressbar-string" v-text="getText('location')"></span>
      </router-link>
      
      <div class="tool-item start-again" @click="startAgain">
        <i class="icon fa fa-undo"></i>
        <span class="progressbar-string" v-text="getText('resetButton')"></span>
      </div>

    </nav>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
//import { ToggleButton } from "./components/customElements";


export default {
  name: "ProcessNavbar",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getText'])
  },
  methods: {
    ...mapMutations('result', ['setCurrentTab']),
    startAgain() {
      this.$store.dispatch("LOAD_INITIAL_STATE");
    },
  },
  components: {
   // ToggleButton,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$navbar-padding: 10px;
$navbar-height: 46px;
.padding-vertical {
  padding: 0 10px;
}
.process-navbar {
  background: white;
  padding: 0 0 0 0;
  border-bottom: solid 1px rgba($color: #e0e0e0, $alpha: 1);

  .flex {
    display: flex;
    justify-content: space-around;
  }
  .nav-item,
  .tool-item {
    text-align: center;
    font: Medium 16px / 21px Roboto;
    letter-spacing: 1.43px;
    color: #707070;
    font-weight: 500;
    font-size: 16px;
    line-height: $navbar-height;
    height: $navbar-height;
  }
  .nav-item {
    text-decoration: none;
    position: relative;
    top: 1px;
    &.router-link-active {
      color: $primary-color;
      border-bottom: solid 2px $primary-color;
    }
  }

  .tool-item {
    &.start-again {
      color: $primary-color;
      font-weight: bolder;
      cursor: pointer;
    }

    &.mode {
      line-height: 100%;
      color: #707070;
      vertical-align: middle;
      .setup {
        font-weight: 300;
        p {
          padding: 0 6px;
        }
      }
    }
  }
  .toggle-button {
    margin: auto 8px auto 8px;
  }
  .nav-item i,
  .tool-item i {
    font-size: 20px;
    margin: auto 5px;
  }
}
</style>
