<template>
  <div class="architecture">
    <h1 class="title" v-text="getText('architecture.heading')"></h1>
    <!-- <div class="flex-row heading-row">
      <div class="tool-item mode">
        <span class="progressbar-string">
          <strong>Režim konfigurace:</strong>
        </span>
        <div class="setup flex-inline">
          <p class="mode-basic">Základní</p>
          <ToggleButton v-model="advancedMode" />
          <p class="mode-advanced">Pokročilý</p>
        </div>
      </div>
    </div> -->

    <p class="description" v-text="getText('architecture.editorial')"></p>

    <MySection :name="getText('houseType.question')" :information="getText('houseType.question')">
      <MultipleChoice
        single-answer
        :options="getSchemaOptions('architecture.houseType')"
        :modelValue="houseType"
        @update:modelValue="setHouseType"
      />
    </MySection>

    <!-- 
    <div class="section">
      <div class="flex-inline">
        <h3>
          Tvar domu
          <i class="fa fa-info-circle"></i>
        </h3>
        <MultipleChoice
          single-answer
          :options="staticData.houseShapeOptions"
          :modelValue="houseShape"
          @update:modelValue="setHouseShape"
        />
      </div>
    </div>
    -->

    <MySection :name="getText('roofType.question')" :information="getText('roofType.info')">
      <MultipleChoice
        single-answer
        :options="getSchemaOptions('architecture.roofType')"
        :modelValue="roofType"
        @update:modelValue="setRoofType"
      />
    </MySection>

    <MySection 
      name="Sklon střechy" 
      :information="getText('info.architecture.roofSlope')" 
      v-show="roofType == 'sloping' && houseType == 'multi-storey'"
    >
      <MultipleChoice
        single-answer
        :options="roofSlopeOptions()"
        :modelValue="roofSlope"
        @update:modelValue="setRoofSlope"
      />
    </MySection>

    <MySection
      v-if="'this feature is disabled' === null"
      name="Obytelné podkrový"
      tab="architecture"
      v-show="roofType == 'sloping'"
    >
      <ToggleButton
        :value="flags.occupiedAttic"
        @input="setFlag({ variableName: 'occupiedAttic', val: $event })"
      />
    </MySection>
    

    <h1 class="title" v-text="getText('buildingStyleLike.question')"></h1>

    <div class="section">
      <MultipleChoiceGalery
        single-answer
        :options="feedBuildingStyleLikeOptions"
        :value="buildingStyleLike"
        @input="setBuildingStyleLike"
        :width="400"
        :height="180"
      />
    </div>

    <AdvancedQuestionsToggleButton v-model="advancedMode" />

    <MySection 
      :name="getText('glazingSize.question')" 
      :information="getText('glazingSize.info')"
      advanced 
      tab="architecture"
    >
      <MultipleChoice
        single-answer
        :options="getSchemaOptions('architecture.glazingSize')"
        :modelValue="glazingSize"
        @update:modelValue="setGlazingSize"
      />
    </MySection>

    <MySection 
      :name="getText('basement.question')" 
      :information="getText('basement.info')"
      advanced 
      tab="architecture"
    >
      <ToggleButton
        :value="flags['basement']"
        @input="setFlag({ variableName: 'basement', val: $event })"
      />
    </MySection>

    <div v-show="advancedMode">
      <h1 style="display:inline !important;" v-text="getText('parking.heading')"></h1>
    </div>
    
    <MySection
      v-for="variableName in Object.keys(getSchema('architecture.parking.counters'))"
      :information="getText(`parking.${variableName}.info`)"
      :name="getText(`parking.${variableName}.question`)"
      :advanced="true"
      tab="architecture"
      :key="variableName"
    >
      <CounterInput
        :value="parkingCounters[variableName]"
        :min="getSchema(`architecture.parking.counters.${variableName}.min`)"
        :max="getSchema(`architecture.parking.counters.${variableName}.max`)"
        @input="
          setParkingCounter({ variableName: variableName, val: $event })
        "
      />
    </MySection>

    <MySection 
      :name="getText('parking.storageSpaceInGarage.question')" 
      :information="getText('parking.storageSpaceInGarage.info')"
      advanced 
      v-show="parkingCounters.attachedGarage + parkingCounters.detachedGarage > 0"
      tab="architecture"
    >
      <toggle-button
        :value="storageSpaceInGarage"
        @input="setStorageSpaceInGarage"
      />
    </MySection>

    <div class="section-controls">
      <button @click="$router.push('/household')" class="secondary transparent" v-text="getText('previousButton')"></button>
      <button @click="$router.push('/construction')" class="primary" v-text="getText('nextButton')"></button>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";
import {
  MultipleChoice,
  MultipleChoiceGalery,
  ToggleButton,
  CounterInput,
//  Slider,
  AdvancedQuestionsToggleButton,
} from "../components/customElements";
import MySection from "../components/MySection";
export default {
  name: "ArchitectureTab",
  components: {
    MultipleChoice,
    MultipleChoiceGalery,
    ToggleButton,
    CounterInput,
    MySection,
    AdvancedQuestionsToggleButton,
  },
  data() {
    return {
      staticData: {
        houseShapeOptions: [
          {
            name: "Čtverec / obdelník",
            image: require("@/assets/image-holder.png"),
            value: "rectangle",
          },
          {
            name: "Tvar T",
            image: require("@/assets/image-holder.png"),
            value: "T shape",
          },
          {
            name: "Tvar L",
            image: require("@/assets/image-holder.png"),
            value: "L shape",
          },
          {
            name: "Volný tvar",
            image: require("@/assets/image-holder.png"),
            value: "atypical",
          },
        ],
        houseTypeOptions: [
          {
            name: "Bungalov",
            image: require("@/assets/image-holder.png"),
            value: "bungalow",
          },
          {
            name: "Patrový",
            image: require("@/assets/image-holder.png"),
            value: "multi-storey",
          },
        ],
        parkingCounters: [
          {
            name: "Nekryté parkovací stání na pozemku",
            variableName: "openRoofParkingSpace",
            advanced: true,
          },
          {
            name: "Kryté parkovací stání na pozemku",
            variableName: "carport",
            advanced: true,
          },
          {
            name: "Garáž v domě",
            variableName: "attachedGarage",
            advanced: true,
          },
          {
            name: "Garáž samostatně vedle domu",
            variableName: "detachedGarage",
            advanced: true,
          },
        ],
        roofTypeOption: [
          {
            name: "Plochá",
            image: require("@/assets/architecture/strecha_plocha.svg"),
            value: "flat",
          },
          {
            name: "Šikmá",
            image: require("@/assets/architecture/strecha_sikma.svg"),
            value: "sloping" /* hip */,
          },
        ],
        roofSlopeOptions: [
          {
            name: "Nizký",
            image: require("@/assets/image-holder.png"),
            value: "low",
          },
          {
            name: "Vysoký",
            image: require("@/assets/image-holder.png"),
            value: "high",
          },
        ],
        roofSlopeOptionsWienerberger: [
          {
            name: "Nizký (5° až 25°)",
            image: require("@/assets/image-holder.png"),
            value: "low",
          },
          {
            name: "Vysoký (25° a více)",
            image: require("@/assets/image-holder.png"),
            value: "high",
          },
        ],
        glazingSizeOption: [
          {
            name: "Standardní",
            image: require("@/assets/image-holder.png"),
            value: "standard",
          },
          {
            name: "Velkorysé",
            image: require("@/assets/image-holder.png"),
            value: "generous",
          },
        ],
      },
    };
  },
  computed: {
    advancedMode: {
      get() {
        return this.$store.getters.advanced("architecture");
      },
      set(v) {
        this.$store.commit("advanced", { value: v, tab: "architecture" });
      },
    },
    ...mapGetters([
      "hasWienerbergerFamily",
      "hasGservisFamily",
      'getText',
      'getSchema',
      'getSchemaOptions',
    ]),
    ...mapGetters("recommandedHouses", ["feedBuildingStyleLikeOptions"]),
    ...mapGetters("configuration/architecture", [
      "houseType",
      "buildingStyleLike",
      "parkingCounters",
      "storageSpaceInGarage",
      "roofType",
      "roofSlope",
      "glazingSize",
      "flags",
    ]),
  },
  methods: {
    isGservis() {
      return this.hasGservisFamily;
    },
    isWienerberger() {
      return this.hasWienerbergerFamily;
    },
    roofSlopeOptions() {
      if (this.isWienerberger()) {
        return this.staticData.roofSlopeOptionsWienerberger;
      } else {
        return this.staticData.roofSlopeOptions;
      }
    },
    ...mapMutations("configuration/architecture", [
      "setHouseType",
      "setBuildingStyleLike",
      "setParkingCounter",
      "setStorageSpaceInGarage",
      "setRoofType",
      "setRoofSlope",
      "setGlazingSize",
      "setFlag",
  ])},
};
</script>

<style lang="scss" scoped>
.architecture {
  .slider-input {
    flex: 0 0 50%;
  }

  .advanced {
    color: $primary-color;
    margin-left: 10px;
  }
}
</style>
