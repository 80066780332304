<template>
  <div class="visualization-popup" v-show="show">
    <div class="popup-dialog">
      <div class="popup-overlay"></div>
      <div class="popup-modal">
        <div class="flex-row popup-heading">
          <h1 v-text="heading"></h1>
          <span @click="onClose" class="close">&plus;</span>
        </div>
        <slot> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: String,
    show: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    onClose() {
      this.$emit("input", 'close');
    },
  },
  components: {},
};
</script>

<style lang="scss">
.popup-modal {
  width: 1232px;
  max-width: 95vw;
  max-height: 85vh;
  overflow-y: auto;

  h1 {
    font: normal normal medium 20px/26px Roboto;
    letter-spacing: 0.26px;
    color: #000000de;
  }
  .popup-heading {
    justify-content: space-between;
    h1 {
      margin-top: 0;
    }
    .close {
      align-self: flex-start;
      transform: rotateZ(45deg);
      transition: 300ms;
      font: 300 34px/19px Roboto;
      color: $secondary-color;
      cursor: pointer;
      &:hover {
        color: $primary-color;
        //transform: rotateZ(135deg);
      }
    }
  }
  p {
    font: normal normal 400 16px/24px Roboto;
    letter-spacing: 0.13px;
    color: #00000099;
  }
  .controls-modal {
    button.primary {
      margin: 50px 0 0 0;
      float: right;
    }
  }
}
</style>