<template>
  <div class="input-group">
        <input
        :value="value"
        @input="onEdit($event)"
        :class="{ edited: changed}"
        :style="{'width': width}"
        type="number"
        step="0.001"
        class="group"
        />

        <span class="input-group-text" v-html="unit">
        </span>
    </div>
</template>

<script>
export default {
    props: {
        value: Number,
        unit: String,
        changed: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: "40px",
        }
    },
    methods: {
        onEdit(event) {
            const value = Number(event.target.value);
            this.$emit('input', value);
        }
    }
}
</script>

<style lang="scss">
.input-group {
    border: $secondary-color solid 1px;
    border-radius: 4px;
    //border: auto;
    position: relative;
    display: flex;
    &:focus {
      outline: none;
      border: none;
    }
    input.group {
        text-align: center;
    }

  .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    // border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}
</style>