<template>
    <component :is="tag" class="price-value">
        {{ $filters.pricePrettier(price) }}
        <sub><span v-show="price >= 1000">000</span> {{getText('currency')}}</sub>
    </component>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        price: {
            type: Number,
            required: true,
        },
        tag: {
            type: String,
            default: 'h1',
        }
    },
    computed: mapGetters(["getText"]),
}
</script>

<style lang="scss">
.price-value {
    text-align: center;
}
</style>