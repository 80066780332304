const { updateWhatever } = require("../utils");
const user = {
  namespaced: true,
  state: () => ({
    email: "",
    gdpr: false,
    onOrder: false,
    NZEBLevel: "NZEB I",
  }),
  getters: {
    email: (state) => state.email,
    gdpr: state => state.gdpr,
    onOrder: (state) => state.onOrder,
    NZEBLevel: (state) => state.NZEBLevel,
  },
  mutations: {
    set(state, newState) {
      state = updateWhatever(state, newState);
    },
    setEmail(state, val) {
      state.email = val;
    },
    setGdpr(state, val) {
      state.gdpr = val;
    },
    setOnOrder(state, val) {
      state.onOrder = val;
    },
    setNZEBLevel(state, val) {
      state.NZEBLevel = val;
    }
  },
  actions: {},
};

export default user;
