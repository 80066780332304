<template>
  <div class="text-tool">
    <div class="flex-row">
        <div class="icon-button" @click="isEditable = !isEditable">
            <i v-if="isEditable" class="icon fa fa-unlock"></i>
            <i v-else class="icon fa fa-lock"></i>
        </div>
        
        <button class="secondary" @click="onShowNBSP()">
            <span v-if="isVisibleNBSP">
                schovat nezalomitelné mezery
            </span>
            <span v-else>
                zvýraznit nezalomitelné mezery
            </span>
        </button>

        <button class="secondary" @click="onSuggestNBSP()">navrhnout nezalomitelné mezery</button>
        <button class="secondary" @click="insertTextAtCaret()">vložit ⍽</button>
    </div>
    <div 
        ref="editor" 
        class="textarea" 
        v-text="value" 
        @dblclick="isEditable = true" 
        @keydown.esc="isEditable = false" 
        :contenteditable="isEditable" 
        @input="onInput"
    ></div>
    <div class="textarea" v-show="isVisibleNBSP" v-html="withNBSP(newText || value)"></div>
    <div v-show="newText && newText != value" >
        <div class="changes flex-row">
            <button class="primary drop" @click="dropChanges"><i class="fa fa-ban"></i> zahodit změny</button>
            <button class="primary save" @click="saveChanges"><i class="fa fa-save"></i> uložit změny</button>
        </div>
    </div>

    <MyDialog :show="suggestedNBSP" @input="onRejectSuggestedNBSP" ref="suggestDiloag">
        <div class="textarea" v-html="withNBSP(autofillNBSP(newText || value))"></div>
        <div class="flex-row">
            <button @click="onRejectSuggestedNBSP" class="primary">odmítnout</button>
            <button @click="onAcceptSuggestedNBSP" class="primary">použít</button>
        </div>
    </MyDialog>
  </div>
</template>

<script>
import MyDialog from '../../components/MyDialog.vue';
const terms = [
    'K','k','S','s','V','v','Z','z','O','o','U','u','A','I',
];

export default {
    components: { MyDialog },
    props: {
        value: String,
    },
    data() {
        return {
            suggestedNBSP: false,
            isVisibleNBSP: false,
            isEditable: false,
            newText: '',
        }
    },
    methods: {
        insertTextAtCaret() {
            const text = '\u{00A0}';
            var sel, range;
            if (window.getSelection) {
                sel = window.getSelection();
                if (sel.getRangeAt && sel.rangeCount) {
                    range = sel.getRangeAt(0);
                    range.deleteContents();
                    range.insertNode( document.createTextNode(text) );
                }
            } else if (document.selection && document.selection.createRange) {
                document.selection.createRange().text = text;
            }
            this.forceInput();
        },
        dropChanges() {
            this.$refs.editor.innerText = this.value;
            this.newText = '';
            this.isEditable = true;
        },
        saveChanges() {
            this.$emit('input', this.newText);
        },
        onSuggestNBSP() {
            this.suggestedNBSP = true;
        },
        onAcceptSuggestedNBSP() {
            this.suggestedNBSP = false;
            this.newText = this.autofillNBSP(this.newText || this.value);
            console.log(`old '${this.value}'`)
            console.log(`new '${this.newText}'`)
        },
        onRejectSuggestedNBSP() {
            this.suggestedNBSP = false;      
        },
        onShowNBSP() {
            this.isVisibleNBSP = ! this.isVisibleNBSP;
        },
        withNBSP(text) {
            let res = '';
            for (const char of text) {
                if (char === '\u{00A0}') {
                    res += '<span class="nbsp">⍽</span>';
                } else {
                    res += char;
                }   
            }
            return res;
        },
        autofillNBSP(text) {
            // const buffer = Buffer.from(char, 'utf8').toString('hex');
            // console.log(`${char}: ${buffer}`);
            let res = '';
            const words = text.split(' ');
            for (let index = 0; index < words.length; index++) {
                const word = words[index];
                res += word;
                if (index === words.length-1) break;
                if (terms.includes(word)) {
                    res += '\u{00A0}';
                } else {
                    res += ' ';
                }
            }
            return res;
        },
        onInput(event) {
            this.newText = event.target.innerText;
        },
        forceInput() {
            this.newText = this.$refs.editor.innerText;
        }
    }
}
</script>

<style lang="scss" scope>
.text-tool {
    .textarea {
        background-color: transparent;
        border-radius: 4px;
        padding: 4px;
        border: 2px solid grey;
        resize: both;
        width: 100%;
        overflow: hidden;
        height: 4em;
        max-width: 100%;
        color: black;
        &[contenteditable=true] {
            background-color: white;
            color: black;
            border: 2px solid black;
        }
    }

    .nbsp {
        background: yellow;
    }
    
    .changes { 
        background: transparent;
        > button {
            //border-radius: 4px;
            //border-color: transparent;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            flex-grow: 1;
            height: 40px;
            margin: 0;
            padding: 0;
            &.drop {
                background: $secondary-color;
            }
            &.save {
                background: rgb(51, 231, 66);
            }
        }
    }
    div.icon-button {
        margin: auto 5px auto 0;
        .icon { 
            font-size: 1.3em;
            cursor: pointer;
            color:  $secondary-color;
            &.fa-unlock {
                color: $primary-color;
            }
            &:hover {
                color: lighten($primary-color, 25%);
            }
        }
    }
    button.primary {
        height: 30px;
        margin-right: 5px;
    }
    button.secondary {
        color: black;
        font-size: 1.0em;
        line-height: 1.0em;
        word-wrap: none;
        margin-right: 5px;
        height: auto;
        padding: 5px;
    }
}
</style>