<template>
  <div class="toggle-button" :class="{disabled}">
    <label class="switch">
      <input type="checkbox" :disabled="disabled" :checked="value" @input.stop="onInput()" ref="inputElement" />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "ToggleButton",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  methods: {
    onInput() {
      if (this.disabled) return;
      this.$emit("input", !this.value);
    },
  },
};
</script>
<style lang="scss">
$switch-color: #268265;


.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 14px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c7c7c7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  background: $switch-color;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -5px;
  top: -3.5px;
  background-color: white;
  box-shadow: 0px 1px 2px lightgrey;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgba($switch-color, 0.4);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgba($switch-color, 0.4);
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-button.disabled {
  > .switch .slider  {
    background: #eee
  }

  input:checked + .slider:before {
    background-color: lighten($switch-color, 50%);
  }
}
</style>
