<template>
  <div class="item-diff">
    <div>
      <span v-text="getOperation()" class="operation" :class={[change.op]:true}></span> 
      <span v-text="getMainText()" class="item-label"></span>
      <MoreLess :show="show" @click="show = !show" />
    </div>
    <DetailDiff :diff="change.diff" v-if="show" />
  </div>
</template>

<script>
import DetailDiff from './DetailDiff';
import MoreLess from './MoreLess';
export default {
  components: {DetailDiff, MoreLess},
  props: {
    change: Object,
    main: String
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    getOperation() {
      return `[${this.change.op}]`
    },
    getMainText() {
      let text = this.change;
      const steps = this.main.split('.');
      steps.forEach(s => {
        if (s in text) {
          text = text[s];
        }
      })
      return text;
    }
  }
};
</script>

<style lang="scss">
.item-diff {
  .operation {
  }
  .item-label {
    margin: 4px;
    font-weight: bold;
  }
 
}
</style>
