<template>
  <div class="around-house">
    <h1 class="title" v-text="getText('surroundings.heading')"></h1>

    <p class="description" v-text="getText('editorial.surroundings')"></p>

    <CardDialog ref="cardDialog" :card="cardToOpen" :cardSlot="cardToOpenSlot" />

    <div class="filters" v-if="'parameter disabled' == false" v-show="isWienerberger()">
      <MySection name="Technické řešení" tab="construction">
        <MultipleChoice
          single-answer
          :options="staticData.wienerbergerClassOptions"
          :modelValue="wienerbergerClass"
          @update:modelValue="setWienerbergerClass"
        />
      </MySection>
    </div>

    <MySection :name="getText('swimmingPool.hasSwimmingPool.question')"  :information="getText('swimmingPool.hasSwimmingPool.info')">
      <ToggleButton :value="hasSwimmingPool" @input="setHasSwimmingPool" />
    </MySection>

    <div class="swimming-pool" v-show="hasSwimmingPool">
      <MySection :name="getText('swimmingPool.size.question')" :information="getText('swimmingPool.size.info')">
        <div class="flex-row">
          <div class="flex-column">
            <label v-text="getText('swimmingPool.width.question')"></label>
            <NumberWithUnitInput
              :value="swimmingPoolWidth"
              @input="setSwimmingPoolWidth"
              unit="m"
            />
          </div>
          <div class="flex-column">

          <label v-text="getText('swimmingPool.length.question')"></label>
            <NumberWithUnitInput
              :value="swimmingPoolLength"
              @input="setSwimmingPoolLength"
              unit="m"
            />
          </div>
        </div>
      </MySection>

      <MySection :name="getText('swimmingPool.constructionType.question')" :information="getText('swimmingPool.constructionType.info')">
        <MultipleChoice
          :options="getSchemaOptions('surroundings.swimmingPool.constructionType')"
          :modelValue="swimmingPoolConstructionType"
          @update:modelValue="setSwimmingPoolConstructionType"
        />
      </MySection>

      <h1 class="title" v-text="getText('swimmingPoolPoolTemplateCardSelection')"></h1>
      <CardSelection
        ref="swimmingPoolPoolTemplateCardSelection"
        single-answer
        gab="20px"
        :options="cardsFor('swimmingPoolPoolTemplate')"
        :value="getActiveCard('swimmingPoolPoolTemplate')"
        @input="setActiveCard({ slot: 'swimmingPoolPoolTemplate', id: $event })"
        @editCard="onEditCard({ slot: 'swimmingPoolPoolTemplate', card: $event })"
      />

      <MySection :name="getText('swimmingPool.hasRoof.question')" :information="getText('swimmingPool.hasRoof.info')">
        <ToggleButton
          :value="swimmingPoolHasRoof"
          @input="setSwimmingPoolHasRoof"
        />
      </MySection>
    </div>

    <MySection :name="getText('fence.hasFence.question')" :information="getText('fence.hasFence.info')">
      <ToggleButton :value="hasFence" @input="setHasFence" />
    </MySection>
    <div class="fence" v-show="hasFence">
      <!-- New Fence -->
      <div v-if="hasNewFence">

        <div class="fence" v-show="hasFence">
          <MySection :name="getText('fence.length.question')" :information="getText('fence.length.info')">
            <div class="flex-column">
              <label class="label" v-text="getText('fence.street.question')"></label>
              <NumberWithUnitInput
                :value="fenceStreet"
                @input="setFenceStreet"
                unit="m"
              />
            </div>

            <div class="flex-column">
              <label class="label" v-text="getText('fence.other.question')"></label>
              <NumberWithUnitInput
                :value="fenceOther"
                @input="setFenceOther"
                unit="m"
              />
            </div>
          </MySection>
          <!-- Street Side -->
          <MySection
            :name="getText('fence.streetConstructionType.question')"
            :information="getText('fence.streetConstructionType.info')"
          >
            <MultipleChoice
              :options="getSchemaOptions('surroundings.fence.streetConstructionType', 'fence.fenceConstructionType')"
              :modelValue="fenceStreetConstructionType"
              @update:modelValue="setFenceStreetConstructionType"
            />
          </MySection>

          <MySection
            :name="getText('fence.streetFillingType.question')"
            :information="getText('fence.streetFillingType.info')"
            v-show="fenceStreetConstructionType === 'combined wall fence'"
          >
            <MultipleChoice
              :options="getSchemaOptions('surroundings.fence.streetFillingType', 'fence.fenceFillingType')"
              :modelValue="fenceStreetFillingType"
              @update:modelValue="setFenceStreetFillingType"
            />
          </MySection>

          <h1 class="title" v-text="getText('fenceStreetTemplateCardSelection')"></h1>
          <CardSelection
            ref="fenceStreetTemplateCardSelection"
            single-answer
            gab="20px"
            :options="cardsFor('fenceStreetTemplate')"
            :value="getActiveCard('fenceStreetTemplate')"
            @input="setActiveCard({ slot: 'fenceStreetTemplate', id: $event })"
            @editCard="onEditCard({ slot: 'fenceStreetTemplate', card: $event })"
          />

          <MySection :name="getText('fence.gateOpener.question')" :information="getText('fence.gateOpener.info')">
            <MultipleChoice
              :options="getSchemaOptions('surroundings.fence.gateOpener')"
              :modelValue="gateOpener"
              @update:modelValue="setGateOpener"
            />
          </MySection>
          
          <h1 class="title" v-text="getText('gateTemplateCardSelection')"></h1>
          <CardSelection
            ref="gateTemplateCardSelection"
            single-answer
            gab="20px"
            :options="cardsFor('gateTemplate')"
            :value="getActiveCard('gateTemplate')"
            @input="setActiveCard({ slot: 'gateTemplate', id: $event })"
            @editCard="onEditCard({ slot: 'gateTemplate', card: $event })"
          />

          <h1 class="title" v-text="getText('fenceDoorTemplateCardSelection')"></h1>
          <CardSelection
            ref="fenceDoorTemplateCardSelection"
            single-answer
            gab="20px"
            :options="cardsFor('fenceDoorTemplate')"
            :value="getActiveCard('fenceDoorTemplate')"
            @input="setActiveCard({ slot: 'fenceDoorTemplate', id: $event })"
            @editCard="onEditCard({ slot: 'fenceDoorTemplate', card: $event })"
          />

          <!-- Other -->
          <MySection 
            :name="getText('fence.otherConstructionType.question')"
            :information="getText('fence.otherConstructionType.info')"
          >
            <MultipleChoice
              :options="getSchemaOptions('surroundings.fence.otherConstructionType', 'fence.fenceConstructionType')"
              :modelValue="fenceOtherConstructionType"
              @update:modelValue="setFenceOtherConstructionType"
            />
          </MySection>

          <MySection
            :name="getText('fence.otherFillingType.question')"
            :information="getText('fence.otherFillingType.info')"
            v-show="fenceOtherConstructionType === 'combined wall fence'"
          >
            <MultipleChoice
              :options="getSchemaOptions('surroundings.fence.otherFillingType', 'fence.fenceFillingType')"
              :modelValue="fenceOtherFillingType"
              @update:modelValue="setFenceOtherFillingType"
            />
          </MySection>

          <h1 class="title" v-text="getText('fenceOtherTemplateCardSelection')"></h1>
          <CardSelection
            ref="fenceOtherTemplateCardSelection"
            single-answer
            gab="20px"
            :options="cardsFor('fenceOtherTemplate')"
            :value="getActiveCard('fenceOtherTemplate')"
            @input="setActiveCard({ slot: 'fenceOtherTemplate', id: $event })"
            @editCard="onEditCard({ slot: 'fenceOtherTemplate', card: $event })"
          />
        </div>
      </div> <!-- Has new fence -->

      <!-- Old Fence -->
      <div v-else>
        <MySection :name="getText('fence.length.question')" :information="getText('fence.length.info')">
          <div class="flex-column">
            <label class="label" v-text="getText('fence.front.question')"></label>
            <NumberWithUnitInput
              :value="fenceFront"
              @input="setFenceFront"
              unit="m"
            />
          </div>

          <div class="flex-column">
            <label class="label" v-text="getText('fence.side1.question')"></label>
            <NumberWithUnitInput
              :value="fenceSide1"
              @input="setFenceSide1"
              unit="m"
            />
          </div>

          <div class="flex-column">
            <label class="label" v-text="getText('fence.side2.question')"></label>
            <NumberWithUnitInput
              :value="fenceSide2"
              @input="setFenceSide2"
              unit="m"
            />
          </div>

          <div class="flex-column">
            <label class="label" v-text="getText('fence.rear.question')"></label>
            <NumberWithUnitInput
              :value="fenceRear"
              @input="setFenceRear"
              unit="m"
            />
          </div> 
        </MySection>
        <!-- Front -->
        <MySection
          :name="getText('fence.frontConstructionType.question')"
          :information="getText('fence.frontConstructionType.info')"
        >
          <MultipleChoice
            :options="getSchemaOptions('surroundings.fence.frontConstructionType', 'fence.fenceConstructionType')"
            :modelValue="fenceFrontConstructionType"
            @update:modelValue="setFenceFrontConstructionType"
          />
        </MySection>

        <MySection
          :name="getText('fence.frontFillingType.question')"
          :information="getText('fence.frontFillingType.info')"
          v-show="fenceFrontConstructionType === 'combined wall fence'"
        >
          <MultipleChoice
            :options="getSchemaOptions('surroundings.fence.frontFillingType', 'fence.fenceFillingType')"
            :modelValue="fenceFrontFillingType"
            @update:modelValue="setFenceFrontFillingType"
          />
        </MySection>

        <h1 class="title" v-text="getText('fenceFrontTemplateCardSelection')"></h1>
        <CardSelection
          ref="fenceFrontTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('fenceFrontTemplate')"
          :value="getActiveCard('fenceFrontTemplate')"
          @input="setActiveCard({ slot: 'fenceFrontTemplate', id: $event })"
          @editCard="onEditCard({ slot: 'fenceFrontTemplate', card: $event })"
        />
        <!-- Side -->
        <MySection 
          :name="getText('fence.sideConstructionType.question')"
          :information="getText('fence.sideConstructionType.info')"
        >
          <MultipleChoice
            :options="getSchemaOptions('surroundings.fence.sideConstructionType', 'fence.fenceConstructionType')"
            :modelValue="fenceSideConstructionType"
            @update:modelValue="setFenceSideConstructionType"
          />
        </MySection>

        <MySection
          :name="getText('fence.sideFillingType.question')"
          :information="getText('fence.sideFillingType.info')"
          v-show="fenceSideConstructionType === 'combined wall fence'"
        >
          <MultipleChoice
            :options="getSchemaOptions('surroundings.fence.sideFillingType', 'fence.fenceFillingType')"
            :modelValue="fenceSideFillingType"
            @update:modelValue="setFenceSideFillingType"
          />
        </MySection>

        <h1 class="title" v-text="getText('fenceSideTemplateCardSelection')"></h1>
        <CardSelection
          ref="fenceSideTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('fenceSideTemplate')"
          :value="getActiveCard('fenceSideTemplate')"
          @input="setActiveCard({ slot: 'fenceSideTemplate', id: $event })"
          @editCard="onEditCard({ slot: 'fenceSideTemplate', card: $event })"
        />
        <!-- Rear -->
        <MySection 
          :name="getText('fence.rearConstructionType.question')"
          :information="getText('fence.rearConstructionType.info')"
        >
          <MultipleChoice
            :options="getSchemaOptions('surroundings.fence.rearConstructionType', 'fence.fenceConstructionType')"
            :modelValue="fenceRearConstructionType"
            @update:modelValue="setFenceRearConstructionType"
          />
        </MySection>

        <MySection
          :name="getText('fence.rearFillingType.question')"
          :information="getText('fence.rearFillingType.info')"
          v-show="fenceRearConstructionType === 'combined wall fence'"
        >
          <MultipleChoice
            :options="getSchemaOptions('surroundings.fence.rearFillingType', 'fence.fenceFillingType')"
            :modelValue="fenceRearFillingType"
            @update:modelValue="setFenceRearFillingType"
          />
        </MySection>

        <h1 class="title" v-text="getText('fenceRearTemplateCardSelection')"></h1>
        <CardSelection
          ref="fenceRearTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('fenceRearTemplate')"
          :value="getActiveCard('fenceRearTemplate')"
          @input="setActiveCard({ slot: 'fenceRearTemplate', id: $event })"
          @editCard="onEditCard({ slot: 'fenceRearTemplate', card: $event })"
        />
      </div> <!-- old fence -->
    </div> <!-- fence -->

    <!-- Paved Surface Terrace -->

    <MySection :name="getText('terrace.hasTerrace.question')" :information="getText('terrace.hasTerrace.info')">
      <ToggleButton :value="hasTerrace" @input="setHasTerrace" />
    </MySection>
    <div class="paved-surface" v-show="hasTerrace">
      <MySection :name="getText('terrace.size.question')" :information="getText('terrace.size.info')">
        <NumberWithUnitInput
          :value="terraceArea"
          @input="setTerraceArea"
          unit="m<sup>2</sup>"
        />
      </MySection>

      <MySection :name="getText('terrace.material.question')" :information="getText('terrace.material.info')">
        <MultipleChoice
          :options="getSchemaOptions('surroundings.terrace.material')"
          :modelValue="terraceMaterial"
          @update:modelValue="setTerraceMaterial"
        />
      </MySection>

      <MySection 
        :name="getText('terrace.concretePavingType.question')" 
        :information="getText('terrace.concretePavingType.info')"
        v-show="terraceMaterial === 'concrete paving'"
      >
        <MultipleChoice
          :options="
            getSchemaOptions('surroundings.terrace.concretePavingType')
          "
          :modelValue="terraceConcreteType"
          @update:modelValue="setTerraceConcreteType"
        />
      </MySection>

      <h1 class="title" v-text="getText('terraceTemplateCardSelection')"></h1>

      <CardSelection
        ref="terraceTemplateCardSelection"
        single-answer
        gab="20px"
        :options="cardsFor('terraceTemplate')"
        :value="getActiveCard('terraceTemplate')"
        @input="setActiveCard({ slot: 'terraceTemplate', id: $event })"
        @editCard="onEditCard({ slot: 'terraceTemplate', card: $event })"
      />
    </div>

    <!-- Paved Surface Paving -->
    
    <MySection :name="getText('pavedSurface.hasPavedSurface.question')" :information="getText('pavedSurface.hasPavedSurface.info')">
      <ToggleButton :value="hasPavedSurface" @input="setHasPavedSurface" />
    </MySection>
    <div class="paved-surface" v-show="hasPavedSurface">
      <MySection :name="getText('pavedSurface.size.question')" :information="getText('pavedSurface.size.info')">
        <NumberWithUnitInput
          :value="pavedSurfaceSize"
          @input="setPavedSurfaceSize"
          unit="m<sup>2</sup>"
        />
      </MySection>

      <MySection :name="getText('pavedSurface.material.question')" :information="getText('pavedSurface.material.info')">
        <MultipleChoice
          :options="getSchemaOptions('surroundings.pavedSurface.material')"
          :modelValue="pavedSurfaceMaterial"
          @update:modelValue="setPavedSurfaceMaterial"
        />
      </MySection>

      <MySection 
        :name="getText('pavedSurface.concretePavingType.question')" 
        :information="getText('pavedSurface.concretePavingType.info')"
        v-show="pavedSurfaceMaterial === 'concrete paving'"
      >
        <MultipleChoice
          :options="
            getSchemaOptions('surroundings.pavedSurface.concretePavingType')
          "
          :modelValue="pavedSurfaceConcretePavingType"
          @update:modelValue="setPavedSurfaceConcretePavingType"
        />
      </MySection>

      <h1 class="title" v-text="getText('pavedSurfacePavingTemplateCardSelection')"></h1>

      <CardSelection
        ref="pavedSurfacePavingTemplateCardSelection"
        single-answer
        gab="20px"
        :options="cardsFor('pavedSurfacePavingTemplate')"
        :value="getActiveCard('pavedSurfacePavingTemplate')"
        @input="setActiveCard({ slot: 'pavedSurfacePavingTemplate', id: $event })"
        @editCard="onEditCard({ slot: 'pavedSurfacePavingTemplate', card: $event })"
      />
    </div>

    <!-- advanced-->
    <AdvancedQuestionsToggleButton v-model="advancedMode" />

    <div v-show="advancedMode">
      <!-- Playground -->
      <MySection 
        :name="getText('playground.hasPlayground.question')" 
        :information="getText('playground.hasPlayground.info')"
      >
        <ToggleButton :value="hasPlayground" @input="setHasPlayground" />
      </MySection>

      <div class="playground" v-show="hasPlayground">
        <MySection :name="getText('playground.material.question')" :information="getText('playground.material.info')">
          <MultipleChoice
            :options="getSchemaOptions('surroundings.playground.material')"
            :modelValue="playgroundMaterial"
            @update:modelValue="setPlaygroundMaterial"
          />
        </MySection>

        <MySection 
          :name="getText('playground.maxNumberOfChildren.question')" 
          :information="getText('playground.maxNumberOfChildren.info')"
        >
          <MultipleChoice
            :options="getSchemaOptions('surroundings.playground.maxNumberOfChildren')"
            :modelValue="playgroundMaxNumberOfChildren"
            @update:modelValue="setPlaygroundMaxNumberOfChildren"
          />
        </MySection>

        <h1 class="title" v-text="getText('playgroundPlaygroundTemplateCardSelection')"></h1>
        <CardSelection
          ref="playgroundPlaygroundTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('playgroundPlaygroundTemplate')"
          :value="getActiveCard('playgroundPlaygroundTemplate')"
          @input="setActiveCard({ slot: 'playgroundPlaygroundTemplate', id: $event })"
          @editCard="onEditCard({slot: 'playgroundPlaygroundTemplate', card: $event})"
        />
      </div>
      <!-- Garden house -->
      <MySection 
        :name="getText('gardenHouse.hasGardenHouse.question')" 
        :information="getText('gardenHouse.hasGardenHouse.info')"
      >
        <ToggleButton :value="hasGardenHouse" @input="setHasGardenHouse" />
      </MySection>
      <div class="garden-house" v-show="hasGardenHouse">
        <MySection :name="getText('gardenHouse.material.question')" :information="getText('gardenHouse.material.info')">
          <MultipleChoice
            :options="getSchemaOptions('surroundings.gardenHouse.material')"
            :modelValue="gardenHouseMaterial"
            @update:modelValue="setGardenHouseMaterial"
          />
        </MySection>
        <MySection :name="getText('gardenHouse.size.question')" :information="getText('gardenHouse.size.info')">
          <MultipleChoice
            :options="getSchemaOptions('surroundings.gardenHouse.size')"
            :modelValue="gardenHouseSize"
            @update:modelValue="setGardenHouseSize"
          />
        </MySection>
        <h1 class="title" v-text="getText('gardenHouseGardenHouseTemplateCardSelection')"></h1>
        <CardSelection
          ref="gardenHouseGardenHouseTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('gardenHouseGardenHouseTemplate')"
          :value="getActiveCard('gardenHouseGardenHouseTemplate')"
          @input="setActiveCard({ slot: 'gardenHouseGardenHouseTemplate', id: $event })"
          @editCard="onEditCard({ slot: 'gardenHouseGardenHouseTemplate', card: $event })"
        />
      </div>

      <!-- Pergola -->
      <MySection :name="getText('pergola.hasPergola.question')" :information="getText('pergola.hasPergola.info')">
        <ToggleButton :value="hasPergola" @input="setHasPergola" />
      </MySection>
      <div class="pergola" v-show="hasPergola">
        <MySection :name="getText('pergola.size.question')" :information="getText('pergola.size.info')">
          <div class="flex-column">
            <label v-text="getText('pergola.width.question')"></label>
            <NumberWithUnitInput
              :value="pergolaWidth"
              @input="setPergolaWidth"
              unit="m"
            />
          </div>
          <div class="flex-column">
            <label v-text="getText('pergola.width.question')"></label>
            <NumberWithUnitInput
              :value="pergolaLength"
              @input="setPergolaLength"
              unit="m"
            />
          </div>
        </MySection>

        <MySection :name="getText('pergola.material.question')" :information="getText('pergola.material.info')">
          <MultipleChoice
            :options="staticData.surroundings.pergolaMaterialOptions"
            :modelValue="pergolaMaterial"
            @update:modelValue="setPergolaMaterial"
          />
        </MySection>

        <h1 class="title" v-text="getText('pergolaTemplateCardSelection')"></h1>
        <CardSelection
          ref="pergolaTemplateCardSelection"
          single-answer
          gab="20px"
          :options="cardsFor('pergolaTemplate')"
          :value="getActiveCard('pergolaTemplate')"
          @input="setActiveCard({ slot: 'pergolaTemplate', id: $event })"
          @editCard="onEditCard({ slot: 'pergolaTemplate', card: $event })"
        />
      </div>
    </div>
    <!--advanced ends-->

    <div class="section-controls">
      <button @click="$router.push('/hvac')" class="secondary transparent" v-text="getText('previousButton')"></button>
      <button @click="$router.push('/location')" class="primary" v-text="getText('nextButton')"></button>
    </div> 
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";
import MySection from "../components/MySection";
import CardDialog from "../components/cardDialog";
import {
  ToggleButton /* , CounterInput */,
  AdvancedQuestionsToggleButton,
  NumberWithUnitInput,
  MultipleChoice,
  CardSelection,
//  CounterInput,
} from "../components/customElements";

export default {
  name: "SurroundingsTab",
  components: {
    ToggleButton,
    MySection,
    AdvancedQuestionsToggleButton,
    NumberWithUnitInput,
    MultipleChoice,
    CardSelection,
    CardDialog,
  },
  data() {
    return {
      staticData: {
        wienerbergerClassOptions: [
          {
            name: "Cenově dostupné",
            value: "affordable",
          },
          {
            name: "Vyvážené",
            value: "smart",
          },
          {
            name: "Nejlepší na trhu",
            value: "premium",
          },
        ],
        surroundings: {
          swimmingPoolConstructionTypeOptions: [
            {
              name: "Těžká fólie",
              image: require("@/assets/image-holder.png"),
              value: "heavy foil",
            },
            {
              name: "Laminát",
              image: require("@/assets/image-holder.png"),
              value: "laminate",
            },
            {
              name: "Plast",
              image: require("@/assets/image-holder.png"),
              value: "plastic",
            },
            {
              name: "Nerez",
              image: require("@/assets/image-holder.png"),
              value: "stainless steel",
            },
            {
              name: "Nadzemní",
              image: require("@/assets/image-holder.png"),
              value: "on-ground",
            },
          ],
          fenceConstructionTypeOptions: [
            {
              name: "S podezdívkou",
              image: require("@/assets/image-holder.png"),
              value: "combined wall fence",
            },
            {
              name: "Plastové plotovky",
              image: require("@/assets/image-holder.png"),
              value: "plastic fence pickets",
            },
            {
              name: "Dřevěné plotovky",
              image: require("@/assets/image-holder.png"),
              value: "wood fence pickets",
            },
            {
              name: "Betonový plot",
              image: require("@/assets/image-holder.png"),
              value: "concrete fence",
            },
            {
              name: "Drátěné pletivo",
              image: require("@/assets/image-holder.png"),
              value: "wired mesh",
            },
            {
              name: "Svařované panely",
              image: require("@/assets/image-holder.png"),
              value: "welded fence panels",
            },
          ],
          fenceFillingTypeOptions: [
            {
              name: "Dřevěná",
              image: require("@/assets/image-holder.png"),
              value: "wood",
            },
            {
              name: "Kovová",
              image: require("@/assets/image-holder.png"),
              value: "metal",
            },
            {
              name: "Plastová",
              image: require("@/assets/image-holder.png"),
              value: "plastic",
            },
          ],
          pavedSurfaceMaterialOptions: [
            {
              name: "Kamenný koberec",
              image: require("@/assets/image-holder.png"),
              value: "stone carpets",
            },
            {
              name: "Dlažba",
              image: require("@/assets/image-holder.png"),
              value: "concrete paving",
            },
            {
              name: "Přírodní kámen",
              image: require("@/assets/image-holder.png"),
              value: "natural stone",
            },
          ],
          pavedSurfaceConcretePavingTypeOptions: [
            {
              name: "Zámková dlažba",
              image: require("@/assets/image-holder.png"),
              value: "interlocking concrete",
            },
            {
              name: "Zatravňovací dlažba",
              image: require("@/assets/image-holder.png"),
              value: "grass pavement",
            },
            {
              name: "Skladebná dlažba",
              image: require("@/assets/image-holder.png"),
              value: "composite pavement",
            },
            {
              name: "Velkoformátová dlažba",
              image: require("@/assets/image-holder.png"),
              value: "large format paving",
            },
          ],
          playgroundMaterialOptions: [
            {
              name: "Dřevo",
              value: "wood",
            },
            {
              name: "Plast",
              value: "plastic",
            },
            {
              name: "Kov",
              value: "metal",
            },
          ],
          playgroundMaxNumberOfChildrenOptions: [
            {
              name: "1",
              value: "1",
            },
            {
              name: "2",
              value: "2",
            },
            {
              name: "3",
              value: "3",
            },
            {
              name: "4",
              value: "4",
            },
            {
              name: "Více",
              value: "more",
            },
          ],
          gardenHouseMaterialOptions: [
            {
              name: "Dřevo",
              value: "wood",
            },
            {
              name: "Plast",
              value: "plastic",
            },
            {
              name: "Kov",
              value: "metal",
            },
          ],
          gardenHouseSizeOptions: [
            {
              name: "Malý (do 8 m^2)",
              image: require("@/assets/image-holder.png"),
              value: "small",
            },
            {
              name: "Střední (do 15 m^2)",
              image: require("@/assets/image-holder.png"),
              value: "medium",
            },
            {
              name: "Velký (do 25 m^2)",
              image: require("@/assets/image-holder.png"),
              value: "large",
            },
          ],
          pergolaMaterialOptions: [
            {
              name: "Hliník",
              image: require("@/assets/image-holder.png"),
              value: "aluminium",
            },
            {
              name: "Dřevo",
              image: require("@/assets/image-holder.png"),
              value: "wood",
            },
            {
              name: "Sluneřní plachta",
              image: require("@/assets/image-holder.png"),
              value: "tarpaulin",
            },
            {
              name: "Výsuvná markýza",
              image: require("@/assets/image-holder.png"),
              value: "awning",
            },
          ],
          flags: [
            {
              name: "Bazén",
              variableName: "swimmingPool",
              advanced: true,
            },
            {
              name: "Oplocení",
              variableName: "fence",
              advanced: true,
            },
            {
              name: "Zpevnění plochy",
              variableName: "fall protectnion",
              advanced: true,
            },
            {
              name: "Dětské hřiště",
              variableName: "playground",
              advanced: true,
            },
            {
              name: "Zahradní domek",
              variableName: "gardenHouse",
              advanced: true,
            },
            {
              name: "Pergola",
              variableName: "pergola",
              advanced: true,
            },
          ],
        },
      },
      cardToOpen: {},
      cardToOpenSlot: "",
    };
  },
  computed: {
    advancedMode: {
      get() {
        return this.$store.getters.advanced("surroundings");
      },
      set(v) {
        this.$store.commit("advanced", { value: v, tab: "surroundings" });
      },
    },
    ...mapGetters([
      "hasWienerbergerFamily",
      "hasGservisFamily",
      "getText",
      'getSchema',
      'getSchemaOptions',
    ]),
    ...mapGetters("cards", ["cardsFor"]),
    ...mapGetters("configuration/goods", ["getActiveCard", "getCardSetup"]),
    ...mapGetters("configuration/surroundings", [
      "wienerbergerClass",
      "swimmingPoolWidth",
      "swimmingPoolLength",
      "swimmingPoolConstructionType",
      "swimmingPoolHasRoof",
      "fenceFront",
      "fenceSide1",
      "fenceSide2",
      "fenceRear",
      "fenceFrontConstructionType",
      "fenceSideConstructionType",
      "fenceRearConstructionType",
      "fenceFrontFillingType",
      "fenceSideFillingType",
      "fenceRearFillingType",
      "hasNewFence",
      "fenceStreet",
      "fenceOther",
      "fenceStreetConstructionType",
      "fenceOtherConstructionType",
      "fenceStreetFillingType",
      "fenceOtherFillingType",
      "gateOpener",
      "pavedSurfaceSize",
      "pavedSurfaceMaterial",
      "pavedSurfaceConcretePavingType",
      "terraceArea",
      "terraceMaterial",
      "terraceConcreteType",
      "playgroundMaterial",
      "playgroundMaxNumberOfChildren",
      "gardenHouseMaterial",
      "gardenHouseSize",
      "pergolaMaterial",
      "pergolaWidth",
      "pergolaLength",
      "hasSwimmingPool",
      "hasFence",
      "hasPavedSurface",
      "hasTerrace",
      "hasPlayground",
      "hasGardenHouse",
      "hasPergola",
    ]),
  },
  methods: {
    onEditCard({card, slot}) {
      this.cardToOpen = card;
      this.cardToOpenSlot = slot;
      this.$refs.cardDialog.show = true;
    },
    isGservis() {
      return this.hasGservisFamily;
    },
    isWienerberger() {
      return this.hasWienerbergerFamily;
    },
    ...mapMutations("configuration/goods", ["setActiveCard"]),
    ...mapMutations("configuration/surroundings", [
      "setWienerbergerClass",
      "setSwimmingPoolWidth",
      "setSwimmingPoolLength",
      "setSwimmingPoolConstructionType",
      "setSwimmingPoolHasRoof",
      "setFenceFront",
      "setFenceSide1",
      "setFenceSide2",
      "setFenceRear",
      "setFenceFrontConstructionType",
      "setFenceSideConstructionType",
      "setFenceRearConstructionType",
      "setFenceFrontFillingType",
      "setFenceSideFillingType",
      "setFenceRearFillingType",
      "setGateOpener",
      "setFenceStreet",
      "setFenceOther",
      "setFenceStreetConstructionType",
      "setFenceOtherConstructionType",
      "setFenceStreetFillingType",
      "setFenceOtherFillingType",
      "setPavedSurfaceSize",
      "setPavedSurfaceMaterial",
      "setPavedSurfaceConcretePavingType",
      "setTerraceArea",
      "setTerraceMaterial",
      "setTerraceConcreteType",
      "setPlaygroundMaterial",
      "setPlaygroundMaxNumberOfChildren",
      "setGardenHouseMaterial",
      "setGardenHouseSize",
      "setPergolaMaterial",
      "setPergolaWidth",
      "setPergolaLength",
      "setHasSwimmingPool",
      "setHasFence",
      "setHasPavedSurface",
      "setHasTerrace",
      "setHasPlayground",
      "setHasGardenHouse",
      "setHasPergola",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.x {
  margin: auto 12px;
}
label.label {
  color: $secondary-color;
}
.flex-column {
  margin: auto 15px;
}
</style>
