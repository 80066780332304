import { createApp } from 'vue'
import App from "./App.vue";
import router from "./router";
import store from "./store";


const app = createApp(App)

app.use(store).use(router).mount('#app')

app.config.globalProperties.$filters = {
  pricePrettier(value) {
    if (!value) return "0";
    if (value < 1000) return Math.floor(value)
    const valueRounded = Math.round(value / 1000);
    const digits = String(valueRounded);
    let result = "";
    let len = digits.length;
    let processed = 0;
    while (processed < len) {
      const group = digits.slice(
        Math.max(len - processed - 3, 0),
        len - processed
      );
      result = group + " " + result;
      processed += 3;
    }
    return result.trim();
  },
  energeticsPrettier(value) {
    if (!value) return "0";
    if (value < 1000) return Math.round(value)
    value = Math.round(value / 100) * 100;
    const valueRounded = Math.floor(value / 1000);
    const tail = Math.round((value % 1000) / 100)
    const digits = String(valueRounded);
    let result = "";
    let len = digits.length;
    let processed = 0;
    while (processed < len) {
      const group = digits.slice(
        Math.max(len - processed - 3, 0),
        len - processed
      );
      result = group + " " + result;
      processed += 3;
    }
    return result.trim() + ',' + tail;
  }

}
