<template>
  <div class="preview">
    <div class="flex-row heading-row">
      <input class="project-name" :placeholder="getText('projectNamePlaceholder')" v-show="isWienerberger()" />

      <div class="tool-item mode">
        <span class="progressbar-string">
          <strong v-text="getText('onOrder.question')"></strong>
        </span>
        <div class="setup flex-inline">
          <p class="mode-basic" v-text="getText('onOrder.option1')"></p>
          <ToggleButton :value="onOrder" @input="setOnOrder($event)" />
          <p class="mode-advanced" v-text="getText('onOrder.option2')"></p>
        </div>
      </div>
    </div>

    <!-- Price view dialog -->
    <MyDialog v-if="refreshToken" :show="showDebug" @input="showDebug = false" >
      <h1>podrobné ceny</h1>
      <pre>
        {{deltas}}
      </pre>
    </MyDialog>

    <MyDialog 
      :heading="getText('warning.discoveries')"
      :show="showWarnings" 
      @input="showWarnings = false" >
      <p class="error" 
        v-for="(warning, idx) in warnings" 
        :key="idx">
        <i class="fa fa-exclamation-triangle" style="padding-right: 10px"></i>
        <span v-text="getText(warning.textId, warning.params)"></span>
      </p>
      <button @click="showWarnings = false" class="primary">OK</button>
    </MyDialog>

    <div class="flex-row project-preview">
      <img :src="previewHouse.image || housePreview" class="img-col" />

      <div class="price-col flex-column">
        <span v-text="getText('priceTotal')"></span>
        <div class="price flex-row">
          <i class="fa fa-key"></i>
          <h1 class="price-value" @click="showDebug=true">
            {{ $filters.pricePrettier(totalPrice) }}
            <sub><span v-show="totalPrice >= 1000">000</span> {{getText('currency')}}</sub>
          </h1>
          <div v-show="warnings && warnings.length" class="relative">
            <i 
              @click="showWarnings = true" 
              class="warning-button fa fa-exclamation-triangle">
            </i>   
          </div>
        </div>
        <span v-text="getText('priceHouse')"></span>
        <div class="price flex-row">
          <i class="fa fa-home"></i>
          <h2 class="price-value">
            {{ $filters.pricePrettier(housePrice) }}
            <sub><span v-show="housePrice >= 1000">000</span> {{getText('currency')}}</sub>
          </h2>
        </div>
        <span v-text="getText('priceSurroundings')"></span>
        <div class="price flex-row">
          <i class="fa fa-umbrella-beach"></i>
          <h2 class="price-value">
            {{ $filters.pricePrettier(surroundingsPrice) }}
            <sub><span v-show="surroundingsPrice >= 1000">000</span> {{getText('currency')}}</sub>
          </h2>
        </div>
        <span v-text="getText('priceTaxNote')"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { ToggleButton } from "../components/customElements";
import MyDialog from '../components/MyDialog.vue';

export default {
  components: {
    ToggleButton,
    MyDialog,
  },
  data() {
    return {
      showDebug: false,
      showWarnings: false,
      housePreview: require("../../assets/results/house-preview.png"),
    };
  },
  computed: {
    ...mapGetters("result", [
      "totalPrice",
      "housePrice",
      "deltas",
      "surroundingsPrice",
      "warnings",
    ]),
    ...mapGetters("recommandedHouses", [
      "previewHouse",
    ]),
    ...mapGetters("configuration/user", ["onOrder"]),
    ...mapGetters([
      "hasWienerbergerFamily", 
      "hasGservisFamily",
      "refreshToken",
      "getText",
    ])
  },
  methods: {
    ...mapMutations("configuration/user", ["setOnOrder"]),
    isGservis() {
      return this.hasGservisFamily;
    },
    isWienerberger() {
      return this.hasWienerbergerFamily;
    },
    log() {
      console.log(this.warnings);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_functions.scss";
.preview {
  .project-name {
    font-weight: 600;
    font-size: 1.2em;
    &::placeholder {
      opacity: 1;
      color: black;
    }
  
  }
  .error {
    color: $primary-color;
    font-weight: 300;
  } 
  .project-preview {
    img {
      margin-right: 10px;
      width: calc(100% / 1.618 - 5px);
    }
    .price-col {
      width: calc(100% - 100% / 1.618 - 5px);
      background: transparent;
      align-items: flex-end;

      .price {
        flex-grow: 10;
        h1.price-value,
        h2.price-value {
          text-align: center;
          margin: 0;
          font-weight: 300;
          letter-spacing: -0.49px;
          white-space: nowrap;

          sub {
            font-weight: 300;
            letter-spacing: 0.13px;
            position: relative;
            right: 0px;
            bottom: 0.5em;
          }
        }
        i.fa {
          align-self: center;
          color: #707070;
          padding: 5px;
        }
        .relative {
          position: relative;
          width: 0;
          // right: larp(30, 70, 350, 1444);
          // top: larp(20, 30, 350, 1444);
          i.warning-button {
            color: $primary-color;
            cursor: pointer;
            &:hover {
              color: lighten($primary-color, 20%);
            }
          }
        }
      }
      button {
        margin: 0;
        padding: 10px 0;
        width: 100%;
        //font-size: larp(10, 30, 350, 1444);
        //line-height: larp(12, 60, 350, 1444);
        white-space: normal;
        height: auto;

        &.primary {
          margin: 10px 0 0 0;
          flex-grow: 6;
        }
        &.secondary {
          flex-grow: 4;
        }
      }
    }
  }
}
</style>
