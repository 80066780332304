<template>
  <div class="section" v-if="!advanced || $store.getters.advanced(tab)">
    <div class="flex-inline">
      <h3>
        {{ name }}
        <i class="fa fa-info-circle" v-show="information" :class="{ tooltip: information }">
          <span class="tooltiptext" v-html="information"></span>
        </i>
        <!-- <span v-show="advanced" class="advanced">Pokročilá otázka</span> -->
      </h3>
      <slot></slot>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: 'MySection',
  props: {
    name: {
      required: true,
      type: String,
    },
    information: {
      type: String,
    },
    advanced: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: String,
      default: "household",
    },
  },
};
</script>

<style lang="scss">
//.section {
  .tooltip {
    position: relative;
    display: inline-block;
    //border-bottom: 1px dotted black;
  }

  .tooltip .tooltiptext {
    font: 400 16px/23px roboto;
    //font-size: 16px;
    //font-weight: 300;
    color: black;
    width: 480px;
    text-align: center;
    padding: 16px;
    visibility: hidden;
    background-color: white;
    color: black;
    border-color:black;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 110%;
  }

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
//}
</style>
