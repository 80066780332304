<template>
  <div 
    class="labeled-counter"
    :class="{
      'flex-column': labelPosition == 'top' || labelPosition == 'bottom',
      'text-center': labelPosition == 'top' || labelPosition == 'bottom',
      'flex-row': labelPosition == 'left' || labelPosition == 'right',
      'left': labelPosition == 'left',
      'right': labelPosition == 'right',
    }"
  >
    <p class="label" :class="{reverse: labelPosition == 'bottom' || labelPosition == 'right'}">
      <slot></slot>
    </p>
    <CounterInput 
      :value="value" 
      @input="onInput" 
      :disabled="getDisabled()" 
      :min="getMin()" 
      :max="getMax()" 
    />
  </div>
</template>

<script>
import CounterInput from "./CounterInput";

export default {
  name: "LabaledCounter",
  props: {
    value: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: -1e10,
    },
    max: {
      type: Number,
      default: 1e10,
    },
    schema: {
      min: { 
        type: Number,
      },
      max: { 
        type: Number,
      }
    },
    labelPosition: {
      type: String,
      default: 'top'
    }
  },
  components: {
    CounterInput,
  },
  data() {
    return { };
  },
  methods: {
    onInput(value) {
      this.$emit("input", Number(value));
    },
    getMin() {
      if (this.schema && 'min' in this.schema) return this.schema.min;
      return this.min;
    },
    getMax() {
      if (this.schema && 'max' in this.schema) return this.schema.max;
      return this.max;
    },
    getDisabled() {
      if (this.schema && 'frozen' in this.schema) return this.schema.frozen;
      return this.disabled;
    }
  },
};
</script>

<style lang="scss">
.labeled-counter {
  background: transparent; /* for faster debbugging */
  &.right > .counter {
    padding: 5px;
  }
  &.left {
    justify-content: space-between;
  }
}
.label {
  margin: auto 4px auto 4px;
  color: #7e7e7e;
  &.reverse { 
    order: 1;
  }
}
</style>
