<template>
  <div class="backup-diff">
    <div v-if="changes.texts" class="texts">
      <CollectionDiff :collection="changes.texts.wienerberger" collectionTitle="Texty wienerberger" mainItemParam="id"/>
      <CollectionDiff :collection="changes.texts.gservis" collectionTitle="Texty gservis" mainItemParam="id"/>
      <CollectionDiff :collection="changes.cards.wienerberger" collectionTitle="Karty wienerberger" mainItemParam="name"/>
      <CollectionDiff :collection="changes.cards.gservis" collectionTitle="Karty gservis" mainItemParam="name"/>
      <CollectionDiff :collection="changes.houses.wienerberger" collectionTitle="Domy wienerberger" mainItemParam="id"/>
      <CollectionDiff :collection="changes.houses.gservis" collectionTitle="Domy gservis" mainItemParam="id"/>
      <CollectionDiff :collection="changes.manufacturers" collectionTitle="Výrobci" mainItemParam="id"/>

            <!--div v-if="changes.texts.gservis" class="gservis">
                <h2>Texty gservis</h2>
                <div :key="change.id" v-for="change in changes.texts.gservis">
                    <ItemDiff :change="change" main="id" />              
                </div>
            </div>
        </div>

        <div v-if="changes.cards" class="cards">
            <h2>Karty</h2>
            <div :key="change.id" v-for="change in changes.cards">
                <ItemDiff :change="change" main="name"/>              
        </div-->
    </div>
  </div>
</template>

<script>
import CollectionDiff from './CollectionDiff.vue'
export default {
  components: { CollectionDiff },
    props: {
        changes: Object
    }
}
</script>

<style>

</style>