<template>
  <div class="load">
      <h1>Vítejte zpět</h1>
      <pre>{{ $router.currentRoute.params.hash }}</pre>
      <p v-show="errors" class="error">{{errors}}</p>
  </div>
</template>

<script>
import api from '../store/api';
export default {
    created() {
        const apiKey = this.$router.currentRoute.params.apiKey;
        if (apiKey) {
            this.loadIndex(apiKey);
            return;
        }
        this.loadState();
    },
    methods: {
        loadIndex(/*apiKey*/) {
            /* this.$store.commit('setApiKey', apiKey);
            this.$store.dispatch("LOAD_STATIC_DATA");
            this.$router.push("/"); */
            this.errors = "Testovací frontendy GSERVIS a WIENERBERGER již nejsou dále podporováný.";
        },
        loadState() {
            const hash = this.$router.currentRoute.params.hash;
            const owner = this.$router.currentRoute.params.owner;
            this.$store.commit("setOwner", owner);
            console.log('route', hash);
            api.post('/api/load-saved-state', {hash}).then((data) => {
                this.$store.dispatch("LOAD_SAVED_STATE", data);
                this.$router.push("/household");
            })
            .catch(error => {
                this.errors = error;
            });
        },
    },
    data() {
        return {
            errors: null
        };
    }
}
</script>

<style lang="scss" scoped>
.load {
    margin: 30px;
    p.error {
        color: red;
        font-weight: bold;
    }
}
</style>