import { createRouter, createWebHistory } from 'vue-router'
import HouseholdTab from "../views/configuration/HouseholdTab.vue";
import ArchitectureTab from "../views/configuration/ArchitectureTab.vue";
import ConstructionTab from "../views/configuration/ConstructionTab.vue";
import HVACTab from "../views/configuration/HVACTab.vue";
import SurroundingsTab from "../views/configuration/SurroundingsTab.vue";
import LocationTab from "../views/configuration/LocationTab.vue";
import CardManagement from "../views/admin/CardManagement.vue";
import MainSettings from "../views/admin/MainSettings.vue";
import TextManagement from "../views/admin/TextManagement.vue";
import LoadEntrance from "../views/LoadEntrance.vue";
import BackupManagement from "../views/admin/maintenance/BackupManagement.vue";
import ManufacturerManagement from "../views/admin/ManufacturerManagement.vue";
import CloneConfigurator from "../views/admin/CloneConfigurator.vue";

const routes = [
  {
    path: "/",
    redirect: "/household",
  },
  {
    path: "/cards",
    name: "cards",
    component: CardManagement /* admin is loaded in App.vue */,
  },
  {
    path: '/backups',
    name: 'backups',
    component: BackupManagement
  },
  {
    path: "/settings",
    name: "settings",
    component: MainSettings,
  },
  {
    path: "/texts",
    name: "texts",
    component: TextManagement,
  },
  {
    path: "/clone",
    name: "clone",
    component: CloneConfigurator,
  },
  {
    path: "/household",
    name: "Household",
    component: HouseholdTab,
  },
  {
    path: "/architecture",
    name: "Architecture",
    component: ArchitectureTab,
  },
  {
    path: "/construction",
    name: "Construction",
    component: ConstructionTab,
  },
  {
    path: "/hvac",
    name: "HVAC",
    component: HVACTab,
  },
  {
    path: "/surroundings",
    name: "Surroundings",
    component: SurroundingsTab,
  },
  {
    path: "/location",
    name: "Location",
    component: LocationTab,
  },
  {
    path: "/:owner/load/:hash",
    name: "Load",
    component: LoadEntrance,
  },
  {
    path: "/:apiKey/load",
    name: "LoadVisitor",
    component: LoadEntrance,
  },
  {
    path: "/manufacturers",
    name: "/manufacturers",
    component: ManufacturerManagement,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
