const { updateWhatever } = require("../utils");
const featureFlags = {
  namespaced: true,
  state: () => ({
    hasPricing: true,
    hasEnergetics: false,
    hasNewFence: false,
    hasChargingStations: false,
    hasWastewaterHeatRecoveryType: false,
  }),
  getters: {
    getFeatureFlag: state => flag => state[flag],
    hasPricing: state => state.hasPricing,
    hasEnergetics: state => state.hasEnergetics,
    hasNewFence: state => state.hasNewFence,
    hasChargingStations: state => state.hasChargingStations,
    hasWastewaterHeatRecoveryType: state => state.hasWastewaterHeatRecoveryType,
  },
  mutations: {
    set(state, newState) {
      state = updateWhatever(state, newState);
    },
  },
  actions: {},
};

export default featureFlags;
