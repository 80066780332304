<template>
  <div class="result-panel">
    <PricePreview v-if="showPreview" />
    <BriefPreview v-else />
    <div class="result-nav flex-row">
      <div
        @click="activate(tabs.ROOMS)"
        :class="{'result-nav-item': true, active: isActive(tabs.ROOMS)}"
        v-text="getText('result.tab.rooms')"
      ></div>
      <div
        @click="activate(tabs.COMPUTATIONS)"
        :class="{'result-nav-item': true, active: isActive(tabs.COMPUTATIONS)}"
        v-text="getText('result.tab.computations')"
      ></div>
      <div
        @click="activate(tabs.VISUALIZATION)"
        :class="{'result-nav-item': true, active: isActive(tabs.VISUALIZATION)}"
        v-text="getText('result.tab.visualization')"
      ></div>
      <!-- <div
        @click="activate(tabs.DISPOSITION)"
        :class="{'result-nav-item': true, active: isActive(tabs.DISPOSITION)}"
      >Dispozice</div>
      <div
        @click="activate(tabs.VIEW3D)"
        :class="{'result-nav-item': true, active: isActive(tabs.VIEW3D)}"
      >3D náhled</div> -->
    </div>

    <div class="result-content">
      <RoomList v-if="isActive(tabs.ROOMS)" />
      <ComputationTiles v-else-if="isActive(tabs.COMPUTATIONS)" />
      <Visualization3D v-else-if="isActive(tabs.VISUALIZATION)" />
      <!-- <Disposition v-else-if="isActive(tabs.DISPOSITION)" />
      <View3D v-else-if="isActive(tabs.VIEW3D)" /> -->
    </div>
  </div>
</template>

<script>
import PricePreview from "./PricePreview";
import BriefPreview from "./BriefPreview";
// subresutls
import RoomList from "./subresult/RoomList";
import ComputationTiles from "./subresult/ComputationTiles";
import Visualization3D from "./subresult/Visualization3D.vue";
import { mapGetters, mapMutations } from 'vuex';
/* import Disposition from "./subresult/Disposition";
import View3D from "./subresult/View3D"; */
export default {
  name: "ResultPanel",
  components: {
    PricePreview,
    BriefPreview,
    RoomList,
    ComputationTiles,
    Visualization3D,
  },
  data() {
    return {
      //currentTab: "rooms",
      tabs: {
        ROOMS: "rooms",
        COMPUTATIONS: "computations",
        VISUALIZATION: "visualization",
/*      DISPOSITION: "disposition",
        VIEW3D: "view3d",*/
      },
    };
  },
  methods: {
    ...mapMutations('result', ['setCurrentTab']),
    activate(tab) {
      this.setCurrentTab(tab);
    },
    isActive(tab) {
      return this.currentTab === tab;
    },
  },
  computed: {
    ...mapGetters('result', ['currentTab']),
    ...mapGetters(['getText']),
    showPreview() {
      return [this.tabs.ROOMS, this.tabs.COMPUTATIONS].includes(
        this.currentTab
      );
    },
  },

};
</script>

<style lang="scss">
.result-panel {
  padding: 10px;
  .result-nav {
    justify-content: space-around;
    border-bottom: 1px solid #0000001f;
    .result-nav-item {
      font: 500 16px/21px Roboto;
      letter-spacing: 1.43px;
      color: #707070;
      padding: 4px;
      border-radius: 3px;
      &:hover {
        background: rgba($color: $primary-color, $alpha: 0.5);
        color: white;
      }
      &.active {
        color: $primary-color;
        border-bottom: solid 2px $primary-color;
        border-radius: 0;
      }
    }
  }
}
</style>
