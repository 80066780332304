<template>
  <div class="product-update info">
    <h2>Aktualizovat produkty ze stavební knihovny pro Wienerberger a Gservis</h2> 
    <p><strong>info</strong> fukce nemá vliv na produkty zadané ručně.</p>
    <button
        v-if="cardUpdateProgress.status !== staticData.ProgressStatus.INPROGRESS"
        @click="updateAllProducts()"
        class="button"
    >
    aktualzovat
    </button>
    <h3 v-else> Probíhá aktualizace</h3>
    
    <MyDialog 
      heading="Aktualizace produktů"
      :show="showDialog" 
      @input="showDialog = false" 
    >
      <h3 
      v-show="cardUpdateProgress.status === staticData.ProgressStatus.INPROGRESS"
      > Probíhá aktualizace</h3>
      <h3 
      v-show="cardUpdateProgress.status === staticData.ProgressStatus.FAILED"
      > Aktualizace se nezdařila</h3>
      <p>
          Aktualizováno {{cardUpdateProgress.updated}} / {{cardUpdateProgress.goal}}
      </p>
      <div v-if="cardUpdateProgress.problematic > 0">
        <h3>Problémy:</h3>
        <p> Na {{cardUpdateProgress.problematic}} karách nalezen problém.</p>
        <div class="update-changes flex-column">
          <span v-for="(error, idx) in cardUpdateProgress.errors" :key="idx">
            <span class="bold" v-text="error.type"></span>;
            <span>jméno karty</span> <span class="bold" v-text="error.cardName"></span>;
            <span>jméno produktu</span> <span class="bold" v-text="error.cardName"></span>;
            <span>deksoftId</span> <span class="bold" v-text="error.deksoftId"></span>
          </span>
        </div>  
      </div>
      <p>Právě probíhá <i v-text="cardUpdateProgress.current"></i></p>
      <h2>Nalezené změny</h2>
      <div class="update-changes flex-column">
        <span v-for="(change, idx) in cardUpdateProgress.diff" v-text="change" class="change" :key="`change-${idx}`"></span>
      </div>
      <button 
        :disabled="cardUpdateProgress.status === staticData.ProgressStatus.INPROGRESS" 
        @click="showDialog = false" class="primary"
      >OK</button>
    </MyDialog>
  </div>
</template>

<script>
import api from "../../../store/api";
import MyDialog from "../../components/MyDialog";
export default {
  components: { MyDialog },
  data() {
    return {
      showDialog: false,
      cardUpdateProgress: {
        updated: 0,
        goal: 0,
        status: 1,
        current: "",
        diff: []
      },
      staticData: {
        ProgressStatus: {
            READY: 1,
            INPROGRESS: 2,
            FAILED: 3,
        }
      }
    }
  },
  methods: {
    updateAllProducts() {
      const url = '/api/cards/update-all-products';
      api.get(url).then(response => {
        this.cardUpdateProgress = {... response};
        this.updateAllProductsProgress();
        this.showDialog = true;
      })
    },
    updateAllProductsProgress() {
      if (this.cardUpdateProgress.status === this.staticData.ProgressStatus.INPROGRESS) {
        const url = '/api/cards/update-all-products-request-progress';
        setTimeout(() => {
          api.get(url).then(response => {
            this.cardUpdateProgress = {... response};
            this.updateAllProductsProgress();
          }).catch(err => {
            this.cardUpdateProgress.status = this.staticData.ProgressStatus.FAILED;
            this.cardUpdateProgress.current = '' + err;
            //this.updateAllProductsProgress();
          });
        }, 333);
      }
    },
  }
}
</script>

<style lang="scss" scope>
  .info,
  .warn {
    background-color: rgb(255, 225, 170);
    padding: 25px;
    border-radius: 8px;
    strong {
      text-transform: capitalize;
    }
    margin: 10px;
  }
  .info {
    background-color: rgb(200, 255, 225);
  }
  .update-changes {
    background-color: rgb(107, 110, 100);
    color: white;
    span.change {
      padding: 3px 10px;
      border: 1px solid black;
      border-bottom-width: 0;
      &:last-child {
        border-bottom-width: 1px;
      }
    }
    .bold {
      font-weight: 700;
    }
  }
  button {
    margin: 10px 0;
  }
</style>