<template>
  <div class="location">
    <h1 class="title" v-text="getText('location.heading')"></h1>
    
    <p class="description" v-text="getText('editorial.location')"></p>

    <MySection :name="getText('region.question')" :information="getText('region.info')">
      <select :value="region" @input="setRegion(parseFloat($event.target.value))">
        <option v-for="region in getRegions" :key="region.id" v-text="region.name" :value="region.id"></option>
      </select>
    </MySection>
    
    <MySection name="Katastrální území parcely" v-if="'disable now' == false" >
      <input
          type="text"
          :value="cadastralCommunity"
          @input="setCadastralCommunity($event.target.value)"
          class="land-location"
        />
    </MySection>
    
    <MySection 
      :name="getText('mainEntranceOrientation.question')" 
      :information="getText('info.location.mainEntranceOrientation')"
    >
      <MultipleChoice
          single-answer
          :options="getSchemaOptions('location.mainEntranceOrientation')"
          :modelValue="mainEntranceOrientation"
          @update:modelValue="setMainEntranceOrientation"
        />
      <WorldSidePicker
        :value="mainEntranceOrientation"
        @input="setMainEntranceOrientation" />
    </MySection>
    <AdvancedQuestionsToggleButton v-model="advancedMode" />

    <!-- advanced-->
    <div v-show="advancedMode">
      <MySection :name="getText('landType.question')" :information="getText('landType.info')">
        <MultipleChoice
            single-answer
            :options="getSchemaOptions('location.landType')"
            :modelValue="landType"
            @update:modelValue="setLandType"
          />
      </MySection>
      
      <MySection :name="getText('landProfile.question')" :information="getText('landProfile.info')">
         <MultipleChoice
            single-answer
            :options="getSchemaOptions('location.landProfile')"
            :modelValue="landProfile"
            @update:modelValue="setLandProfile"
          />
      </MySection>

      <h1 class="title" v-text="getText('connections.question')"></h1>
      <div class="info-connections">
        <i class="" v-text="getText('connections.info')"></i>
      </div>

      <MySection 
        v-for="flag in staticData.location.flags.filter(
          (flag) => flag.advanced
        )"
        :name="getText(`${flag.variableName}.question`)"
        :information="getText(`${flag.variableName}.info`)"
        class="section flag"
        :key="flag.name"
      >
        <toggle-button
          :value="flags[flag.variableName]"
          @input="setFlag({ variableName: flag.variableName, val: $event })"
        />
        
      </MySection>
      
    </div>
    <!--advanced ends-->

    <div class="section-controls">
      <button @click="$router.push('/surroundings')" class="secondary transparent" v-text="getText('previousButton')"></button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";
import {
  MultipleChoice,
  ToggleButton,
  WorldSidePicker,
  AdvancedQuestionsToggleButton,
} from "../components/customElements";
import MySection from '../components/MySection.vue';

export default {
  name: "LocationTab",
  components: {
    MultipleChoice,
    ToggleButton,
    WorldSidePicker,
    AdvancedQuestionsToggleButton,
    MySection,
  },
  data() {
    return {
      staticData: {
        location: {
          landTypeOptions: [
            {
              name: "Nově budovaná lokalita",
              image: require("@/assets/image-holder.png"),
              value: "new building-location",
            },
            {
              name: "Parcela ve staré zástavbě",
              image: require("@/assets/image-holder.png"),
              value: "existing building-location",
            },
            {
              name: "Proluka v řadové zástavbě",
              image: require("@/assets/image-holder.png"),
              value: "gap in a terraced house",
            },
          ],
          landProfileOptions: [
            {
              name: "Rovina",
              image: require("@/assets/image-holder.png"),
              value: "flat",
            },
            {
              name: "Svah",
              image: require("@/assets/image-holder.png"),
              value: "slope",
            },
          ],
          flags: [
            {
              name: "Vodovod",
              variableName: "waterConnection",
              advanced: true,
            },
            {
              name: "Kanalizace",
              variableName: "sewerageConnection",
              advanced: true,
            },
            {
              name: "Plynovod",
              variableName: "naturalGasConnection",
              advanced: true,
            },
            {
              name: "Elektřina",
              variableName: "electricityConnection",
              advanced: true,
            },
            {
              name: "Dešťová kanalizace",
              variableName: "rainwaterSewerConnection",
              advanced: true,
            },
          ],
        },
      },
    };
  },
  computed: {
    advancedMode: {
      get() {
        return this.$store.getters.advanced("household");
      },
      set(v) {
        this.$store.commit("advanced", { value: v, tab: "household" });
      },
    },
    ...mapGetters(["getText", 'getSchema', 'getSchemaOptions', "getRegions"]),
    ...mapGetters("configuration/location", [
      "cadastralCommunity",
      "flags",
      "landType",
      "landProfile",
      "mainEntranceOrientation",
      "region",
    ]),
  },
  methods: mapMutations("configuration/location", [
    "setCadastralCommunity",
    "setFlag",
    "setLandType",
    "setLandProfile",
    "setMainEntranceOrientation",
    "setRegion",
  ]),
};
</script>

<style lang="scss" scoped>
.info-connections {
  padding: 16px;
  background: white;
  border-color: rgba(38, 130, 101, 0.4);;
  //border-color: #dadada;
  //border-radius: 4px;
  border-width: 2px;
  border-top-width: 0;
  border-bottom-width: 0;
  border-right-width: 0px;
  border-left-width: 6px;
  border-style: solid;
  i {
    //font-size: 1.1em;
    font: 400 16px/23px roboto;
    letter-spacing: 0.13px;
    color: black;
  }
}
</style>
