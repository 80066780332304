<template>
  <div class="card-dialog">
    <MyDialog :heading="makeHeading" :show="true" v-if="show" @input="onInput">
      <p class="text" v-text="getText('editorial.card')">
      </p>

      <div class="column-names flex-row">
        <span style="flex: 1 1 20%" v-text="getText('cards.subcategory')"></span>
        <div class="flex-row product-selection">
          <span class="product-sel-item" v-text="getText('cards.product')"></span>
          <span style="flex: 1;" v-text="getText('cards.description')"></span>
          <span v-text="getText('cards.link')"></span>
        </div>
      </div>
      <MySection
        v-for="goods in products"
        :key="goods"
        :name="getText(goods)"
      >
        <div class="flex-row product-selection">
          <select 
            class="product-sel-item"
            :value="getGoods(cardSlot  || card.category)[goods]"
            @change="selectProduct(goods, $event.target.value)"
          >
            <option
              v-for="(product, idx) in getCardProducts(goods)"
              :key="idx"
              :value="product.id"
              v-text="product.name"
            >
            </option>
          </select>
          <p class="brief ellipsis" v-text="getSelectedBrief(goods)"></p>
          <a
            :href="getSelectedProductUrl(goods)"
            :class="{ disabled: getSelectedProductUrl(goods) === '#' }"
            target="_blank"
          >
            <i class="fas fa-external-link-square-alt"></i>
          </a>
        </div>
      </MySection>
      <div class="gab80px"></div>
    </MyDialog>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import MyDialog from "./MyDialog";
import MySection from "./MySection.vue";
import cardStaticData from "../admin/cards/cardStaticData";
export default {
  components: {
    MyDialog,
    MySection,
  },
  props: {
    card: Object,
    cardSlot: String,
  },
  data() {
    return {
      staticData: {
        ...cardStaticData,
      },
      show: false,
    };
  },
  methods: {
    ...mapMutations('configuration/goods', ['setProduct']),
      selectProduct(goods, id) {
      console.log('selectProduct', id);
      this.setProduct({category: this.cardSlot || this.card.category, goods, id});
    },
    onInput() {
      this.show = false;
      //this.$emit("input");
    },
    getCardProducts(goods) {
      if (!this.card.products || !(goods in this.card.products)) return [];
      return this.card.products[goods];
    },
    getSelectedProductUrl(goods) {
      const choiceObj = this.getGoods(this.cardSlot || this.card.category);
      const id = choiceObj[goods];
      const activeProducts = this.card.products[goods].filter(product => product.id == id);
      if (activeProducts.length <= 0) return "#";
      try {
      return activeProducts[0].url;
      } catch(error) {
        console.error(error);
      }
    },
    getSelectedBrief(goods) {
      const choiceObj = this.getGoods(this.cardSlot || this.card.category);
      const id = choiceObj[goods];
      const activeProducts = this.card.products[goods].filter(product => product.id == id);
      if (activeProducts.length <= 0) return "";
      try {
        return activeProducts[0].brief;
      } catch(error) {
        console.error(error);
      }
    },
  },
  computed: {
    ...mapGetters(['getText']),
    ...mapGetters('configuration/goods', ['getGoods']),
    makeHeading() {
      const tab = this.card.superCategory;
      if (!tab) return "";
      const category = this.card.category;
      console.log("kard dategory " + this.card.category);
      return `${this.getText('cardSettings')} ${this.getText(category)}`;
    },
    products() {
      const tab = this.card.superCategory;
      if (!tab) return [];
      const category = this.card.category;
      return this.staticData.categoryToProducts[category];
    },
  },
};
</script>

<style lang="scss">
.card-dialog {
  .column-names {
    justify-content: space-between;
  }

  .flex-row.product-selection {
    flex: 8;
    .product-sel-item {
      flex: 1 0 400px;
    }
    .brief {

      width: 400px;
      //height: 60px;
      overflow-y: auto;
      margin: 0 15px;
    }

    a {
      flex: 0 0 0%;
    }
  }
  
  a {
    &.disabled {
      color: currentColor;
      display: inline-block; /* For IE11/ MS Edge bug */
      pointer-events: none;
      text-decoration: none;
      & > i {
        color: lighten($secondary-color, 30%);
      }
    }
    & > i {
      font-size: 25px;
      color: $secondary-color;
      cursor: pointer;
    }
  }
  .gab80px {
    min-height: 80px;
  }
}
</style>
