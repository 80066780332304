<template>
  <div class="prerequisites">
    <div
      v-for="prerequisitesItem in prerequisites"
      :key="prerequisitesItem.category"
      class="list-prerequisites"
    >
      <SelectableIDList :prerequisite="prerequisitesItem" />
    </div>

    <button
      class="button toggle-form"
      @click.prevent="showForm = !showForm"
      :class="{ primary: !showForm, secondary: showForm }"
    >
      <i
        class="plus fa"
        :class="{ 'fa-plus': !showForm, 'fa-minus': showForm }"
      ></i>
    </button>
    <div class="form-add add-new" v-show="showForm">
      <h3 class="title">Přidat kategorii prerekvizit</h3>

      <div class="flex-column">
        <label>Záložka</label>
        <select v-model="newPrerequisites.superCategory">
          <option
            v-for="tab in staticData.tabs"
            :value="tab.value"
            :key="tab.value"
            v-text="tab.name"
          ></option>
        </select>
        <span
          class="error"
          v-show="hasError('superCategory')"
          v-text="getError('superCategory')"
        ></span>
      </div>

      <div class="flex-column">
        <label>Kategorie</label>
        <select v-model="newPrerequisites.category">
          <option
            v-for="category in getCategories()"
            :value="category.value"
            :key="category.value"
            v-text="category.name"
          ></option>
        </select>
        <span
          class="error"
          v-show="hasError('category')"
          v-text="getError('category')"
        ></span>
      </div>
      <button @click.prevent="onAddCategory" class="button primary">
        přidat kategorii
      </button>
    </div>
  </div>
</template>

<script>
import SelectableIDList from "./SelectableIDList";
import cardStaticData from "./cardStaticData";
export default {
  components: {
    SelectableIDList
  },
  props: {
    prerequisites: { type: Array },
  },
  methods: {
    onAddCategory() {
      if (
        this.prerequisites.filter(
          (prereq) =>
            prereq.superCategory === this.newPrerequisites.superCategory &&
            prereq.categories === this.newPrerequisites.category
        ) > 0
      ) {
        return;
      }
      const { superCategory, category, list } = this.newPrerequisites;
      this.prerequisites.push({
        superCategory,
        category,
        list,
      });
      this.showForm = false;
    },
    getCategories() {
      return this.staticData.categories[this.newPrerequisites.superCategory];
    },
    hasError(param) {
      return param in this.errors;
    },
    getError(param) {
      return this.errors[param];
    },
    clearErrors() {
      this.errors = {};
    },
    hasAny() {
      return this.prerequisites.length > 0;
    },
    clear() {
      this.newPrerequisites = {
        superCategory: "",
        category: "",
        list: [],
      };
    },
  },
  data() {
    return {
      newPrerequisites: {
        superCategory: "",
        category: "",
        list: [],
      },
      showForm: false,
      errors: {},
      staticData: {
        ...cardStaticData,
      },
    };
  },
};
</script>

<style lang="scss">
.prerequisites {
  .list-prerequisites {
    
  }
  .view {
    background: white;
    min-height: 100px;
    .product {
      border: 2px solid red;
      .product-info {
        width: 200px;
      }
    }
  }
  button.toggle-form {
    width: 180px;
    .plus {
      font-size: 2em;
    }
  }
  .add-new {
    .how {
      margin: 10px 0;
    }
    .load {
      label {
        //align-self: center;
        //font: 400 14px/14px Roboto;
        margin: 10px 10px 10px 0;
      }
      margin: 10px 0;
    }
    button.button.primary {
      width: 140px;
      height: 2em;
      padding: auto;
      margin-bottom: 15px;
    }
  }
  .toggle-button {
    margin: auto 10px;
  }
  .product-data {
    flex-wrap: wrap;
  }
  .remove {
    position: relative;
    color: $primary-color;
  }
}
</style>