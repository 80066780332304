<template>
  <div>
    <p><strong v-text="text"></strong></p>
    <div class="flex-row flags">
        <div>
            <!-- <strong v-text="getText('energetics.grantNZUChapterBPasivPlus')"></strong> -->
            <i class="nzu-flag far fa-check-square green" v-if="grantNZUPartials.grantSectionB_NewBulding.details.conditionsPasivPlus[parameter]"></i>
            <i class="nzu-flag far fa-square red" v-else></i>
        </div>
    </div>   
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        text: {
            type: String,
            required: true,
        },
        parameter: {
            type: String,
            required: true,
        },
        simplified: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        ...mapGetters("result", [
            "grantNZUPartials",
        ]),
        ...mapGetters(["getText"]),
    }
}
</script>

<style lang="scss">
.flags {
    width: 100%;
    justify-content: center;
    align-content: center;
    i.nzu-flag {
        padding: 0 5px;
        &.center {
            margin: 0;
            align-self: center;
            text-align: center;
        }
    }
    i.nzu-flag.red {
        color: black;
    }
    i.nzu-flag.green {
        color: green;
    }
}
</style>