const { updateWhatever } = require("../utils");
const surroundings = {
  namespaced: true,
  state: () => ({
    wienerbergerClass: "",
    swimmingPool: {
      hasSwimmingPool: false,
      width: 4,
      length: 7,
      constructionType: "laminate",
      hasRoof: false,
    },
    fence: {
      hasFence: false,
      // old fence
      front: 20,
      side1: 35,
      side2: 35,
      rear: 20,
      frontConstructionType: "combined wall fence",
      sideConstructionType: "wood fence pickets",
      rearConstructionType: "wood fence pickets",
      frontFillingType: "wood",
      sideFillingType: "wood",
      rearFillingType: "wood",
      // new fence
      hasNewFence: false,
      street: 20,
      other: 90,
      streetConstructionType: "combined wall fence",
      otherConstructionType: "wood fence pickets",
      streetFillingType: "wood",
      otherFillingType: "wood",
      gateOpener: "automatic",
    },
    pavedSurface: {
      hasPavedSurface: false,
      area: 130,
      material: "concrete paving",
      concretePavingType: "interlocking concrete",
    },
    terrace: {
      hasTerrace: false,
      area: 130,
      material: "concrete paving",
      concreteType: "interlocking concrete",
    },
    playground: {
      hasPlayground: false,
      material: "wood",
      maxNumberOfChildren: "",
    },
    gardenHouse: {
      hasGardenHouse: false,
      material: "wood",
      size: "small",
    },
    pergola: {
      hasPergola: false,
      material: "wood",
      width: 3,
      length: 2.5,
    },
  }),
  getters: {
    wienerbergerClass: state => state.wienerbergerClass,
    swimmingPoolWidth: (state) => state.swimmingPool.width,
    swimmingPoolLength: (state) => state.swimmingPool.length,
    swimmingPoolConstructionType: (state) =>
      state.swimmingPool.constructionType,
    swimmingPoolHasRoof: (state) => state.swimmingPool.hasRoof,
    fenceFront: (state) => state.fence.front,
    fenceSide1: (state) => state.fence.side1,
    fenceSide2: (state) => state.fence.side2,
    fenceRear: (state) => state.fence.rear,
    fenceFrontConstructionType: (state) => state.fence.frontConstructionType,
    fenceSideConstructionType: (state) => state.fence.sideConstructionType,
    fenceRearConstructionType: (state) => state.fence.rearConstructionType,
    fenceFrontFillingType: (state) => state.fence.frontFillingType,
    fenceSideFillingType: (state) => state.fence.sideFillingType,
    fenceRearFillingType: (state) => state.fence.rearFillingType,
    // new fence
    hasNewFence: (state) => state.fence.hasNewFence,
    fenceStreet: (state) => state.fence.street,
    fenceOther: (state) => state.fence.other,
    fenceStreetConstructionType: (state) => state.fence.streetConstructionType,
    fenceOtherConstructionType: (state) => state.fence.otherConstructionType,
    fenceStreetFillingType: (state) => state.fence.streetFillingType,
    fenceOtherFillingType: (state) => state.fence.otherFillingType,
    gateOpener: (state) => state.fence.gateOpener,
    pavedSurfaceSize: (state) => state.pavedSurface.area,
    pavedSurfaceMaterial: (state) => state.pavedSurface.material,
    pavedSurfaceConcretePavingType: (state) =>
      state.pavedSurface.concretePavingType,
    hasTerrace: (state) => state.terrace.hasTerrace,
    terraceArea: (state) => state.terrace.area,
    terraceMaterial: (state) => state.terrace.material,
    terraceConcreteType: (state) => state.terrace.concreteType,
    playgroundMaterial: (state) => state.playground.material,
    playgroundMaxNumberOfChildren: (state) =>
      state.playground.maxNumberOfChildren,
    gardenHouseMaterial: (state) => state.gardenHouse.material,
    gardenHouseSize: (state) => state.gardenHouse.size,
    pergolaMaterial: (state) => state.pergola.material,
    pergolaWidth: (state) => state.pergola.width,
    pergolaLength: (state) => state.pergola.length,
    hasSwimmingPool: (state) => state.swimmingPool.hasSwimmingPool,
    hasFence: (state) => state.fence.hasFence,
    hasPavedSurface: (state) => state.pavedSurface.hasPavedSurface,
    hasPlayground: (state) => state.playground.hasPlayground,
    hasGardenHouse: (state) => state.gardenHouse.hasGardenHouse,
    hasPergola: (state) => state.pergola.hasPergola,
  },
  mutations: {
    set(state, newState) {
      state = updateWhatever(state, newState);
    },
    setWienerbergerClass(state, val) {
      state.wienerbergerClass = val;
    },
    setSwimmingPoolWidth(state, val) {
      state.swimmingPool.width = val;
    },
    setSwimmingPoolLength(state, val) {
      state.swimmingPool.length = val;
    },
    setSwimmingPoolConstructionType(state, val) {
      state.swimmingPool.constructionType = val;
    },
    setSwimmingPoolHasRoof(state, val) {
      state.swimmingPool.hasRoof = val;
    },
    setFenceFront(state, val) {
      state.fence.front = val;
    },
    setFenceSide1(state, val) {
      state.fence.side1 = val;
    },
    setFenceSide2(state, val) {
      state.fence.side2 = val;
    },
    setFenceRear(state, val) {
      state.fence.rear = val;
    },
    setFenceFrontConstructionType(state, val) {
      state.fence.frontConstructionType = val;
    },
    setFenceSideConstructionType(state, val) {
      state.fence.sideConstructionType = val;
    },
    setFenceRearConstructionType(state, val) {
      state.fence.rearConstructionType = val;
    },
    setFenceFrontFillingType(state, val) {
      state.fence.frontFillingType = val;
    },
    setFenceSideFillingType(state, val) {
      state.fence.sideFillingType = val;
    },
    setFenceRearFillingType(state, val) {
      state.fence.rearFillingType = val;
    },
    // new fence
    setHasNewFence(state, val) {
      state.fence.hasNewFence = val;
    },
    setFenceStreet(state, val) {
      state.fence.street = val;
    },
    setFenceOther(state, val) {
      state.fence.other = val;
    },
    setFenceStreetConstructionType(state, val) {
      state.fence.streetConstructionType = val;
    },
    setFenceOtherConstructionType(state, val) {
      state.fence.otherConstructionType = val;
    },
    setFenceStreetFillingType(state, val) {
      state.fence.streetFillingType = val;
    },
    setFenceOtherFillingType(state, val) {
      state.fence.otherFillingType = val;
    },
    setGateOpener(state, val) {
      state.fence.gateOpener = val;
    },
    setPavedSurfaceSize(state, val) {
      state.pavedSurface.area = val;
    },
    setPavedSurfaceMaterial(state, val) {
      state.pavedSurface.material = val;
    },
    setPavedSurfaceConcretePavingType(state, val) {
      state.pavedSurface.concretePavingType = val;
    },
    setHasTerrace(state, val) {
      state.terrace.hasTerrace = val;
    },
    setTerraceArea(state, val) {
      state.terrace.area = val;
    },
    setTerraceMaterial(state, val) {
      state.terrace.material = val;
    },
    setTerraceConcreteType(state, val) {
      state.terrace.concreteType = val;
    },
    setPlaygroundMaterial(state, val) {
      state.playground.material = val;
    },
    setPlaygroundMaxNumberOfChildren(state, val) {
      state.playground.maxNumberOfChildren = val;
    },
    setGardenHouseMaterial(state, val) {
      state.gardenHouse.material = val;
    },
    setGardenHouseSize(state, val) {
      state.gardenHouse.size = val;
    },
    setPergolaMaterial(state, val) {
      state.pergola.material = val;
    },
    setPergolaWidth(state, val) {
      state.pergola.width = val;
    },
    setPergolaLength(state, val) {
      state.pergola.length = val;
    },
    setHasSwimmingPool(state, val) {
      state.swimmingPool.hasSwimmingPool = val;
    },
    setHasFence(state, val) {
      state.fence.hasFence = val;
    },
    setHasPavedSurface(state, val) {
      state.pavedSurface.hasPavedSurface = val;
    },
    setHasPlayground(state, val) {
      state.playground.hasPlayground = val;
    },
    setHasGardenHouse(state, val) {
      state.gardenHouse.hasGardenHouse = val;
    },
    setHasPergola(state, val) {
      state.pergola.hasPergola = val;
    },
  },
  actions: {},
};

export default surroundings;
