<template>
    <div class="json-detail">
        <button @click="onInput()">Detaily z výpočtu</button>
        <MyDialog :show="show" @input="onInput()" >
            <h1>Detaily z výpočtu</h1>
            <JsonViewer 
                :value="data" 
                :expand-depth=1
                copyable
                boxed
            />
        </MyDialog>
    </div>
</template>

<script>
import MyDialog from '../../components/MyDialog.vue';
import JsonViewer from 'vue-json-viewer/ssr'
import 'vue-json-viewer/style.css'


export default {
  components: { MyDialog, JsonViewer },
    props:{
        show: Boolean,
        data: {type: Object},
    },
    methods: {
        onInput() {
            this.$emit('input', !this.show);
        },
    }
}
</script>

<style>

</style>