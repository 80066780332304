<template>
  <div class="admin">
    <div class="projects">
      <label for="owner">Vyberte konfigurator:</label>
      <select
        id="owner"
        name="owner"
        :value="configuratorOwner"
        @change="onChange"
      >
        <option v-for="owner in owners" :value="owner" v-text="owner" :key="owner"></option>
      </select>
      <h1 class="title">API pro {{ configuratorOwner.toUpperCase() }}</h1>
      <p>x-api-key: {{ xApiKey }}</p>
      <p>conf. family: {{configuratorFamily}}</p>

      <h1 class="title">DATA Z TABULEK</h1>
      <div class="flex-row">
        <div class="info">
          <h2>Obnovit data podle TABULEK</h2> 
          <p>Data mají vliv na velikosti místností, české názvy místností a nějaké konstantní parametry jako třeba výšpa patra.</p>
          <button
              @click="go('/api/renew-cache', $event)"
              class="button"
          >
            provést
          </button>
        </div>
      </div>

      <h1 class="title">KARTY</h1>
      <div class="data flex-row">
        <ProductsUpdate />
      </div>

      <div class="data flex-row" v-if="false">
        <div class="warn">
          <h2>Načíst tesovací karty pro Wienerberger a Gservis</h2> 
          <p><strong>varování</strong> stisknutí smažete aktuální karty a nahradíte je testovacími.</p>
          <button
              @click="go('/api/cards/load-dataset', $event)"
              class="button"
          >
            provést
          </button>
        </div>
      </div>

      <h1 class="title">VYMAZAT DATA</h1>
      <div class="data flex-row">
        <div class="warn">
          <div class="flex-column">
            <select 
              :value="deleteDataPayload.collection" 
              @change="deleteDataPayload.collection = $event.target.value"
            >
              <option value="texts">texty</option>
              <option value="cards">karty</option>
              <option value="records">uložené konfigurace</option>
              <option value="houses">domy</option>
            </select>

            <select 
              v-if="deleteDataPayload.collection == 'cards'"
              :value="deleteDataPayload.filter.category" 
              @change="deleteDataPayload.filter.category = $event.target.value"
            >
              <option v-for="cat in getCardCategories" :value="cat.value" v-text="cat.name" :key="cat.value"></option>
            </select>
          </div>
          <button
              @click="deleteData($event)"
              class="button"
              :disabled="loadingContent"
          >
            provést
          </button>
        </div>
      </div>


      <FeatureFlags />

      <h1 class="title">DOMY</h1>
      <div class="data flex-row">
        <div>
          <div class="flex-column">
            <h3>GSERVIS</h3>
            <button
              @click="go('/api/clear-houses-gservis', $event)"
              class="button"
            >
              smazat projekty
            </button>
            <button
              @click="go('/api/fetch-houses-gservis', $event)"
              class="button"
            >
              synchronizovat projekty
            </button>
            <h4>seznam projektů</h4>
            <div
              v-for="(proj, index) in houses.gservis"
              :key="proj.slug"
              class="project"
            >
              {{ index + 1 }}. {{ proj.slug }}
            </div>
          </div>
        </div>

        <div>
          <div class="flex-column">
            <h3>WIENERBERGER</h3>
            <button
              @click="go('/api/clear-houses-wienerberger', $event)"
              class="button"
            >
              smazat projekty
            </button>
            <button
              @click="go('/api/fetch-houses-wienerberger', $event)"
              class="button"
            >
              synchronizovat projekty
            </button>

            <h4>seznam projektů</h4>
            <div
              v-for="(proj, index) in houses.wienerberger"
              :key="proj.slug"
              class="project"
            >
              {{ index + 1 }}. {{ proj.slug }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import api from "../../store/api";
import ProductsUpdate from "./settings/ProductsUpdate";
import FeatureFlags from "./settings/FeatureFlags";
import cardStaticData from './cards/cardStaticData';
export default {
  components: {ProductsUpdate, FeatureFlags},
  data() {
    return {
      houses: {
        gservis: [],
        wienerberger: [],
      },
      deleteDataPayload: {
        collection: 'cards',
        filter: {
          category: '',
        },
      },
    };
  },
  mounted() {
    this.loadProjects("gservis");
    this.loadProjects("wienerberger");
  },
  computed: {
    ...mapGetters([
      "xApiKey",
      "configuratorOwner",
      "owners",
      "configuratorFamily",
      "loadingContent",
    ]),
    getCardCategories() {
      const categories = [{name: "všechny kategorie", value: ""}];
      Object.values(cardStaticData.categories).forEach(item => {
        categories.push(...item);
      });
      return categories;
    }
  },
  methods: {
    ...mapMutations(["setOwner"]),
    onChange(event) {
      this.setOwner(event.target.value);
      this.$store.dispatch("LOAD_STATIC_DATA");
    },
    async deleteData() {
      const payload = {
        ... this.deleteDataPayload
      }
      payload.filter = {};
      if (payload.collection == 'cards' && this.deleteDataPayload.filter.category != "") {
        payload.filter.category = this.deleteDataPayload.filter.category;
      }
      let message = `Opravdu si přejete v konfigurátoru ${
        this.configuratorOwner.toUpperCase()
        } vymazat všechny '${payload.collection.toUpperCase()}'`
      if (Object.keys(payload.filter).length > 0) {
        message +=`' s vlastnostmi ${JSON.stringify(payload.filter)}?'`
      } else message += `?`;
      if (confirm(message)) {
        const res = await this.$store.dispatch('DELETE_DATA', payload);
        console.log('deleteData', res);
      }
    },
    go(url, event) {
      event.target.disabled = true;
      api.get(url).then((response) => {
        event.target.disabled = false;
        console.log(response);
        this.loadProjects("gservis");
        this.loadProjects("wienerberger");
      });
    },
    loadProjects(owner) {
      api.get(`/api/list-houses-${owner}`).then((response) => {
        this.houses[owner] = response;
      });
    },
  },
};
</script>

<style lang="scss">
.admin > .projects {
  .button {
    font-size: 1.2em;
    background-color: $primary-color;
    color: white;
    padding: 3px;
    margin: 2px;
    border: none;
    border-radius: 4px;
    &:hover {
      background-color: lighten($primary-color, 30%);
      color: black;
    }
    &:disabled {
      background-color: lighten($primary-color, 30%);
      color: white;
    }
  }
  h1 {
    font: 500 20px/26px Roboto;
  }
  .load {
    p {
      font: 500 14px/18px Roboto;
      margin: 10px;
    }
    input,
    button {
      height: 25px;
      margin: auto 0;
    }
  }
  h2 {
    font: 500 16px/20px Roboto;
  }
  .data {
    justify-content: space-around;
  }

  textarea.custom,
  input.custom {
    color: black;
  }
  textarea:not(.custom),
  input:not(.custom) {
    color: blue;
  }

  button.primary {
    &:disabled {
      background: $secondary-color;
    }
  }
  
  .info,
  .warn {
    width: 100%;
    background-color: rgb(255, 225, 170);
    padding: 25px;
    border-radius: 8px;
    strong {
      text-transform: capitalize;
    }
    margin: 10px;
  }
  .info {
    background-color: rgb(200, 255, 225);
  }
  .container {
    padding: 10px 0;
    img {
      width: 290px;
      height: 220px;
    }
    .card {
      position: relative;
      margin-right: 6px;
      .buttons {
        color: lighten($primary-color, 20%);
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 99;
        i {
          padding: 3px;
          font-size: 1.4em;
          cursor: pointer;
          &:hover {
            color: $primary-color;
          }
        }
      }
      .textual-content {
        padding: 10px;
      }
      border: solid 2px lighten($primary-color, 40%);
    }
  }
}
</style>
