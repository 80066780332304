import api from "../api";

const cards = {
  namespaced: true,
  state: () => ({
    cards: [],
    // construction
    facadeTemplate: [],
    flatRoofCoveringTemplate: [],
    horizontalConstructionTemplate: [],
    roofWindowTemplate: [],
    slopingRoofCoveringTemplate: [],
    slopingRoofSystemTemplate: [],
    verticalConstructionTemplate: [],
    windowTemplate: [],
    windowShadingTemplate: [],
    // hvac
    heatingSystemTemplate: [],
    chimneyTemplate: [],
    ventilationSystemTemplate: [],
    coolingSystemTemplate: [],
    photovoltaicSystemTemplate: [],
    wastewaterHeatRecoveryTemplate: [],
    chargingStationTemplate: [],
    // surroundings
    swimmingPoolPoolTemplate: [],
    fenceFrontTemplate: [],
    fenceSideTemplate: [],
    fenceRearTemplate: [],
    fenceStreetTemplate: [],
    fenceOtherTemplate: [],
    pavedSurfacePavingTemplate: [],
    terraceTemplate: [],
    playgroundPlaygroundTemplate: [],
    gardenHouseGardenHouseTemplate: [],
    pergolaTemplate: [],
  }),
  getters: {
    cards: (state) => state.cards,
    cardsFor: (state) => (category) => state[category],
  },
  mutations: {
    setAllCards(state, val) {
    state.cards = val;
    },
    setCards(state, cards) {
    Object.keys(cards).forEach((category) => {
        state[category] = cards[category];
    });
    },
  },
  actions: {
    loadAllCards(context) {
      api.get("/api/cards/all").then((response) => {
          context.commit("setAllCards", response);
      });
    },
  },
};

export default cards;