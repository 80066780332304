const filterOptions = {
  wienerbergerClassOptions: [
    {
      name: "Affordable",
      value: "affordable",
    },
    {
      name: "Smart",
      value: "smart",
    },
    {
      name: "Premium",
      value: "premium",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  houseTypeOptions: [
    {
      name: "Bungalov",
      value: "bungalow",
    },
    {
      name: "Patrový",
      value: "multi-storey",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  technicalSolutionOptions: [
    {
      name: "Standardní",
      value: "standard",
    },
    {
      name: "Prémiové",
      value: "premium",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  thermalInsulationLevelOptions: [
    {
      name: "Normální",
      //image: require("@/assets/image-holder.png"),
      value: "standard",
    },
    {
      name: "Zvýšená",
      //image: require("@/assets/image-holder.png"),
      value: "passive house",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  constructionSystemOptions: [
    {
      name: "Zděný",
      //image: require("@/assets/image-holder.png"),
      value: "masonry",
    },
    {
      name: "Dřevostavba",
      //image: require("@/assets/image-holder.png"),
      value: "wooden",
    },
    {
      name: "Monolitický",
      //image: require("@/assets/image-holder.png"),
      value: "other",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  windowMaterialOptions: [
    {
      name: "Dřevo",
      //image: require("@/assets/image-holder.png"),
      value: "wooden",
    },
    {
      name: "Plast",
      //image: require("@/assets/image-holder.png"),
      value: "plastic",
    },
    {
      name: "Hliník",
      //image: require("@/assets/image-holder.png"),
      value: "aluminium",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  windowShadingOptions: [
    {
      name: "Žádné",
      value: "none",
    },
    {
      name: "Žaluzie",
      value: "slatted blinds",
    },
    {
      name: "Rolety",
      value: "shade rolls",
    },
    {
      name: "Slunolamy",
      value: "louvered overhangs",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  roofTypeOptions: [
    {
      name: "Plochá",
      value: "flat",
    },
    {
      name: "Šikmá",
      value: "sloping" /* hip */,
    },
    {
      name: "vše",
      value: "",
    },
  ],
  slopingRoofCoveringTypeOptions: [
    {
      name: "Pálená taška",
      //image: require("@/assets/image-holder.png"),
      value: "clay tiles",
    },
    {
      name: "Betonová taška",
      //image: require("@/assets/image-holder.png"),
      value: "concrete tiles",
    },
    {
      name: "Plechová krytina",
      //image: require("@/assets/image-holder.png"),
      value: "metal roofing",
    },
    {
      name: "Asfaltvý šindel",
      //image: require("@/assets/image-holder.png"),
      value: "asphalt shingles",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  flatRoofCoveringTypeOptions: [
    {
      name: "Hydroizolacni folie",
      //image: require("@/assets/image-holder.png"),
      value: "hydroinsulation foil",
    },
    {
      name: "Asfaltvé pásy",
      //image: require("@/assets/image-holder.png"),
      value: "built-up roofing",
    },
    {
      name: "Zelená střecha",
      //image: require("@/assets/image-holder.png"),
      value: "green roof",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  roofSlopeOptions: [
    {
      name: "Nizký (5° až 25°)",
      //image: require("@/assets/image-holder.png"),
      value: "low",
    },
    {
      name: "Vysoký (25° a více)",
      //image: require("@/assets/image-holder.png"),
      value: "high",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  heatingPrimarySourceOptions: [
    {
      name: "elektřina",
      value: "electricity",
    },
    {
      name: "zemni plyn",
      value: "natural gas",
    },
    {
      name: "dřevo",
      value: "wood",
    },
    {
      name: "uhlí",
      value: "coal",
    },
    {
      name: "ostatní",
      value: "other",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  heatingPrimarySourceTypeOptions: [
    {
      name: "Elektrokotel",
      value: "eletric boiler",
    },
    {
      name: "Tepelné čerpadlo vzduch / voda",
      value: "air source heat pump",
    },
    {
      name: "Tepelné čerpadlo země / voda",
      value: "ground source heat pump",
    },
    {
      name: "Topné rohože",
      value: "heating mats",
    },
    {
      name: "Přímotopy",
      value: "electric heater",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  heatingPrimarySourceTypeOptionsWienerberger: [
    {
      name: "Tepelné čerpadlo vzduch / voda",
      value: "air source heat pump",
    },
    {
      name: "Tepelné čerpadlo země / voda",
      value: "ground source heat pump",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  heatingMainEmitterOptions: [
    {
      name: "Podlahové vytápění",
      value: "floor heating",
    },
    {
      name: "Radiátory",
      value: "radiators",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  heatingMainEmitterOptionsWienerberger: [
    {
      name: "Podlahové vytápění",
      value: "floor heating",
    },
    {
      name: "Radiátory",
      value: "radiators",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  heatingSecondarySourceOptions: [
    {
      name: "žádné",
      value: "none",
    },
    {
      name: "krbová kamna",
      value: "stove",
    },
    {
      name: "krbová vložka",
      value: "fireplace",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  photovoltaicSystemTypeOptions: [
    {
      name: "Přímý ohřev teplé vody",
      value: "direct water heating",
    },
    {
      name: "Využítí elektrické energie v domě bez akumulace",
      value: "electrical without accumulation",
    },
    {
      name: "Využítí elektrické energie v domě s akumulací",
      value: "electrical with accumulation",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  wastewaterHeatRecoveryTypeOptions: [
    {
      name: "Centrální",
      value: "centralized",
    },
    {
      name: "Decentrální",
      value: "decentralized",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  /// surroundings
  swimmingPoolConstructionTypeOptions: [
    {
      name: "Těžká fólie",
      value: "heavy foil",
    },
    {
      name: "Laminát",
      value: "laminate",
    },
    {
      name: "Plast",
      value: "plastic",
    },
    {
      name: "Nerez",
      value: "stainless steel",
    },
    {
      name: "Nazdemní",
      value: "on-ground",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  fenceConstructionTypeOptions: [
    {
      name: "S podezdívkou",
      value: "combined wall fence",
    },
    {
      name: "Plastové plotovky",
      value: "plastic fence pickets",
    },
    {
      name: "Dřevěné plotovky",
      value: "wood fence pickets",
    },
    {
      name: "Betonový plot",
      value: "concrete fence",
    },
    {
      name: "Drátěné pletivo",
      value: "wired mesh",
    },
    {
      name: "Svařované panely",
      value: "welded fence panels",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  fenceFillingTypeOptions: [
    {
      name: "Dřevěná",
      value: "wood",
    },
    {
      name: "Kovová",
      value: "metal",
    },
    {
      name: "Plastová",
      value: "plastic",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  gateOpenerOptions: [
    {
      name:"Bez pohodnu",
      value:"mechanical",
    },
    {
      name:"S pohonem",
      value:"automatic",
    },
    {
      name:"vše",
      value:"",
    },
  ],
  pavedSurfaceMaterialOptions: [
    {
      name: "Kamenný koberec",
      value: "stone carpets",
    },
    {
      name: "Dlažba",
      value: "concrete paving",
    },
    {
      name: "Beton",
      value: "concrete",
    },
    {
      name: "Přírodní kámen",
      value: "natural stone",
    },
    {
      name: "Dřevo",
      value: "wood",
    },
    {
      name: "Dřevoplast",
      value: "wood-plastic"
    },
    {
      name: "vše",
      value: "",
    },
  ],
  pavedSurfaceMaterialOldOptions: [
    {
      name: "Kamenný koberec",
      value: "stone carpets",
    },
    {
      name: "Dlažba",
      value: "concrete paving",
    },
    {
      name: "Přírodní kámen",
      value: "natural stone",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  pavedSurfaceConcretePavingTypeOptions: [
    {
      name: "Zámková dlažba",
      value: "interlocking concrete",
    },
    {
      name: "Zatravňovací dlažba",
      value: "grass pavement",
    },
    {
      name: "Skladebná dlažba",
      value: "composite pavement",
    },
    {
      name: "Velkoformátová dlažba",
      value: "large format paving",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  playgroundMaterialOptions: [
    {
      name: "dřevo",
      value: "wood",
    },
    {
      name: "plast",
      value: "plastic",
    },
    {
      name: "kov",
      value: "metal",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  playgroundMaxNumberOfChildrenOptions: [
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "2",
    },
    {
      name: "3",
      value: "3",
    },
    {
      name: "4",
      value: "4",
    },
    {
      name: "více",
      value: "more",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  gardenHouseMaterialOptions: [
    {
      name: "dřevo",
      value: "wood",
    },
    {
      name: "plast",
      value: "plastic",
    },
    {
      name: "kov",
      value: "metal",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  gardenHouseSizeOptions: [
    {
      name: "malý (do 8 m^2)",
      value: "small",
    },
    {
      name: "střední (do 15 m^2)",
      value: "medium",
    },
    {
      name: "velký (do 25 m^2)",
      value: "large",
    },
    {
      name: "vše",
      value: "",
    },
  ],
  pergolaMaterialOptions: [
    {
      name: "Hliník",
      value: "aluminium",
    },
    {
      name: "Dřevo",
      value: "wood",
    },
    {
      name: "Sluneřní plachta",
      value: "tarpaulin",
    },
    {
      name: "Výsuvná markýza",
      value: "awning",
    },
    {
      name: "vše",
      value: "",
    },
  ],
};

export default filterOptions;
