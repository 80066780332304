import cardStaticData from "../../views/admin/cards/cardStaticData";

const MakeCard = (category) => {
  const activeCard = "";
  const goods = {};
  if (category in cardStaticData.categoryToProducts) {
    cardStaticData.categoryToProducts[category].forEach((template) => {
      goods[template] = null;
    });
  }
  return {
    activeCard,
    goods,
  };
};
///*
const GenerateCards = () => {
  const templates = {};
  for (const category in cardStaticData.categoryToProducts) {
    templates[category] = MakeCard(category);
    //console.log(`${category}: MakeCard("${category}"),`);
  }
  //console.log(JSON.stringify(templates));
};
GenerateCards();
//*/
const goods = {
  namespaced: true,
  state: () => ({
    verticalConstructionTemplate: MakeCard("verticalConstructionTemplate"),
    slopingRoofSystemTemplate: MakeCard("slopingRoofSystemTemplate"),
    windowTemplate: MakeCard("windowTemplate"),
    windowShadingTemplate: MakeCard("windowShadingTemplate"),
    facadeTemplate: MakeCard("facadeTemplate"),
    horizontalConstructionTemplate: MakeCard("horizontalConstructionTemplate"),
    slopingRoofCoveringTemplate: MakeCard("slopingRoofCoveringTemplate"),
    flatRoofCoveringTemplate: MakeCard("flatRoofCoveringTemplate"),
    roofWindowTemplate: MakeCard("roofWindowTemplate"),
    heatingSystemTemplate: MakeCard("heatingSystemTemplate"),
    chimneyTemplate: MakeCard("chimneyTemplate"),
    ventilationSystemTemplate: MakeCard("ventilationSystemTemplate"),
    coolingSystemTemplate: MakeCard("coolingSystemTemplate"),
    photovoltaicSystemTemplate: MakeCard("photovoltaicSystemTemplate"),
    wastewaterHeatRecoveryTemplate: MakeCard("wastewaterHeatRecoveryTemplate"),
    chargingStationTemplate: MakeCard("chargingStationTemplate"),
    swimmingPoolPoolTemplate: MakeCard("swimmingPoolPoolTemplate"),
    fenceFrontTemplate: MakeCard("fenceTemplate"),
    fenceSideTemplate: MakeCard("fenceTemplate"),
    fenceRearTemplate: MakeCard("fenceTemplate"),
    fenceStreetTemplate: MakeCard("fenceTemplate"),
    fenceOtherTemplate: MakeCard("fenceTemplate"),
    pavedSurfacePavingTemplate: MakeCard("pavedSurfacePavingTemplate"),
    terraceTemplate: MakeCard("terraceTemplate"),
    playgroundPlaygroundTemplate: MakeCard("playgroundPlaygroundTemplate"),
    gardenHouseGardenHouseTemplate: MakeCard("gardenHouseGardenHouseTemplate"),
    pergolaTemplate: MakeCard("pergolaTemplate"),
  }),
  getters: {
    getActiveCard: (state) => (slot) => {
      console.log("getActiveCard", slot, state[slot]);
      return state[slot].activeCard;
    },
    getGoods: (state) => (slot) => {
      if (! (slot in state)) console.warn(`Invalid goods ${slot}`);
      return state[slot].goods 
    },
    getCardSetup: (state) => (slot) => state[slot],
  },
  mutations: {
    setActiveCard: (state, { slot, id }) => {
      state[slot].activeCard = id;
      const category = cardStaticData.slotToCategory[slot];
      cardStaticData.categoryToProducts[category].forEach((product) => {
        state[slot].goods[product] = null;
      });
    },
    setGoods: (state, { category, data }) => {
      state[category].goods = { ...data };
    },
    setProduct: (state, { category, goods, id }) => {
      state[category].goods[goods] = id;
    },
    setCardSetup: (state, { category, setup }) => {
      state[category] = {
        activeCard: setup.activeCard,
        goods: { ...setup.goods },
      };
    },
  },
};

export default goods;
