const { updateWhatever } = require("../utils");
const location = {
  namespaced: true,
  state: () => ({
    flags: {
      waterConnection: false,
      sewerageConnection: false,
      naturalGasConnection: false,
      electricityConnection: false,
      rainwaterSewerConnection: false,
    },
    landType: "existing building-location",
    landProfile: "slope",
    cadastralCommunity: "",
    mainEntranceOrientation: "north",
    region: 3100,
  }),
  getters: {
    flags: (state) => state.flags,
    landType: (state) => state.landType,
    landProfile: (state) => state.landProfile,
    cadastralCommunity: (state) => state.cadastralCommunity,
    mainEntranceOrientation: (state) => state.mainEntranceOrientation,
    region: state => state.region,
  },
  mutations: {
    set(state, newState) {
      state = updateWhatever(state, newState);
    },
    setFlag(state, { variableName, val }) {
      state.flags[variableName] = val;
    },
    setLandType(state, val) {
      state.landType = val;
    },
    setLandProfile(state, val) {
      state.landProfile = val;
    },
    setCadastralCommunity(state, val) {
      state.cadastralCommunity = val;
    },
    setMainEntranceOrientation(state, val) {
      state.mainEntranceOrientation = val;
    },
    setRegion(state, val) {
      state.region = val;
    }
  },
  actions: {},
};

export default location;
