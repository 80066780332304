const { updateWhatever } = require("../utils");

const construction = {
  namespaced: true,
  state: () => ({
    wienerbergerClass: "smart",
    technicalSolution: "standard", // premium
    thermalInsulationLevel: "standard",
    constructionSystem: "masonry",
    roof: {
      slopingRoofCoveringType: "",
      flatRoofCoveringType: "",
    },
    windows: {
      windowMaterial: "plastic",
      windowShading: "slatted blinds",
    },
  }),
  getters: {
    wienerbergerClass: state => state.wienerbergerClass,
    technicalSolution: (state) => state.technicalSolution,
    thermalInsulationLevel: (state) => state.thermalInsulationLevel,
    constructionSystem: (state) => state.constructionSystem,
    windowMaterial: (state) => state.windows.windowMaterial,
    windowShading: (state) => state.windows.windowShading,
    slopingRoofCoveringType: (state) => state.roof.slopingRoofCoveringType,
    flatRoofCoveringType: (state) => state.roof.flatRoofCoveringType,
  },
  mutations: {
    set(state, newState) {
      state = updateWhatever(state, newState);
    },
    setWienerbergerClass(state, val) {
      state.wienerbergerClass = val;
    },
    setTechnicalSolution: (state, val) => {
      state.technicalSolution = val;
    },
    setThermalInsulationLevel: (state, val) => {
      state.thermalInsulationLevel = val;
    },
    setConstructionSystem: (state, val) => {
      state.constructionSystem = val;
    },
    setWindowMaterial: (state, val) => {
      state.windows.windowMaterial = val;
    },
    setWindowShading: (state, val) => {
      state.windows.windowShading = val;
    },
    setSlopingRoofCoveringType: (state, val) => {
      state.roof.slopingRoofCoveringType = val;
    },
    setFlatRoofCoveringType: (state, val) => {
      state.roof.flatRoofCoveringType = val;
    },
  },
  actions: {},
};

export default construction;
