<template>
  <div class="visualization-popup">
    <div class="popup-dialog">
      <div class="popup-overlay"></div>
      <div class="popup-modal">
        <div class="flex-row popup-heading">
          <h1>Výběr domu pro vizualizaci</h1>
          <span @click="onClose" class="close">&plus;</span>
        </div>
        <p>Máte vybránu obecnou konfiguraci. Na záložce Architektura jste zvolili domy, které se Vám líbí svým stylem. Na nich si můžete nakonfigurovat vizualizaci Vašeho domu. Stačí jeden z nich vybrat.</p>
        <h1>Náhledy domů označené v sekci Líbí se mi (domy nemusejí odpovídat zvolené konfiguraci, ale umožňují úpravy vizuálu)</h1>

        <div class="section">
          <multiple-choice-galery
            multiple-answer
            :options="staticData.buildingStyleLikeOptions"
            v-model="data.buildingStyleLike"
          />
        </div>

        <div class="controls-modal">
          <button class="primary">POKRAČOVAT</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MultipleChoiceGalery } from "../../components/customElements";

export default {
  data() {
    return {
      staticData: {
        buildingStyleLikeOptions: [
          {
            name: "",
            image: require("@/assets/architecture/Image.png"),
            value: "Image.png",
          },
          {
            name: "",
            image: require("@/assets/architecture/Image-1.png"),
            value: "Image-1.png",
          },
          {
            name: "",
            image: require("@/assets/architecture/Image-2.png"),
            value: "Image-2.png",
          },
          {
            name: "",
            image: require("@/assets/architecture/Image-3.png"),
            value: "Image-3.png",
          },
        ],
      },
      data: {
        buildingStyleLike: [],
      },
    };
  },
  components: {
    MultipleChoiceGalery,
  },
};
</script>

<style lang="scss">
.popup-modal {
  width: 1232px;

  h1 {
    font: normal normal medium 20px/26px Roboto;
    letter-spacing: 0.26px;
    color: #000000de;
  }
  .popup-heading {
    justify-content: space-between;
    h1 {
      margin-top: 0;
    }
    .close {
      align-self: flex-start;
      transform: rotateZ(45deg);
      transition: 300ms;
      font: 300 34px/19px Roboto;
      color: $secondary-color;
      cursor: pointer;
      &:hover {
        color: $primary-color;
        //transform: rotateZ(135deg);
      }
    }
  }
  p {
    font: normal normal 400 16px/24px Roboto;
    letter-spacing: 0.13px;
    color: #00000099;
  }
  .multiple-choice-galery .scrollable {
    width: auto;
  }
  .controls-modal {
    button.primary {
      margin: 50px 0 0 0;
      float: right;
    }
  }
}
</style>