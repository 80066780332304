import { createStore } from 'vuex'

import featureFlags from "./modules/featureFlags";
import user from "./modules/user";
import household from "./modules/household";
import architecture from "./modules/architecture";
import construction from "./modules/construction";
import hvac from "./modules/hvac";
import surroundings from "./modules/surroundings";
import location from "./modules/location";
import buildings from "./modules/buildings";
import goods from "./modules/goods";
import result from "./modules/result";
import recommandedHouses from './modules/recommandedHouses';
import cards from './modules/cards';

import api from "./api";

import _ from "underscore"; // debouncer for auto-update plugin

const { updateWhatever, getPathValue } = require("./utils");
/// Module configuration
const configuration = {
  namespaced: true,
  state: () => {},
  modules: {
    featureFlags,
    user,
    household,
    architecture,
    construction,
    hvac,
    surroundings,
    location,
    buildings,
    goods,
  },
  mutations: {
    set(state, newState) {
      state = updateWhatever(state, newState);
    },
  },
};

const families = {
  GSERVIS: "gservis",
  WIENERBERGER: "wienerberger",
};

/// Plugin automaticUpdate
const automaticUpdate = (store) => {
  // call for every mutation
  const mutationTrigger = _.debounce((mutation, state) => {
    console.log("DEB", mutation);
    // if mutation is on configuration module
    console.log("mutation type", mutation.type);
    if (mutation.type.search("configuration/set") === 0) return;

    if (mutation.type.search("configuration/") === 0) {
      store.dispatch("SEND_STATE", state);
    }
  }, 500);

  store.subscribe(mutationTrigger);
};

const store = createStore({
  strict: true,
  modules: {
    configuration,
    result,
    cards,
    recommandedHouses,
  },
  state: {
    advancedConfigurationMode: {
      household: false,
      architecture: false,
      construction: false,
      hvac: false,
      surroundings: false,
      location: false,
    },
    loadingContent: true,
    configurators: {
      owners: {},
      apiKeyToOwner: {},
      ownerToFamily: {},
    },
    configuratorOwner: "",
    accessToken: "",
    refreshToken: "",
    errors: {},
    texts: {},
    schema: {},
    manufacturers: [],
    roomCodebook: [],
    regions: [],
    energyCarriers: [],
  },
  getters: {
    advanced: (state) => (tab) => state.advancedConfigurationMode[tab],
    xApiKey: (state, getters) => {
      if (! getters.configuratorOwner) return null;
      let apiKey = "";
      
      Object.entries(state.configurators.apiKeyToOwner).some(([key, owner]) => {
        if (state.configuratorOwner != owner) return false;
        apiKey = key;
        return true;
      });
      
      return apiKey;
    },
    configuratorFamily: (state) => state.configurators.ownerToFamily[state.configuratorOwner],
    hasGservisFamily: (state, getters) => getters.configuratorFamily === families.GSERVIS,
    hasWienerbergerFamily: (state, getters) => getters.configuratorFamily === families.WIENERBERGER,
    owners: (state) => Object.keys(state.configurators.ownerToFamily),
    configuratorOwner: (state) => state.configuratorOwner,
    accessToken: state => state.accessToken,
    refreshToken: state => state.refreshToken,
    hasError: state => param => state.errors[param],
    getError: state => param => state.errors[param],
    texts: state => state.texts,
    getText: state => (strIndex, fillparams) => { 
      if (!fillparams) return state.texts[strIndex];
      
      const replacer = (() => {
        let index = 0; 
        return () => {
          if (index < fillparams.length) return fillparams[index++];
          return '{}';
        };
      })();
      const text = state.texts[strIndex];
      if (!text) return text;
      const regex = /\{\}/g;
      return text.replaceAll(regex, replacer);
    },
    getTextParagraphs: state => param => {
      return state.texts[param].split('\n').map(text => `<p>${text}</p>`).join('\n');
    },
    loadingContent: state => {
      return state.loadingContent;
    },
    schema: state => state.schema,
    getSchema: state => path => getPathValue(state.schema, path),
    getSchemaOptions: state => (path, stringOptionPath) => {
      if (!stringOptionPath) stringOptionPath = path.split('.').slice(1).join('.');
      return getPathValue(state.schema, path).enum.map((value,idx) => ({name: state.texts[`${stringOptionPath}.option${idx}`], value}))
    },
    manufacturers: state => state.manufacturers,
    getManufacturerByID: state => index => { 
      if (! state.manufacturers.map(m => m.index).includes(index)) {
        console.error(`Unknown manufacturer ${index}.`);
        return {name: '!!! NO ONE !!!', logo: 'https://g-servis-dek-configurator.dejmas.cz/logos/noone.png'};
      }
      const [manufacturer] = state.manufacturers.filter(m => m.index == index);
      return manufacturer;
    },
    getRoomData: state => identifier => {
      const [roomRecord] = state.roomCodebook.filter(room => room.identifier == identifier);
      if (!roomRecord) {
        console.error(`Unknown room ${identifier}`);
      }
      return roomRecord;
    },
    getRegions: state => state.regions,
    energyCarriers: state => state.energyCarriers,
  },
  mutations: {
    advanced(state, { value, tab }) {
      state.advancedConfigurationMode[tab] = value;
    },
    setConfigurators(state, configurators) {
      state.configurators = configurators;
    },
    setOwner(state, owner) {
      state.configuratorOwner = owner;
    },
    setApiKey(state, apiKey) {
      state.configuratorOwner = state.configurators.apiKeyToOwner[apiKey];
    },
    setAccessToken(state, val) {
      state.accessToken = val;
    },
    setRefreshToken(state, val) {
      state.refreshToken = val;
    },
    setErrors(state, val) {
      state.errors = {...val};
    },
    cleanErrors(state) {
      state.errors = {};
    },
    setTexts(state, val) {
      state.texts = val;
    },
    setLoadingContent(state, val) {
      state.loadingContent = val;
    },
    setSchema(state, val) {
      state.schema = val;
    },
    setManufacturers(state, val) {
      state.manufacturers = val;
    },
    setRoomCodebook(state, val) {
      state.roomCodebook = val;
    },
    setRegions(state, val) {
      state.regions = val;
    },
    setEnergyCarriers(state, val) {
      state.energyCarriers = val;
    },
  },
  actions: {
    logout (context) {
      const data = {
        refreshToken: context.getters.refreshToken
      };
      api.delete("/api/auth/logout", data).then(() => {
        context.commit("setAccessToken", "");
        context.commit("setRefreshToken", "");
        context.commit("setApiKey", "");
        localStorage.clear();
      });
    },
    LOAD_INITIAL_STATE(context) {
      api
        .get("/api/initial-configuration")
        .then((data) => {
          const configuration = data;
          console.log(data);
          context.commit("configuration/set", configuration);
          context.commit(
            "configuration/buildings/set",
            configuration.buildings
          );
          context.dispatch('SEND_STATE', context.state);
        })
        .catch((error) => {
          console.log("error/bad connection/invalid input");
          console.log("LOAD_INITIAL_STATE error:", error);
        });
    },
    async LOAD_SAVED_STATE(context, data) {
      try {
        context.commit("setLoadingContent", true);

        // texts
        const texts = await api.get("/api/texts");
        context.commit("setTexts", texts);

        const configuration = data.configuration;
        console.log(data.price);
        context.commit("configuration/set", configuration);
        context.commit(
          "configuration/buildings/set",
          configuration.buildings
        );
        context.commit("result/updatePrice", data.price);
        context.commit("result/updateEnergetics", data.energetics);
        //context.commit("result/updateRooms", data.rooms);
        context.commit(
          "recommandedHouses/updateRecommandedHouses",
          data.recommandedHouses
        );
        context.commit("cards/setCards", data.cards);

        // schema
        const schema = await api.get("/api/helper/configuration-schema");
        context.commit("setSchema", schema);
        // roomCodebook
        const roomCodebook = await api.get("/api/helper/room-codebook");
        context.commit("setRoomCodebook", roomCodebook);
        // manufacturers
        const manufacturers = await api.get("/api/helper/manufacturers");
        context.commit("setManufacturers", manufacturers);
        // regions
        const regions = await api.get("/api/helper/regions");
        context.commit("setRegions", regions);
        // energy carriers
        const energyCarriers = await api.get("/api/helper/energy-carriers");
        context.commit("setEnergyCarriers", energyCarriers);
         
        context.commit("setLoadingContent", false);
      } catch(error) {
        console.log("LOAD_SAVED_STATE error:", error);
      }
    },
    async LOAD_STATIC_DATA(context) {
      try {
        context.commit("setLoadingContent", true);

        const configurators = await api.get("/api/maintenance/configurators");
        context.commit("setConfigurators", configurators);

        // texts
        const texts = await api.get("/api/texts");
        context.commit("setTexts", texts);
        // initial state
        const initialState = await api.get("/api/initial-configuration")
        const configuration = initialState;
        context.commit("configuration/set", configuration);
        context.commit("configuration/buildings/set", configuration.buildings);
        context.dispatch('SEND_STATE', context.state);
        // schema
        const schema = await api.get("/api/helper/configuration-schema");
        context.commit("setSchema", schema);
        // roomCodebook
        const roomCodebook = await api.get("/api/helper/room-codebook");
        context.commit("setRoomCodebook", roomCodebook);
        // manufacturers
        const manufacturers = await api.get("/api/helper/manufacturers");
        context.commit("setManufacturers", manufacturers);
        // regions
        const regions = await api.get("/api/helper/regions");
        context.commit("setRegions", regions);
        // energy carriers
        const energyCarriers = await api.get("/api/helper/energy-carriers");
        context.commit("setEnergyCarriers", energyCarriers);

        context.commit("setLoadingContent", false);
      } catch(error) {
        console.log("LOAD_STATIC_DATA error:", error);
        context.commit("setLoadingContent", false);
      }
    },

    LOAD_TEXTS(context) {
      api
      .get("/api/texts")
      .then((data) => {
          //console.log(data);
          context.commit("setTexts", data);
        })
        .catch((error) => {
          console.log("LOAD_TEXTS error:", error);
        });
    },

    async textTest(context) {
      const texts = await api.get("/api/texts/test");
      context.commit('setTexts', texts);
    },

    async importTextsToAPI(context) {
      const res = await api.get("/api/maintenance/import-texts");
      console.log(res);
      // texts
      const texts = await api.get("/api/texts");
      context.commit("setTexts", texts);
    },
    /* SAVE_STATE(context) {
      api
        .post("/api/save-state", context.state)
        .then(data => {

        })
        .catch(error => {

        })
    }, */

    SEND_STATE(context, payload) {
      api
        .post("/api/update-result", payload.configuration)
        .then((data) => {
          const configuration = data.configuration;
          console.log(data.price);
          context.commit("configuration/set", configuration);
          context.commit(
            "configuration/buildings/set",
            configuration.buildings
          );
          context.commit("result/updatePrice", data.price);
          context.commit("result/updateEnergetics", data.energetics);
          //context.commit("result/updateRooms", data.rooms);
          context.commit(
            "recommandedHouses/updateRecommandedHouses",
            data.recommandedHouses
          );
          context.commit("cards/setCards", data.cards);
          //this.commit("updateRooms", data.rooms);
          // in configuration
        })
        .catch((errors) => {
          context.commit("setErrors", errors);
          console.log("SEND_STATE errors:", errors);
        });
    },
    async CLONE_CONFIGURATOR(context, payload) {
      try {
        context.commit("setLoadingContent", true);
        const res = await api.post('/api/maintenance/clone-configurator', payload);
        context.commit("setLoadingContent", false);
        return res;
      } catch (error) {
        console.log("CLONE_CONFIGURATOR error:", error);
        context.commit("setLoadingContent", false);
      }
    },
    async DELETE_DATA(context, payload) {
      try {
        context.commit("setLoadingContent", true);
        const res = await api.post('/api/maintenance/delete-in-configurator', payload);
        context.commit("setLoadingContent", false);
        //context.dispatch('LOAD_STATIC_DATA');
        console.log("DELETE_DATA:", res);
        return res;
      } catch (error) {
        context.commit("setLoadingContent", false);
        console.log("DELETE_DATA error:", error);
      }
    }
  },
  plugins: [automaticUpdate],
});

export default store;
