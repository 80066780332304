<template>
<div class="backups">
    <h1 class="title">Zálohy stavu 
    </h1>
    <button @click="loadSnapshots()" class="primary">nacist</button>
    <div class="table">
        <div v-for="snap in snapshots" :key="snap.file" class="backup">
            <span v-text="snap.displayTime"></span>
            <a @click="get(snap)" v-text="getName(snap)" href="#"></a>
            <div>
                <button class="action" @click="readSnapshot(snap._id)">
                    porovnat změny 
                </button>

                <button class="action" @click="openRevertDialog(snap)">
                    obnovit snapshot 
                </button>

                <button class="action remove" @click="removeSnapshot(snap._id)">
                    x
                </button>
            </div>
        </div>
    </div>

    <MyDialog class="revert-dialog" :heading="revertDialog.heading" :show="revertDialog.show" @input="revertDialog.show = false">
        <p>Označte data, která si přejete obnovit ze zálohy.</p>
        <div class="flex-column">
            <div class="flex-row">
                <span class="label">Texty</span>
                <ToggleButtonVModel v-model="revertDialog.options.texts" />
            </div>

            <div class="flex-row">
                <span class="label">Karty</span>
                <ToggleButtonVModel v-model="revertDialog.options.cards" />
            </div>

            <div class="flex-row">
                <span class="label">Domy</span>
                <ToggleButtonVModel v-model="revertDialog.options.houses" />
            </div>

            <div class="flex-row">
                <span class="label">Uložené konfigurace</span>
                <ToggleButtonVModel v-model="revertDialog.options.storedConfigurations" />
            </div>

            <div class="flex-row">
                <span class="label">Počáteční konfigurace</span>
                <ToggleButtonVModel v-model="revertDialog.options.initialConfigurations" />
            </div>
            
            <div class="flex-row">
                <span class="label">Příznaky funkcionalit</span>
                <ToggleButtonVModel v-model="revertDialog.options.featureFlags" />
            </div>

            <div class="flex-row">
                <span class="label">Výrobci</span>
                <ToggleButtonVModel v-model="revertDialog.options.manufacturers" />
            </div>
        </div>

        <div class="errors" v-if="revertDialog.errors">
            <h1>Chyby</h1>
            <pre v-text="revertDialog.errors"></pre>
        </div>

        <div class="button-container flex-row">
            <button class="primary" @click="revertSnapshot()"> Obnovit snapshot </button>
        </div>
        
        
    </MyDialog>

    <button @click="makeSnapshot()" class="primary">
        vytvořit zálohu
    </button>
    <div class="synchronize">
        <h1 class="title">Synchronize s jinou instancí.</h1>
        <select v-model="synchronizeFrom" name="server">
            <option v-for="server in servers" :key="server.url" :value="server.url" :disabled="server.disabled">{{server.name}} ({{server.url}})</option>
        </select>
        <button @click="synchronize" class="primary">synchronizovat zálohy</button>
    </div>

    <h1 class="title">Změny</h1>
    <BackupDiff :changes="changes" />
        
</div>  
</template>

<script>
import api from '../../../store/api'
import MyDialog from '../../components/MyDialog.vue';
import ToggleButtonVModel from '../../components/fundamentals/ToggleButtonVModel.vue';
import BackupDiff from './BackupDiff';
//import DetailDiff from './DetailDiff';
//import PrettyDiff from './PrettyDiff';
export default {
    components: { BackupDiff, MyDialog, ToggleButtonVModel },
    mounted() {
        this.loadSnapshots();
        this.loadInstances();
        this.synchronizeFrom; 
    },
    data() {
        return {
            servers: [
                {name: "beta", url: "g-servis-dek-configurator.dejmas.cz", disabled: false},
                {name: "ostrá", url: "configurator.deksoft.eu", disabled: true},
            ],
            synchronizeFrom: null,
            snapshots: [],
            changes: {},
            revertDialog: {
                heading: "",
                show: false,
                snapshot: null,
                options: {
                    texts: true,
                    cards: true,
                    houses: true,
                    storedConfigurations: true,
                    initialConfigurations: true,
                    featureFlags: true,
                    manufacturers: true,
                },
                errors: null,
            },
        }
    },
    methods: {
        openRevertDialog(snapshot) {
            this.revertDialog.heading = `Obnovit snaphost ${snapshot.file}`;
            this.revertDialog.snapshot = snapshot;
            this.revertDialog.options = {
                texts: true,
                cards: true,
                houses: true,
                storedConfigurations: true,
                initialConfigurations: true,
                featureFlags: true,
                manufacturers: true,
            };
            this.revertDialog.show = true;
            this.revertDialog.errors = null;
        },
        getName(snap) {
            return snap.version == 2 ? snap.name : snap.file;
        },
        async get(snapshot) {
            try {
                if (!snapshot.url) {
                    console.warn('snapshot has no url.');
                    return;
                }
                const res =  await api.request(
                    'get', 
                    snapshot.url, 
                    {},
                    {responseType: 'blob'}
                );
                const dataurl = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = dataurl;
                link.setAttribute('download', snapshot.file); //or any other extension
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.log(error);
            }
        },
        async makeSnapshot() {
            try {
                const res = await api.get('/api/maintenance/make-snapshot');
                if (res !== 'ok') console.log(res);
                this.loadSnapshots();
            } catch (error) {
                console.error(error);
            }
        },
        async readSnapshot(id) {
            try {
                const res = await api.post('/api/maintenance/read-snapshot', {id});
                this.changes = res;
                console.log(res)
            } catch (error) {
                console.error(error);
            }
        },
        async revertSnapshot() {
            try {
                if (!confirm('Obnovením snapshotu zahodíte rozpracované změny, které snaphost přepíše. Přejete si obnovit snapshot?')) return;
                const data = {
                    id: this.revertDialog.snapshot._id,
                    options: this.revertDialog.options,
                };
                const res = await api.post('/api/maintenance/restore-snapshot', data);
                console.log(res)
                this.loadSnapshots();
                this.revertDialog.show = false;
            } catch (error) {
                this.revertDialog.error = error;
                console.error(error);
            }
        },
        async synchronize() {
            try {
                const res = await api.post('/api/maintenance/synchronize-snapshots', 
                    { synchronizeFrom: this.synchronizeFrom });
                console.log("sync", res);
                this.loadSnapshots();
            } catch (error) {
                console.error(error);
            }
        },
        async loadSnapshots() {
            try {
                const res = await api.get('/api/maintenance/list-snapshots');
                this.snapshots = res;
            } catch (error) {
                console.error(error);
            }
        },
        async loadInstances() {
            try {
                const res = await api.get('/api/maintenance/instances');
                this.servers = res;
            } catch (error) {
                console.error(error);
            }
        },
        async removeSnapshot(id) {
            try {
                if (!confirm('Přejete si smazat snapshot?')) return;
                const res = await api.post('/api/maintenance/remove-snapshot', {id});
                console.log(res)
                this.loadSnapshots();
            } catch (error) {
                console.error(error);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.backups {
    .table {
        display: grid;
        width: 100%;
        grid-template-rows: 1fr;
        .backup {
            line-height: 1.6em;
            background: white;
            border-bottom: 1px solid grey;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    //background: chocolate;
    .synchronize {
        select {
            height: 40px;
        }
    }
    
    .action {
        border-radius: 3px;
        border-width: 0.01em;
        padding: 4px 10px;
        margin: 2px;
    }

    .remove {
        background-color: $primary-color;
        color: white;
    }

    .revert-dialog {
        .label {
            color: black;
            width: 250px;
            height: 30px;
            &.disabled {
                color: lighten(black, 50%);
            }
        }
    }

    .button-container {
        //width: 800px;
        align-items: center;
        justify-content: center;
    }
}
</style>