const { updateWhatever } = require("../utils");
//const Vue = require("vue");
/// Module configuration
const people = {
  namespaced: true,
  state: () => ({
    children: 2,
    parents: 2,
    grandparents: 0,
    others: 0,
  }),
  getters: {
    children: (state) => state.children,
    parents: (state) => state.parents,
    grandparents: (state) => state.grandparents,
    others: (state) => state.others,
  },
  mutations: {
    setChildren(state, val) {
      state.children = val;
    },
    setParents(state, val) {
      state.parents = val;
    },
    setGrandparents(state, val) {
      state.grandparents = val;
    },
    setOthers(state, val) {
      state.others = val;
    },
  },
};

const household = {
  namespaced: true,
  state: () => ({
    livingStandard: "standard",
    rooms: {
      bedrooms: { value: 1, lock: false },
      childrenRooms: { value: 2, lock: false },
      bathrooms: { value: 1, lock: false },
      bathroomsWithWC: { value: 0, lock: false },
      toilets: { value: 2, lock: false },
      kitchens: { value: 1, lock: false },
      livingRooms: { value: 1, lock: false },
    },
    
    //garageInHouse: false,
    //parkingSlotsInGarage: 0,
    flags: {
      technicalRoom: false,
      separateHouseholdForGrandparents: false,
      barrierfreeHousing: false,
      closedKitchen: false,
      pantry: 0,
      wic: 0,
      //masterBathroom: false,
      study: 0,
      guestRoom: 0,
      //storageSpaceInGarage: false,
      storageForGardenEquipment: 0,
      indoorPool: false,
      indoorWellness: false,
      fitness: false,
      conservatory: false,
    },
  }),
  getters: {
    lock: (state) => (name) => state.rooms[name].lock,
    children: (state) => state.people.children,
    parents: (state) => state.people.parents,
    grandparents: (state) => state.people.grandparents,
    others: (state) => state.people.others,
    livingStandard: (state) => state.livingStandard,
    bedrooms: (state) => state.rooms.bedrooms.value,
    childrenRooms: (state) => state.rooms.childrenRooms.value,
    bathrooms: (state) => state.rooms.bathrooms.value,
    bathroomsWithWC: (state) => state.rooms.bathroomsWithWC.value,
    toilets: (state) => state.rooms.toilets.value,
    kitchens: (state) => state.rooms.kitchens.value,
    livingRooms: (state) => state.rooms.livingRooms.value,
    //garageInHouse: (state) => state.garageInHouse,
    //parkingSlotsInGarage: (state) => state.parkingSlotsInGarage,
    flags: (state) => state.flags,
  },
  mutations: {
    set(state, newState) {
      state = updateWhatever(state, newState);
    },
    setUnlock(state, variableName) {
      state.rooms[variableName].lock = false;
    },
    setLivingStandard(state, val) {
      state.livingStandard = val;
    },
    setBedrooms(state, val) {
      state.rooms.bedrooms.value = val;
      state.rooms.bedrooms.lock = true;
    },
    setChildrenRooms(state, val) {
      state.rooms.childrenRooms.value = val;
      state.rooms.childrenRooms.lock = true;
    },
    setBathrooms(state, val) {
      state.rooms.bathrooms.value = val;
      state.rooms.bathrooms.lock = true;
    },
    setBathroomsWithWC(state, val) {
      state.rooms.bathroomsWithWC.value = val;
      state.rooms.bathroomsWithWC.lock = true;
    },
    setToilets(state, val) {
      state.rooms.toilets.value = val;
      state.rooms.toilets.lock = true;
    },
    setKitchens(state, val) {
      state.rooms.kitchens.value = val;
      state.rooms.kitchens.lock = true;
    },
    setLivingRooms(state, val) {
      state.rooms.livingRooms.value = val;
      state.rooms.livingRooms.lock = true;
    },
    /*setGarageInHouse(state, val) {
      if (val && state.parkingSlotsInGarage === 0) {
        state.parkingSlotsInGarage = 1;
      }
      state.garageInHouse = val;
    },
    setParkingSlotsInGarage(state, val) {
      if (val === 0) {
        state.garageInHouse = false;
      }
      state.parkingSlotsInGarage = val;
    },*/
    setFlag(state, { variableName, val }) {
      state.flags[variableName] = val;
    },
    /*     
    removeCountableRooms(state, variableName) {
      state.rooms[variableName].value -= 1;
      state.rooms[variableName].lock = true;
      //console.log(variableName, state.rooms[variableName]);
    },
    removeFlagRoom(state, variableName) {
      state.flags[variableName] = false;
    }, */
    hintRooms(state, rooms) {
      state.rooms = { ...rooms };
    },
  },
  actions: {},
  modules: {
    people,
  },
};
/*
function nameToIdentifier(name) {
  const capitalize = (str) => {
    if (str.lenght === 0) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const tokens = name.trim().split(/[^\w]+/);
  if (tokens.lenght === 0) return "";
  return (
    tokens[0] +
    tokens
      .slice(1)
      .map(capitalize)
      .join("")
  );
}
*/
export default household;
