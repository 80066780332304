<template>
  <div class="clone-configurator">
    <h1>Klonování konfigurátoru</h1>
    <p>
      Nově přidaný konfigurátor bez dat není funkční. Takový konfigurátor
      bohužel ani nenačte frontend. Postrádá nezbytné struktury v databázi pro
      jeho fungování. To je jmenovitě nastavení příznaků, texty, karty, domy a
      počáteční konfigurace. Funkce klonování je vhodná k tomu, aby si nový
      konfigurátor vytvořil datové struktury podle nějakého funkčního
      konfigurátoru. Klonování vytvoří nový (resp. přepíše existující) sloupec v
      tabulkách s texty.
    </p>

    <p>
      <strong>
        Pokud již konfigurátor nějaké data má, klonování je přepíše. Před
        použitím funkce je doporučené vždy vytvořit zálohu. S funkcí by se mělo
        zacházet moudře.
      </strong>
      Dva základní konfigurátory WIENERBERGER a GSERVIS nelze zvolit jako cíl
      klonování z bezpecnostních.
    </p>

    <p>
      Cíl klonování musí existovat v tabulce
      <a
        href="https://docs.google.com/spreadsheets/d/1zYjUiVZQznmQFjVFSe1c2OHkVQhjQRQ-MqFwFRhOduI/edit#gid=1581749983"
        >DEK Configurator Admin / Configurators</a>. 
        API potřebuje mít obnovéné data z tabulky.
    </p>

    <div>
        <div class="flex-column">
            <label>klonovat z konfigurátoru</label>
            <select 
                :value="payload.cloneeOwner"
                @change="payload.cloneeOwner = $event.target.value"
            >
                <option 
                    v-for="owner in owners" 
                    :value="owner" 
                    v-text="owner.toUpperCase()" 
                    :key="owner" 
                />
            </select>
        </div>

        <div class="flex-column">
            <label>cíl klonování</label>
            <select 
                :value="payload.newOwner"
                @change="payload.newOwner = $event.target.value"
            >
                <option 
                    v-for="owner in owners.filter(excludeTargetsFilter)" 
                    :value="owner" 
                    v-text="owner.toUpperCase()" 
                    :key="owner" 
                />
            </select>
        </div>
    </div>

    <div>
        <p><strong>Objekty klonování</strong></p>
        <div class="flex-column clone-objects">
            <div class="flex-inline space-bet">
                <p>Texty</p>
                <ToggleButton  
                    :value="payload.options.texts"
                    @input="payload.options.texts = $event"
                />
            </div>

            <div class="flex-inline space-bet">
                <p>Sloupce textů v google tabulkách</p>
                <ToggleButton 
                    :value="payload.options.textTable"
                    @input="payload.options.textTable = $event"
                />
            </div>

            <div class="flex-inline space-bet">
                <p>Karty</p>
                <ToggleButton 
                    :value="payload.options.cards"
                    @input="payload.options.cards = $event"
                />
            </div>

            <div class="flex-inline space-bet">
                <p>Domy</p>
                <ToggleButton 
                    :value="payload.options.houses"
                    @input="payload.options.houses = $event"
                />
            </div>

            <div class="flex-inline space-bet">
                <p>Uložené konfigurace (zbytečné)</p>
                <ToggleButton
                disabled
                    :value="payload.options.storedConfigurations"
                    @input="payload.options.storedConfigurations = $event"
                />
            </div>

            <div class="flex-inline space-bet">
                <p>Počáteční konfigurace (nutné)</p>
                <ToggleButton
                    disabled
                    :value="payload.options.initialConfiguration"
                    @input="payload.options.initialConfiguration = $event"
                />
            </div>

            <div class="flex-inline space-bet">
                <p>Funkcionality (nutné)</p>
                <ToggleButton 
                    disabled
                    :value="payload.options.featureFlags"
                    @input="payload.options.featureFlags = $event"
                />
            </div>
        </div>
        <p><strong>Přepis dat</strong></p>
        <div class="flex-column clone-objects">
            <div class="flex-inline space-bet">
                <p>Povolit přepis</p> 
                <ToggleButton 
                    :value="payload.options.rewrite"
                    @input="payload.options.rewrite = $event"
                />
            </div>
        </div>

    </div>

    <button class="primary" @click="perform">SPUSTIT KLONOVÁNÍ</button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {ToggleButton} from '../components/customElements';

export default {
  components: { 
    ToggleButton,
  },
  computed: {
    ...mapGetters([
      "xApiKey",
      "configuratorOwner",
      "owners",
      "configuratorFamily",
      "loadingContent",
    ]),
  },
  data() {
    return {
      payload: {
        cloneeOwner: "wienerberger",
        newOwner: "",
        options: {
          rewrite: true,
          texts: true,
          textTable: true,
          cards: true,
          houses: true,
          storedConfigurations: false,
          initialConfiguration: true,
          featureFlags: true,
        },
      },
    };
  },
  methods: {
    excludeTargetsFilter(value) {
        return value != 'wienerberger' && value != 'gservis';
    },
    async perform() {
        const res = this.$store.dispatch('CLONE_CONFIGURATOR', this.payload);
        console.log(res);
    }
  }
};
</script>

<style lang="scss">
.clone-configurator {
    .clone-objects {
        max-width: 250px;
    }
    .space-bet {
        justify-content: space-between;
        align-items: baseline;
    }
    label {
        font-weight: 600;
    }
}
</style>