<template>
    <div class="feature-flags">
        <h1 class="title">FUNKCIONALITY</h1>
        <div class="list flex-column">
            <div class="headings flex-row">
                <div class="cell"><strong>name</strong></div>
                <div class="cell"><strong>status</strong></div>
                <div class="cell"><strong>action</strong></div>
            </div>
            <div :key="key" v-for="(val, key) in featureFlags.data" class="feature flex-row">
                <div class="cell">{{key}}</div>
                <div class="cell" v-text="val? 'active' : 'inactive'"></div>
                <div class="cell">
                    <button 
                        class="button" 
                        v-text="val? 'deactivate' : 'activate'"
                        @click="toggle(key)"
                    ></button>
                </div>
            </div>
        </div>
        <div class="flex-row">
            <input type="text" v-model="newFlag">
            <button @click="addNewFlag(newFlag)">add new flag</button>
        </div>
        <div class="error" v-show="errors" ><strong>Chyba:</strong> <span class="error" v-text="errors"></span> </div>
    </div>
</template>

<script>
//import { mapGetters } from 'vuex';
import api from '../../../store/api';
export default {
    data() {
        return {
            featureFlags: {
                name: "",
                configuratorOwner: "",
                data: {

                }
            },
            loaded: false,
            errors: "",
            newFlag: '',
        };
    },
    async mounted() {
        await this.loadFlags();
    },
    //computed: mapGetters(),
    methods: {
        async loadFlags() {
            try {
                const settings = await api.get('/api/maintenance/settings');
                this.featureFlags = settings;
                this.loaded = true;
            } catch (errors) {
                console.error(errors)
                this.errors = errors;
            }
        },
        async toggle(key) {
            try {           
                const copy = JSON.parse(JSON.stringify(this.featureFlags));
                copy.data[key] = !this.featureFlags.data[key];
                const res = await api.post('/api/maintenance/settings-save', copy);
                console.log(res);
                await this.loadFlags();
            } catch (errors) {
                console.error(errors)
                this.errors = errors;
            }
        },
        async addNewFlag(key) {
            try {
                if (!key) return;           
                const copy = JSON.parse(JSON.stringify(this.featureFlags));
                copy.data[key] = false;
                const res = await api.post('/api/maintenance/settings-save', copy);
                console.log(res);
                await this.loadFlags();
            } catch (errors) {
                console.error(errors)
                this.errors = errors;
            }
        }
    },
}
</script>

<style lang="scss" scope>
.feature-flags {
    .list {
        background: white;
        padding: 5px;
    }
    .error {
        padding-inline: 5px;
        background: white;
        line-height: 3em;
        color: rgb(192, 15, 15);
    }
    .cell {
        flex-basis: 30%;
        >.button {
            width: 120px;
        }
    }
    .feature,.headings {
        column-gap: 50px;
        line-height: 3em;
        border-bottom: 2px solid black;
        

    }
    .feature {
        border-bottom: 1px solid black;
    }
}
</style>