<template>
  <div class="household">
    <h1 class="title" v-text="getText('household.heading')"></h1>

    <p class="description" v-text="getText('household.editorial')"></p>
    <MySection :name="getText('people.question')" :information="getText('people.info')">
      <div class="flex-row" style="flex-wrap: wrap;">
        <LabeledCounter
          :value="children"
          @input="setChildren"
          :schema="getSchema('household.people.children')"
          >{{getText('people.children.question')}}</LabeledCounter
        >
        <LabeledCounter
          :value="parents"
          @input="setParents"
          :schema="getSchema('household.people.parents')"
          >{{getText('people.parents.question')}}</LabeledCounter
        >
        <LabeledCounter
          :value="grandparents"
          @input="setGrandparents"
          :schema="getSchema('household.people.grandparents')"
          >{{getText('people.grandparents.question')}}</LabeledCounter
        >
        <LabeledCounter
          :value="others"
          @input="setOthers"
          :min="Number(0)"
          :schema="getSchema('household.people.others')"
          >{{getText('people.others.question')}}</LabeledCounter
        >
      </div>
    </MySection>

    <MySection
      v-for="flag in staticData.flags.filter(getFilterForGroup('intro'))"
      :key="flag.name"
      :name="getText(`${flag.variableName}.question`)"
      v-show="isFlagVisible(flag)"
      :information="getText(`${flag.variableName}.info`)"
    >
      <ToggleButton
        :value="flags[flag.variableName]"
        @input="setFlag({ variableName: flag.variableName, val: $event })"
      />
    </MySection>
<!--
    <MySection
      v-show="isFlagVisible(staticData.flags.filter(f=>f.variableName == 'separateHouseholdForGrandparents')[0])"
      :name="staticData.flags.filter(f=>f.variableName == 'separateHouseholdForGrandparents')[0].name"
      :information="getText('info.household.separateHouseholdForGrandparents')"
      tab="household"
    >
      <ToggleButton
        :value="flags['separateHouseholdForGrandparents']"
        @input="setFlag({ variableName: 'separateHouseholdForGrandparents', val: $event })"
      />
    </MySection>
-->
    <MySection :name="getText('livingStandard.question')" :information="getText('info.household.livingStandard')">
      <MultipleChoice
          single-answer
          :modelValue="livingStandard"
          @update:modelValue="setLivingStandard"
          :options="getSchemaOptions('household.livingStandard')"
        />
    </MySection>

    <MySection :name="getText('rooms.question')" :information="getText('info.household.rooms')">
        <div class="flex-column">
          <div class="flex-row" style="flex-wrap: wrap;">
            <LabeledCounter
              :value="bedrooms"
              @input="setBedrooms"
              :schema="getSchema('household.rooms.bedrooms.value')"
              >{{getText('rooms.bedrooms.question')}}
              <i
                v-if="lock('bedrooms')"
                @click="setUnlock('bedrooms')"
                class="fa fa-undo edited"
              ></i>
            </LabeledCounter>
            <LabeledCounter
              :value="childrenRooms"
              @input="setChildrenRooms"
              :schema="getSchema('household.rooms.childrenRooms.value')"
              >{{getText('rooms.childrenRooms.question')}}
              <i
                v-if="lock('childrenRooms')"
                @click="setUnlock('childrenRooms')"
                class="fa fa-undo edited"
              ></i>
            </LabeledCounter>

            <LabeledCounter :value="kitchens" :schema="getSchema('household.rooms.kitchens.value')">
              <span v-if="flags.closedKitchen">{{getText('rooms.kitchens.question')}}</span>
              <span v-else>{{getText('rooms.kitchenette.question')}}</span>
            </LabeledCounter>

            <LabeledCounter :value="livingRooms" :schema="getSchema('household.rooms.livingRooms.value')"
              >{{getText('rooms.livingRooms.question')}}</LabeledCounter
            >
          </div>
          <div class="flex-row">
            <LabeledCounter
              :value="bathrooms"
              @input="setBathrooms"
              :schema="getSchema('household.rooms.bathrooms.value')"
              >{{getText('rooms.bathrooms.question')}}
              <i
                v-if="lock('bathrooms')"
                @click="setUnlock('bathrooms')"
                class="fa fa-undo edited"
              ></i>
            </LabeledCounter>
            <LabeledCounter
              :value="toilets"
              @input="setToilets"
              :schema="getSchema('household.rooms.toilets.value')"
              >{{getText('rooms.toilets.question')}}
              <i
                v-if="lock('toilets')"
                @click="setUnlock('toilets')"
                class="fa fa-undo edited"
              ></i>
            </LabeledCounter>

            <LabeledCounter
              :value="bathroomsWithWC"
              @input="setBathroomsWithWC"
              :schema="getSchema('household.rooms.bathroomsWithWC.value')"
              >{{getText('rooms.bathroomsWithWC.question')}}
              <i
                v-if="lock('bathroomsWithWC')"
                @click="setUnlock('bathroomsWithWC')"
                class="fa fa-undo edited"
              ></i>
            </LabeledCounter>

            <div style="width: 122px; margin: 0 10px;"></div>
          </div>
        </div>
    </MySection>

    <MySection
      v-for="flag in staticData.flags.filter(getFilterForGroup('basic'))"
      :key="flag.name"
      :name="getText(`${flag.variableName}.question`)"
      v-show="isFlagVisible(flag)"
      :information="getText(`${flag.variableName}.info`)"
    >
      <ToggleButton
        :value="flags[flag.variableName]"
        @input="setFlag({ variableName: flag.variableName, val: $event })"
      />
    </MySection>

    <AdvancedQuestionsToggleButton v-model="advancedMode" />

    <MySection
      v-for="flag in staticData.flags.filter(getFilterForGroup('advanced'))"
      :key="flag.name"
      :name="getText(`${flag.variableName}.question`)"
      v-show="isFlagVisible(flag)"
      advanced
      tab="household"
      :information="getText(`${flag.variableName}.info`)"
    >
      <CounterInput
        v-if="flag.counter"
        :value="flags[flag.variableName]"
        :min="0"
        :max="5"
        @input="
          setFlag({ variableName: flag.variableName, val: $event })
        "
      />

      <ToggleButton
        v-else
        :value="flags[flag.variableName]"
        @input="setFlag({ variableName: flag.variableName, val: $event })"
      />
    </MySection>

    <div class="section-controls">
      <button @click="$router.push('/architecture')" class="primary" v-text="getText('nextButton')"></button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";
import MySection from "../components/MySection";
import {
  LabeledCounter,
  CounterInput,
  ToggleButton,
  MultipleChoice,
  AdvancedQuestionsToggleButton,
} from "../components/customElements";

export default {
  name: "HouseholdTab",
  components: {
    LabeledCounter,
    ToggleButton,
    MultipleChoice,
    AdvancedQuestionsToggleButton,
    MySection,
    CounterInput,
  },
  computed: {
    advancedMode: {
      get() {
        return this.$store.getters.advanced("household");
      },
      set(v) {
        this.$store.commit("advanced", { value: v, tab: "household" });
      },
    },
    ...mapGetters("configuration/household/people", [
      "children",
      "parents",
      "grandparents",
      "others",
    ]),
    ...mapGetters("configuration/household", [
      "lock",
      "livingStandard",
      "bedrooms",
      "childrenRooms",
      "bathrooms",
      "bathroomsWithWC",
      "toilets",
      "kitchens",
      "livingRooms",
      //  "garageInHouse",
      //  "parkingSlotsInGarage",
      "flags",
    ]),
    ...mapGetters([
      "hasWienerbergerFamily",
      "hasGservisFamily",
      "getText",
      'getSchema',
      'getSchemaOptions',
    ]),
  },
  methods: {
    getFilterForGroup(group) {
      const intro = ['separateHouseholdForGrandparents'];
      let basic = ['technicalRoom'];
      if (this.isWienerberger()) {
        basic.push('study');
      }
      if (group === 'intro') {
        return (flag) => intro.includes(flag.variableName);
      }
      if (group === 'basic') {
        return (flag) => basic.includes(flag.variableName);
      }
      return flag => !basic.includes(flag.variableName) && !intro.includes(flag.variableName);
    },
    isFlagVisible(flag) {
      if (flag.variableName === "separateHouseholdForGrandparents") {
        return this.grandparents > 0;
      }
      return true;
    },
    event(event) {
      console.log(event);
    },
    isGservis() {
      return this.hasGservisFamily;
    },
    isWienerberger() {
      return this.hasWienerbergerFamily;
    },
    ...mapMutations("configuration/household/people", [
      "setChildren",
      "setParents",
      "setGrandparents",
      "setOthers",
    ]),
    ...mapMutations("configuration/household", [
      "setUnlock",
      "setLivingStandard",
      "setBedrooms",
      "setChildrenRooms",
      "setBathrooms",
      "setBathroomsWithWC",
      "setToilets",
      "setKitchens",
      "setLivingRooms",
      //  "setGarageInHouse",
      //  "setParkingSlotsInGarage",
      "setFlag",
    ]),
  },
  data() {
    return {
      staticData: {
        flags: [
          {
            name: "Samostatná domácnost pro prarodiče",
            variableName: "separateHouseholdForGrandparents",
            advanced: false,
          },
          {
            name: "Bezbariérové bydlení",
            variableName: "barrierfreeHousing",
            advanced: true,
          },
          {
            name: "Kuchyně jako samostatná místnost",
            variableName: "closedKitchen",
            advanced: true,
          },
          {
            name: "Samostatná spíž",
            variableName: "pantry",
            advanced: true,
            counter: true,
          },
          {
            name: "Samostatná šatna",
            variableName: "wic",
            advanced: true,
            counter: true,
          },
          {
            name: "Samostatná technická místnost",
            variableName: "technicalRoom",
            advanced: false,
          },
          /*{
            name: "Samostatná koupelna u ložnice",
            variableName: "masterBathroom",
            advanced: true,
          },*/
          {
            name: "Samostatná pracovna",
            variableName: "study",
            advanced: true,
            counter: true,
          },
          {
            name: "Samostatný pokoj pro hosty",
            variableName: "guestRoom",
            advanced: true,
            counter: true,
          },
          /*{
            name: "Úložný prostor v garáži",
            variableName: "storageSpaceInGarage",
            advanced: true,
          },*/
          {
            name: "Úložný prostor pro zahradní vybavení",
            variableName: "storageForGardenEquipment",
            advanced: true,
            counter: true,
          },
          {
            name: "Vnitřní bazén",
            variableName: "indoorPool",
            advanced: true,
          },
          {
            name: "Vnitřní wellness",
            variableName: "indoorWellness",
            advanced: true,
          },
          {
            name: "Fitness",
            variableName: "fitness",
            advanced: true,
          },
          {
            name: "Zimní zahrada",
            variableName: "conservatory",
            advanced: true,
          },
        ],
        livingStandardOptions: [
          {
            name: "Úsporný",
            image: require("@/assets/image-holder.png"),
            value: "economy",
          },
          {
            name: "Standardní",
            image: require("@/assets/image-holder.png"),
            value: "standard",
          },
          {
            name: "Komfortní",
            image: require("@/assets/image-holder.png"),
            value: "comfort",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
.heading-row {
  justify-content: space-between;

  .tool-item {
    &.start-again {
      color: $primary-color;
      font-weight: bolder;
      cursor: pointer;
    }

    &.mode {
      line-height: 100%;
      color: #707070;
      vertical-align: middle;
      .setup {
        font-weight: 300;
        p {
          padding: 0 6px;
        }
      }
    }
  }
  .toggle-button {
    margin: auto 8px auto 8px;
  }
  .nav-item i,
  .tool-item i {
    font-size: 20px;
    margin: auto 5px;
  }
}

.section-controls {
  height: 123px;
  button.primary {
    float: right;
    margin: 20px 0;
  }
  button.secondary {
    float: left;
    margin: 20px 0;
  }
}
.section > .flex-inline {
  width: 100%;
  justify-content: space-between;
  //flex-wrap: wrap;
  h3 {
    flex: 1 1 20%;
  }
  /** multiple choice */

  .flex-row {
    flex: 0 0 50%;
    background: transparent;
    justify-content: space-between;
  }
}

.section {
  padding: 20px 0 13px 0;
  border-bottom: solid 2px #dadada;
  h3 {
    font-size: 1em;
    font-weight: 400;
    color: #000000de;
    > i {
      font-size: 21px;
      color: #737373;
      margin-left: 7px;
    }
  }

  i.fa.fa-undo.edited {
    padding-left: 10px;
    color: $primary-color;
  }

  .toggle-button {
    margin: auto 10px;
  }
  .counter,
  .world-side-picker,
  .land-location {
    margin: 0 10px;
  }

  .advanced {
    color: $primary-color;
    margin-left: 10px;
  }
  &.flag > .flex-inline {
    width: 100%;
    justify-content: space-between;
  }
}
</style>
