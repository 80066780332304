<template>
  <div class="multiple-choice flex-row">
    <div
      v-for="option in options"
      :class="{choice: true, 'flex-column': true, 'no-image': !option.image, active: this.modelValue.includes(option.value) }"
      @click="click(option.value)"
      :key="option.value"
      :data-class="option.value"
      ref="optionElements"
    >
      <img v-if="option.image" :src="option.image" :width="imgWidth" :height="imgHeight" />
      <p class="label text-center" v-text="option.name"></p>
      <div class="overlay">
        <i class="fas fa-check-circle"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    "single-answer": Boolean,
    "multiple-answer": Boolean,
    options: { type: Array, required: true },
    "img-width": { type: Number, default: 100 },
    "img-height": { type: Number, default: 100 },
    modelValue: { type: Array },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      output: [],
    };
  },
  methods: {
    click(option) {
      if (this.multipleAnswer) {
        this.toggle(option);
      } else if (this.singleAnswer) {
        this.choose(option);
      } else {
        console.warn(
          "multiple-choice without answer type specification 'multiple-answer' or 'single-answer'"
        );
        this.toggle(option);
      }
    },
    toggle(option) {
      let newArray;
      if (this.modelValue.some((item) => item === option)) {
        newArray = this.modelValue.filter((item) => item !== option);
      } else {
        newArray = [...this.modelValue, option];
      }
      this.$emit('update:modelValue', newArray);
    },
    choose(option) {
      const newArray = [option];
      this.$emit("update:modelValue", newArray);
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.updateActives();
      },
    },
  },
};
</script>

<style lang="scss">
.multiple-choice {
  > .choice {
    margin: 0 10px;
    position: relative;
    > img {
      margin: 0 auto;
    }
  }
  .overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($color: $primary-color, $alpha: 0.12);
    i {
      float: right;
      padding: 6px 6px;
      color: $primary-color;
      font-size: 1.5em;
    }
  }

  > .choice.active .overlay {
    display: block;
  }

  /* no image */
  > .choice.no-image {
    height: 32px;
    background: white;
    border-radius: 16px;
    border: 1px solid #00000033;

    &.active {
      border: 1px solid $primary-color;
      p {
        color: $primary-color;
      }
      background: #b111161f 0% 0% no-repeat padding-box;
    }

    p {
      white-space: nowrap;
      padding: 0 20px;
      font: 400 14px/19px Roboto;
      letter-spacing: 0.25px;
      line-height: 32px;
      margin: auto;
    }

    .overlay {
      border-radius: 16px;
      i {
        display: none;
      }
    }
  }
}
</style>