const cardStaticData = {
  tabs: [
    { value: "constructions", name: "Konstrukce" },
    { value: "hvac", name: "Technická řešení" },
    { value: "surroundings", name: "Okolí domu" },
  ],
  slotToCategory: {
    verticalConstructionTemplate: "verticalConstructionTemplate",
    slopingRoofSystemTemplate: "slopingRoofSystemTemplate",
    windowTemplate: "windowTemplate",
    windowShadingTemplate: "windowShadingTemplate",
    facadeTemplate: "facadeTemplate",
    horizontalConstructionTemplate: "horizontalConstructionTemplate",
    slopingRoofCoveringTemplate: "slopingRoofCoveringTemplate",
    flatRoofCoveringTemplate: "flatRoofCoveringTemplate",
    roofWindowTemplate: "roofWindowTemplate",
    heatingSystemTemplate: "heatingSystemTemplate",
    chimneyTemplate: "chimneyTemplate",
    ventilationSystemTemplate: "ventilationSystemTemplate",
    coolingSystemTemplate: "coolingSystemTemplate",
    photovoltaicSystemTemplate: "photovoltaicSystemTemplate",
    wastewaterHeatRecoveryTemplate: "wastewaterHeatRecoveryTemplate",
    chargingStationTemplate: "chargingStationTemplate",
    swimmingPoolPoolTemplate: "swimmingPoolPoolTemplate",
    fenceFrontTemplate: "fenceTemplate",
    fenceSideTemplate: "fenceTemplate",
    fenceRearTemplate: "fenceTemplate",
    fenceStreetTemplate: "fenceTemplate",
    fenceOtherTemplate: "fenceTemplate",
    gateTemplate: "gateTemplate",
    fenceDoorTemplate: "fenceDoorTemplate",
    pavedSurfacePavingTemplate: "pavedSurfacePavingTemplate",
    terraceTemplate: "pavedSurfacePavingTemplate",
    playgroundPlaygroundTemplate: "playgroundPlaygroundTemplate",
    gardenHouseGardenHouseTemplate: "gardenHouseGardenHouseTemplate",
    pergolaTemplate: "pergolaTemplate",
  },
  categoryToFilters: {
    verticalConstructionTemplate: [
      "houseType",
      "technicalSolution",
      "thermalInsulationLevel",
      "constructionSystem",
    ],
    slopingRoofSystemTemplate: [
      "houseType",
      "technicalSolution",
      "thermalInsulationLevel",
      "constructionSystem",
    ],
    windowTemplate: [
      "houseType",
      "technicalSolution",
      "thermalInsulationLevel",
      "windowMaterial",
    ],
    windowShadingTemplate: [
      "technicalSolution",
      "windowShading",
    ],
    facadeTemplate: [
      "houseType", 
      "technicalSolution", 
      "thermalInsulationLevel"
    ],
    horizontalConstructionTemplate: [
      "houseType", 
      "roofType",
      "technicalSolution",
      "thermalInsulationLevel",
      "constructionSystem",
    ],
    slopingRoofCoveringTemplate: [
      "houseType",
      "technicalSolution",
      "slopingRoofCoveringType",
      "roofSlope",
    ],
    flatRoofCoveringTemplate: [
      "houseType",
      "technicalSolution",
      "thermalInsulationLevel",
      "flatRoofCoveringType",
    ],
    roofWindowTemplate: [
      "houseType", 
      "technicalSolution", 
      "thermalInsulationLevel"
    ],
    /// HVAC
    heatingSystemTemplate: [
      "technicalSolution",
      "heatingPrimarySource",
      "heatingPrimarySourceType",
      "heatingMainEmitter",
    ],
    chimneyTemplate: [
      "houseType",
      "wienerbergerClass",
      "technicalSolution",
      "heatingPrimarySource",
      "heatingSecondarySource",
    ],
    ventilationSystemTemplate: ["technicalSolution"],
    coolingSystemTemplate: ["technicalSolution"],
    photovoltaicSystemTemplate: ["technicalSolution", "photovoltaicSystemType"],
    wastewaterHeatRecoveryTemplate: ["technicalSolution", "wastewaterHeatRecoveryType"],
    chargingStationTemplate: ["technicalSolution"],
    /// surroundings
    swimmingPoolPoolTemplate: ["wienerbergerClass", "swimmingPoolConstructionType"],
    fenceTemplate: ["wienerbergerClass","fenceConstructionType", "fenceFillingType"],
    gateTemplate: ["fenceConstructionType", "gateOpener"],
    fenceDoorTemplate: ["fenceConstructionType" ],
    pavedSurfacePavingTemplate: [
      "wienerbergerClass",
      "pavedSurfaceMaterial",
      "pavedSurfaceConcretePavingType",
    ],
    playgroundPlaygroundTemplate: ["wienerbergerClass","playgroundMaterial", "playgroundMaxNumberOfChildren"],
    gardenHouseGardenHouseTemplate: ["wienerbergerClass","gardenHouseMaterial", "gardenHouseSize"],
    pergolaTemplate: ["wienerbergerClass","pergolaMaterial"],
  },
  categoryToProducts: {
    verticalConstructionTemplate: [
      "externalWalls",
      "internalBearingWalls",
      "partition",
      "internalFinishPlaster",
      "internalFinishCladding",
    ],
    slopingRoofSystemTemplate: ["slopingRoofStructure"],
    windowTemplate: [
      "windowVertical", 
      "entranceDoor",
      "interiorDoors",
      "garageDoor",
    ],
    windowShadingTemplate: ["windowShading"],
    facadeTemplate: ["wallFinishExternal"],
    horizontalConstructionTemplate: [
      "groundFloor",
      "groundFloorWithFloorHeating",
      "slabInternalCeiling",
      "subfloorOnTheSlab",
      "subfloorOnTheSlabWithFloorHeating",
      "flatRoofConstruction",
      "floorCoveringDryOperation",
      "floorCoveringWetOperation",
      "foundationSlab",
      "dampProofLayer",
    ],
    slopingRoofCoveringTemplate: ["roofCoveringSlopingRoof"],
    flatRoofCoveringTemplate: ["flatRoofInsulatingLayers"],
    roofWindowTemplate: ["skylight"],
    /// HVAC
    heatingSystemTemplate: ["heatingSystem"],
    chimneyTemplate: ["chimney"],
    ventilationSystemTemplate: ["ventilationSystem"],
    coolingSystemTemplate: ["coolingSystem"],
    photovoltaicSystemTemplate: ["photovoltaicSystem"],
    wastewaterHeatRecoveryTemplate: ["wastewaterHeatRecovery"],
    chargingStationTemplate: ["chargingStation"],
    /// suroundings
    swimmingPoolPoolTemplate: ["swimmingPool"],
    fenceTemplate: ["fence"],
    gateTemplate: ["gate"],
    fenceDoorTemplate: ["fenceDoor"],
    pavedSurfacePavingTemplate: ["pavedSurfacePaving"],
    playgroundPlaygroundTemplate: ["playground"],
    gardenHouseGardenHouseTemplate: ["gardenHouse"],
    pergolaTemplate: ["pergola"],
  },
  productsToNames: {
    externalWalls: "Obvodové stěny",
    internalBearingWalls: "Vnitřní nosné stěny",
    partition: "Vnitřní nenosné stěny",
    internalFinishPlaster: "Vnitřní omítky",
    internalFinishCladding: "Vnitřní obklady",
    slopingRoofStructure: "Střešní konstrukce - šikmá střecha",
    windowVertical: "Okna - fasádní",
    windowShading: "Stínící technika",
    entranceDoor: "Dveře - vchodové",
    wallFinishExternal: "Fasádní systém",
    groundFloor: "Podlaha na terénu - bez podlahového vytápění",
    groundFloorWithFloorHeating: "Podlaha na terénu - s podlahovým vytápěním",
    slabInternalCeiling: "Vnitřní strop",
    subfloorOnTheSlab: "Hrubá podlaha - bez podlahového vytápění",
    subfloorOnTheSlabWithFloorHeating: "Hrubá podlaha - s podlahovým vytápěním",
    flatRoofConstruction: "Nosná konstrukce ploché střechy",
    floorCoveringDryOperation: "Nášlapná vrstva - suché provozy",
    floorCoveringWetOperation: "Nášlapná vrstva - mokré provozy",
    roofCoveringSlopingRoof: "Střešní krytina - šikmá střecha",
    flatRoofInsulatingLayers: "Izolační souvrství ploché střechy",
    skylight: "Okna - střešní",
    foundationSlab: "Základová deska",
    dampProofLayer: "Hydroizolace podlahy na terénu",
    interiorDoors: "Interiérové dveře",
    garageDoor: "Garážová vrata",
    /// hvac
    heatingSystem: "systém výtápění a přípravy teplé vody",
    chimney: "komín",
    ventilationSystem: "systém nuceného větrání",
    coolingSystem: "systém chlazení",
    photovoltaicSystem: "fotovoltaický systém",
    wastewaterHeatRecovery: "rekuperace tepla z odpadní vody.",
    chargingStation: "dobíjecí stanice",
    /// suroundings
    swimmingPool: "bazén",
    fence: "oplocení",
    gate: "brána",
    fenceDoor: "branka",
    pavedSurfacePaving: "zpěvněná plocha",
    playground: "dětské hřiště",
    gardenHouse: "zahradní domek",
    pergola: "pergola",
  },
  categories: {
    constructions: [
      {
        value: "verticalConstructionTemplate",
        name: "svislých konstrukcí",
      },
      {
        value: "slopingRoofSystemTemplate",
        name: "střešní konstrukce - šikmá střecha",
      },
      {
        value: "windowTemplate",
        name: "výplní otvorů - fasádní",
      },
      {
        value: "windowShadingTemplate",
        name: "stínící technika",
      },
      {
        value: "facadeTemplate",
        name: "typu fasády",
      },
      {
        value: "horizontalConstructionTemplate",
        name: "vodorovných konstrukcí (včetně nosné konstrukce ploché střechy)",
      },
      {
        value: "slopingRoofCoveringTemplate",
        name: "střešní krytiny - šikmá střecha",
      },
      {
        value: "flatRoofCoveringTemplate",
        name: "izolačního souvrství ploché střechy",
      },
      {
        value: "roofWindowTemplate",
        name: "výplní otvorů - střešní",
      },
    ],
    hvac: [
      {
        value: "heatingSystemTemplate",
        name: "systém vytápění a přípravy teplé vody",
      },
      {
        value: "chimneyTemplate",
        name: "komín",
      },
      {
        value: "ventilationSystemTemplate",
        name: "systém nuceného větrání",
      },
      {
        value: "coolingSystemTemplate",
        name: "systém chlazení",
      },
      {
        value: "photovoltaicSystemTemplate",
        name: "fotovoltaický systém",
      },
      {
        value: "wastewaterHeatRecoveryTemplate",
        name: "rekuperace tepla z odpadní vody",
      },
      {
        value: "chargingStationTemplate",
        name: "dobíjecí stanice",
      }
    ],
    surroundings: [
      {
        value: "swimmingPoolPoolTemplate",
        name: "bazén",
      },
      {
        value: "fenceTemplate",
        name: "plot",
      },
      {
        value: "gateTemplate",
        name: "brána",
      },
      {
        value: "fenceDoorTemplate",
        name: "branka",
      },
      {
        value: "pavedSurfacePavingTemplate",
        name: "zpevněná plocha",
      },
      {
        value: "playgroundPlaygroundTemplate",
        name: "dětské hřiště",
      },
      {
        value: "gardenHouseGardenHouseTemplate",
        name: "zahradní domek",
      },
      {
        value: "pergolaTemplate",
        name: "pergola",
      },
    ],
  },
  manufacturers: [
    "",
    "DEK",
    "Wienerberger",
    "Weber",
    "Xella",
    "Heluz",
    "Baumit",
    "Vekra",
    "Velux",
    "Tondach",
    "Semmelrock",
    'KM Beta',
    'Isover',
    'Deksolar',
    'Albixon',
    'Relax bazény',
    'Berndorf baderbau',
    'Best',
    'DAMIPWC',
    'České ploty',
    'Prima',
    'Pantanet',
    'Topstone',
    'Marimex',
    'Palis Gym',
    'Step2',
    'Hřiště Piccolino',
    'Zahradní domky.cz',
    'Ligure',
    'Dekwood',
    'Sunfurl',
    'Tendabox',
    'Topenářství Dům kotlů',
    'Nibe',
    'Regulus',
    'Schiedel',
    'Acond',
    'Protherm',
    'Enbra',
    'Daikin',
  ],
};

export default cardStaticData;
