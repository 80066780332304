<template>
  <div class="subresult-rooms">
    <h5 v-text="getText('totalFloorArea', [house.totalArea])"></h5>

    <div v-for="(floor, index) in house.storeys" :key="index" class="storey">
      <div class="flex-inline">
        <i class="fa fa-caret-down"></i>
        <h5>
          <i 
            class="fa fa-undo locked" 
            @click="unlockStorey(floor)"
            v-show="floor.lock"
          ></i>
          {{ getText(floor.identifier) }} ({{getText('floorArea')}}
          {{ storeyArea(floor) }}
          m<sup>2</sup>)
        </h5>
      </div>
      <div class="room-list" 
          :class="{playdown: havingMoveFrom(floor), highlight: havingMoveTo(floor)}" @dragover="allowDrop" 
          @drop="dropRoom($event, floor.identifier)"
      >
        <div class="room-item heading flex-row">
          <p class="room-no" v-text="getText('roomtable.number')"></p>
          <p class="room-list-header" v-text="getText('roomtable.name')"></p>
          <p class="room-list-header" v-text="getText('roomtable.area')"></p>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div
          v-for="room in floor.rooms"
          class="room-item flex-row"
          :class="{dragging: beingDragged(room)}"
          :key="room.id"
          draggable="true"
          @dragstart="dragRoom($event, room, floor.identifier)"
        >
          <p v-text="room.id" class="room-no"></p>
          <div class="flex-inline parameters">
            <input :value="getText(room.identifier)" type="text" readonly/>
            <!--input :value="floor.name" type="text" readonly/-->
            <div class="input-group ">
              <input
                :value="room.area"
                @change="onEdit(room, $event)"
                :class="{ edited: room.lock }"
                type="number"
                step="0.001"
                class="group"
                :readonly="!editable(room)"
              />

              <span class="input-group-text">
                m
                <sup>2</sup>
              </span>
            </div>

            <i
              v-if="room.lock"
              @click="redo(room)"
              class="fa fa-undo enabled edited"
            ></i>
            <i v-else @click="startEdit($event)" class="fa fa-edit" :class="{enabled: editable(room)}"></i>
            <i
              @click="remove(room)"
              :class="{
                enabled: removable(room),
              }"
              class="fa fa-trash-alt"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <!-- DETACHED GARAGE -->
    <h5 
      v-if="detachedGarage.hasGarage" 
      v-text="getText('detachedGarage.totalArea', [detachedGarage.totalArea])"
    >
    </h5>

    <div
      v-for="(floor, index) in detachedGarage.storeys"
      :key="index + 'garage'"
      class="storey"
    >
      <div class="flex-inline">
        <i class="fa fa-caret-down"></i>
        
        <h5>
          {{ getText(floor.identifier) }} ({{getText('floorArea')}}
          {{ storeyArea(floor) }}
          m<sup>2</sup>)
        </h5>
      </div>
      <div class="room-list">
        <div class="room-item heading flex-row">
          <p class="room-no" v-text="getText('roomtable.number')"></p>
          <p class="room-list-header" v-text="getText('roomtable.name')"></p>
          <p class="room-list-header" v-text="getText('roomtable.area')"></p>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div
          v-for="room in floor.rooms"
          class="room-item flex-row"
          :key="room.id"
        >
          <p v-text="room.id" class="room-no"></p>
          <div class="flex-inline parameters">
            <input :value="getText(room.identifier)" type="text" readonly/>
            <div class="input-group">
              <input
                :value="room.area"
                @input="onGarageEdit(room, $event)"
                :class="{ edited: room.lock }"
                type="number"
                step="0.001"
                class="group"
                :readonly="!editable(room)"
              />

              <span class="input-group-text">
                m
                <sup>2</sup>
              </span>
            </div>

            <i
              v-if="room.lock"
              @click="redoGarage(room)"
              class="fa fa-undo enabled edited"
            ></i>
            <i v-else @click="startEdit($event)" :class="{enabled: editable(room)}" class="fa fa-edit" ></i>
            <i
              @click="removeGarage(room)"
              :class="{
                enabled: removable(room),
              }"
              class="fa fa-trash-alt"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
mapGetters;
export default {
  computed: {
    ...mapGetters(["getRoomData", "getText"]),
    ...mapGetters("configuration/buildings", [
      "house",
      "hasGarage",
      "detachedGarage",
    ]),
  },
  methods: {
    havingMoveFrom(storey) {
      return this.drag.dragging && this.drag.dragFrom === storey.identifier;
    },
    havingMoveTo(storey) {
      return this.drag.dragging && this.drag.dragFrom !== storey.identifier;
    },
    beingDragged(room) {
      return this.drag.dragging && this.drag.dragRoomId === room.id;
    },
    allowDrop(e) {
      //console.log("allowDrop");
      e.preventDefault();
    },
    dragRoom(event, room, currentStorey) {
      this.drag.dragging = true;
      this.drag.dragFrom = currentStorey;
      this.drag.dragRoomId = room.id;
      const payload = {
        room,
        currentStorey
      };
      //console.log("drag", room.id, currentStorey);
      event.dataTransfer.setData("text/plain", JSON.stringify(payload));
    },
    dropRoom(event, targetStorey) {
      this.drag.dragging = false;
      console.log("drop");
      event.preventDefault();
      console.log(...event.dataTransfer.getData("moveAction"))
      const {room, currentStorey} = JSON.parse(event.dataTransfer.getData("text/plain"));
      if (currentStorey === targetStorey)  return; 
      //console.log('Drag end', room.id, "to", targetStorey);
      this.$store.commit("configuration/buildings/moveRoom", {room, target: targetStorey});
    },
    storeyArea(storey) {
      const total = storey.rooms.reduce((acc, item) => acc + item.area, 0);
      return Math.round(total) == total ? total : total.toFixed(1);
    },
    startEdit(event) {
      if (event.target 
       && event.target.parentElement) {
        event.target.parentElement.querySelector(".input-group > input").focus()
      }
    },
    editable (room) {
      const data = this.getRoomData(room.identifier)
      if (data) return data.editable;
      return false;
    },
    removable(room) {
      const data = this.getRoomData(room.identifier);
      if (data) return data.removable;
      return false;
    },
    remove(room) {
      if (this.removable(room)) {
        this.$store.commit("configuration/buildings/removeRoom", room);
      }
    },
    removeGarage(room) {
      if (this.removable(room)) {
        this.$store.commit("configuration/buildings/removeGarageRoom", room);
      }
    },
    onEdit(room, event) {
      if (this.editable(room)) {
        this.editRoom({ room, value: Number(event.target.value) });
      }
    },
    onGarageEdit(room, event) {
      if (this.editable(room)) {
        this.editGarageRoom({ room, value: Number(event.target.value) });
      }
    },
    redo(room) {
      this.redoRoom(room);
    },
    redoGarage(room) {
      this.redoGarageRoom(room);
    },
    ...mapMutations("configuration/buildings", [
      "editRoom",
      "redoRoom",
      "editGarageRoom",
      "redoGarageRoom",
      "unlockStorey",
    ]),
  },
  data() {
    return {
      drag: {
        dragging: false,
        dragFrom: "",
        dragRoomId: "",
      }
    };
  },
};
</script>

<style lang="scss">
$input-widh: 200px;
$input-height: 40px;
$input-padding-left: 12px;

.subresult-rooms {
  h5 {
    margin: 10px auto;
    font-size: 16px;
    letter-spacing: 0.15px;
  }
  .storey {
    .room-list {
      &.highlight {
        border: 5px solid $primary-color;
        //background: pink;
      }
      &.playdown {
        background: lighten($color: #000000, $amount: 99%);
      }
    }
    .room-item:not(.heading) {
      cursor: grab;
      &.dragging {
        border-left: 5px solid $primary-color;
        cursor: ns-resize;
        //background-color: pink;
      }
    }

    i.fa.fa-undo {
      color: $primary-color;
      cursor: pointer;
    }
    
    .room-no {
      margin: auto 0 auto 0;
      flex-basis: 120px;
      flex-shrink: 1;
    }
    .roomarea {
      width: 30px;
    }
    input.edited {
      color: $primary-color;
    }
    i {
      font-size: 20px;
      margin: auto 10px;
      &.enabled {
        cursor: pointer;
        color: #707070;
        &:hover {
          color: $secondary-color;
        }
        &.edited {
          color: $primary-color;
        }
      }
      color: #70707050;
    }
    
    .room-item.heading {
      border-bottom: solid 1px #00000033;
      .room-list-header {
        width: 215px;
        text-align: center;
      }
    }
    .grid {
      display: grid;
      grid-template-columns: 3fr 4fr 4fr 40px 40px 5fr;
    }
  }
}

.subresult-rooms {
  input {
    outline: none;
    border: none;
    border-radius: 3px;
    height: 40px;
    padding: 0;
  }

  input:not(.group) {
    width: $input-widh;
  }

  .input-group,
  input:not(.group) {
    border: $secondary-color solid 1px;
    margin: 10px;
    width: $input-widh;
    padding-left: $input-padding-left;
  }
  input.group {
    width: $input-widh - $input-height;
  }
  .input-group {
    border: $secondary-color solid 1px;
    border-radius: 4px;
    //border: auto;
    position: relative;
    display: flex;
    &:focus {
      outline: none;
      border: none;
    }
  }

  .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    // border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}
</style>
