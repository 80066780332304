<template>
  <div class="brief-preview">
    <div class="nav flex-row">
      <h1 class="nav-item project-name" v-text="getText('projectNamePlaceholder')"></h1>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      housePreview: require("../../assets/results/house-preview.png"),
    };
  },
  computed: {
    ...mapGetters(["getText"])
  }
};
</script>

<style lang="scss" scoped>
//@import "../../scss/_functions.scss";
.brief-preview {
  padding: 24px 0;
  .nav {
    align-items: flex-end;
    .nav-item:first-child {
      margin: 0 10px 0 0;
    }
    .nav-item {
      margin: 0 10px 0 10px;
    }
    button.primary {
      width: 280px;
    }
  }
}
</style>
