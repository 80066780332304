<template>
  <div id="app">
    <div v-if="shouldOpenApplication()">
      <div class="dialog-emitter">
        <WelcomeDialog v-if="shouldPopupWelcome()"></WelcomeDialog>      
      </div>
      <div class="top">
        <TopNavbar />
        <ProcessNavbar />
      </div>
      <div class="slider-for-one-column no-scrollbar">
        <div class="layout flex-row">
          <div class="column configuration">
            <div class="wrapper-for-overlay no-scrollbar">
              <LoadingComponent v-if="loadingContent" />
              <router-view v-else class="config-panel" />
              <div class="overlay-configuration"></div>
            </div>
          </div>
          <div class="column result no-scrollbar">
            <LoadingComponent v-if="loadingContent" />
            <ResultPanel v-else />
          </div>
        </div>
      </div>

    </div>

    <LoadEntrance v-else-if="shouldUseLoadComponent()" />
    
    <LoginScreen v-else />

  </div>
</template>

<script>
import TopNavbar from "./views//TopNavbar";
import ProcessNavbar from "./views/ProcessNavbar";
import LoginScreen from "./views/LoginScreen.vue";
import LoadingComponent from "./views/LoadingComponent";
//import Entrance from "./views/Entrance";
import ResultPanel from "./views/result/ResultPanel";
import WelcomeDialog from "./views/configuration/WelcomeDialog";
import { mapGetters, mapMutations } from 'vuex';
import LoadEntrance from './views/LoadEntrance.vue';


export default {
  name: "app",
  components: {
    TopNavbar,
    ProcessNavbar,
    LoginScreen,
    //Entrance,
    ResultPanel,
    WelcomeDialog,
    LoadingComponent,
    LoadEntrance,
  },
  created() {
    // look if browser knows
    if (localStorage.getItem("refreshToken")) {
      this.setRefreshToken(localStorage.getItem("refreshToken"));
      this.setAccessToken(localStorage.getItem("accessToken"));
      if (!this.shouldUseLoadComponent()) {
        this.setOwner(localStorage.getItem("configuratorOwner"));
        this.$store.dispatch("LOAD_STATIC_DATA");
      }
    }
  },
  methods: {
    ...mapMutations(['setRefreshToken', 'setAccessToken', 'setOwner']),
    shouldOpenApplication() {
      return this.xApiKey;
    },
    shouldUseLoadComponent() {
       return this.$route.matched.some((route) => {
         console.log(route.name)
          return route.name === "LoadVisitor" || route.name === "Load";
      });
    },
    shouldPopupWelcome() {
       return this.$route.matched.some((route) => {
         console.log(route.name)
          return ['household'].includes(route.name.toLowerCase());
      });
    }
  },
  computed: {
    isEntrance() {
      // This works:
      // return this.$route.name === "entrance";
      // But this might be better way:
      return this.$route.matched.some((route) => {
        return route.name === "entrance";
      });
    },
    ...mapGetters(['xApiKey', 'loadingContent'])
  },
};
</script>

<style lang="scss">
@import "@/scss/_base.scss";

html,
body {
  font-family: "Roboto", sans-serif;
  font-weight: light;
  font-size: 14px;
  //line-height: 19;
  //letter-spacing: 9.6;
  margin: 0;
  //height: 100%;
  //overflow: hidden;
}

.layout {
  .column {
    //height: calc(100vh - 80px);

    box-sizing: border-box;

    &.configuration {
      position: relative;

      .wrapper-for-overlay {
        background: #f2f2f2;
        overflow: auto;
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .config-panel {
          padding: 20px;
        }
        .overlay-configuration {
          top: 0;
          bottom: 0;
          position: absolute;
          background: transparent;
          pointer-events: none;
          width: 100%;
          height: 100%;
          z-index: 10;
          box-shadow: -5px 20px 20px 0px rgba($color: #000000, $alpha: 0.14)
            inset;
        }
      }
    }

    &.result {
      overflow-y: auto;
      background: white;
    }
  }
}

.config-panel {
  h1.title {
    font: 500 20px/26px Roboto;
    letter-spacing: 0.26px;
    margin-top: 17px;
    color: #000000de;
  }
  p.description {
    font: 300 14px/19px Roboto;
    letter-spacing: 0.13px;
    color: #00000099;
  }
}
</style>
