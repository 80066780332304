<template>
  <div class="card-view">
    <div class="filtration">
      <h2>FILTRACE KARET</h2>
      <div class="flex-column">
        <label>záložka</label>
        <select v-model="filter.superCategory">
          <option
            v-for="tab in staticData.tabs"
            :value="tab.value"
            :key="tab.value"
            v-text="tab.name"
          ></option>
        </select>
      </div>

      <div class="flex-column">
        <label>kategorie</label>
        <select v-model="filter.category">
          <option
            v-for="category in staticData.categories[filter.superCategory]"
            :value="category.value"
            :key="category.value"
            v-text="category.name"
          ></option>
        </select>
      </div>

      <MySection name="Wienerbergerovská třída" v-show="isWienerberger()">
        <MultipleChoice
          single-answer
          :options="staticData.wienerbergerClassOptions"
          v-model="filter.meta.wienerbergerClass"
        />
      </MySection>

      <MySection name="Typ domu" v-show="isActive('houseType')">
        <MultipleChoice
          single-answer
          :options="staticData.houseTypeOptions"
          v-model="filter.meta.houseType"
        />
      </MySection>

      <MySection name="Technické řešení" v-show="isActive('technicalSolution')">
        <MultipleChoice
          single-answer
          :options="staticData.technicalSolutionOptions"
          v-model="filter.meta.technicalSolution"
        />
      </MySection>

      <MySection
        name="Úroveň tepelné izolace konstrukcí"
        v-show="isActive('thermalInsulationLevel')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.thermalInsulationLevelOptions"
          v-model="filter.meta.thermalInsulationLevel"
        />
      </MySection>

      <MySection
        name="Konstrukční systém"
        v-show="isActive('constructionSystem')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.constructionSystemOptions"
          v-model="filter.meta.constructionSystem"
        />
      </MySection>

      <MySection name="Materiál oken" v-show="isActive('windowMaterial')">
        <MultipleChoice
          single-answer
          :options="staticData.windowMaterialOptions"
          v-model="filter.meta.windowMaterial"
        />
      </MySection>

      <MySection name="Typ stínící techniky" v-show="isActive('windowShading')">
        <MultipleChoice
          single-answer
          :options="staticData.windowShadingOptions"
          v-model="filter.meta.windowShading"
        />
      </MySection>

      <MySection name="Tvar střechy" v-show="isActive('roofType')">
        <MultipleChoice
          single-answer
          :options="staticData.roofTypeOptions"
          v-model="filter.meta.roofType"
        />
      </MySection>

      <MySection
        name="Střešní krytina-šikmá střecha"
        v-show="isActive('slopingRoofCoveringType')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.slopingRoofCoveringTypeOptions"
          v-model="filter.meta.slopingRoofCoveringType"
        />
      </MySection>

      <MySection
        name="Střešní krytina-plochá střecha"
        v-show="isActive('flatRoofCoveringType')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.flatRoofCoveringTypeOptions"
          v-model="filter.meta.flatRoofCoveringType"
        />
      </MySection>

      <MySection name="Sklon střechy" v-show="isActive('roofSlope')">
        <MultipleChoice
          single-answer
          :options="staticData.roofSlopeOptions"
          v-model="filter.meta.roofSlope"
        />
      </MySection>

      <!--  HVAC Filters  -->

      <MySection name="Primární zdroj" v-show="isActive('heatingPrimarySource')">
        <MultipleChoice
          single-answer
          :options="staticData.heatingPrimarySourceOptions"
          v-model="filter.meta.heatingPrimarySource"
        />
      </MySection>

      <MySection
        v-if="isGservis()"
        name="Typ primárního zdroje (pro zroj elektřina)"
        v-show="isActive('heatingPrimarySourceType')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.heatingPrimarySourceTypeOptions"
          v-model="filter.meta.heatingPrimarySourceType"
        />
      </MySection>

      <MySection
        v-else
        name="Typ primárního zdroje (pro zroj elektřina)"
        v-show="isActive('heatingPrimarySourceType')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.heatingPrimarySourceTypeOptionsWienerberger"
          v-model="filter.meta.heatingPrimarySourceType"
        />
      </MySection>

      <MySection
        v-if="isGservis()"
        name="Převažující otopná tělesa"
        v-show="isActive('heatingMainEmitter')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.heatingMainEmitterOptions"
          v-model="filter.meta.heatingMainEmitter"
        />
      </MySection>

      <MySection
        v-else
        name="Převažující otopná tělesa"
        v-show="isActive('heatingMainEmitter')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.heatingMainEmitterOptionsWienerberger"
          v-model="filter.meta.heatingMainEmitter"
        />
      </MySection>

      <MySection
        name="Sekundární zdroj"
        v-show="isActive('heatingSecondarySource')"
      >
        <MultipleChoice
          multiple-answer
          :options="staticData.heatingSecondarySourceOptions"
          v-model="filter.meta.heatingSecondarySource"
          @input.capture="clearErrors()"
        />
      </MySection>

      <MySection
        name="Typ fotovoltaického systému"
        v-show="isActive('photovoltaicSystemType')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.photovoltaicSystemTypeOptions"
          v-model="filter.meta.photovoltaicSystemType"
        />
      </MySection>

      <MySection
        name="Typ fotovoltaického systému"
        v-show="isActive('wastewaterHeatRecoveryType')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.wastewaterHeatRecoveryTypeOptions"
          v-model="filter.meta.wastewaterHeatRecoveryType"
        />
      </MySection>

      <!-- Surroundings -->

      <MySection
        name="Typ konstrukce bazénu"
        v-show="isActive('swimmingPoolConstructionType')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.swimmingPoolConstructionTypeOptions"
          v-model="filter.meta.swimmingPoolConstructionType"
        />
      </MySection>

      <MySection
        name="Typ konstrukce oplocení"
        v-show="isActive('fenceConstructionType')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.fenceConstructionTypeOptions"
          v-model="filter.meta.fenceConstructionType"
        />
      </MySection>

      <MySection
        name="Typ výplně oplocení"
        tab="surroundings"
        v-show="
          isActive('fenceFillingType') &&
            filter.meta.fenceConstructionType.includes('combined wall fence')
        "
      >
        <MultipleChoice
          :options="staticData.fenceFillingTypeOptions"
          v-model="filter.meta.fenceFillingType"
        />
      </MySection>

      <MySection
        name="Způsob otevírání"
        tab="surroundings"
        v-show="isActive('gateOpener')"
      >
        <MultipleChoice
          :options="staticData.gateOpenerOptions"
          v-model="filter.meta.gateOpener"
        />
      </MySection>

      <MySection
        name="Materiál zpevněných ploch"
        v-show="isActive('pavedSurfaceMaterial')"
        v-if="isGservis()"
      >
        <MultipleChoice
          single-answer
          :options="staticData.pavedSurfaceMaterialOptions"
          v-model="filter.meta.pavedSurfaceMaterial"
        />
      </MySection>

      <MySection
        name="Materiál zpevněných ploch"
        v-show="isActive('pavedSurfaceMaterial')"
        v-else
      >
        <MultipleChoice
          single-answer
          :options="staticData.pavedSurfaceMaterialOldOptions"
          v-model="filter.meta.pavedSurfaceMaterial"
        />
      </MySection>

      <MySection
        name="Typ zpevněných beronových ploch"
        v-show="isActive('pavedSurfaceConcretePavingType')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.pavedSurfaceConcretePavingTypeOptions"
          v-model="filter.meta.pavedSurfaceConcretePavingType"
        />
      </MySection>

      <MySection
        name="Materiál dětského hřiště"
        v-show="isActive('playgroundMaterial')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.playgroundMaterialOptions"
          v-model="filter.meta.playgroundMaterial"
        />
      </MySection>

      <MySection
          name="Maximální počet dětí"
          v-show="isActive('playgroundMaxNumberOfChildren')"
        >
          <MultipleChoice
            single-answer
            :options="staticData.playgroundMaxNumberOfChildrenOptions"
            v-model="filter.meta.playgroundMaxNumberOfChildren"
          />
      </MySection>

      <MySection
        name="Materál zahradního domku"
        v-show="isActive('gardenHouseMaterial')"
      >
        <MultipleChoice
          single-answer
          :options="staticData.gardenHouseMaterialOptions"
          v-model="filter.meta.gardenHouseMaterial"
        />
      </MySection>

      <MySection
        name="Velikost zahradního domku"
        v-show="isActive('gardenHouseSize')"
      >
        <MultipleChoice
          multiple-answer
          :options="staticData.gardenHouseSizeOptions"
          v-model="filter.meta.gardenHouseSize"
        />
      </MySection>

      <MySection name="Materíál pergoly" v-show="isActive('pergolaMaterial')">
        <MultipleChoice
          single-answer
          :options="staticData.pergolaMaterialOptions"
          v-model="filter.meta.pergolaMaterial"
        />
      </MySection>
    </div>

    <div class="preview">
      <h2>
        NALEZENÉ KARTY ODPOVÍDAJÍCÍ FILTRACI
        <i @click="refresh" class="refresh fa fa-sync-alt"></i>
      </h2>
      <div class="container flex-row">
        <div
          v-for="card in cards.filter(myFilter)"
          :key="card._id"
          class="card"
        >
          <div class="buttons">
            <i class="fa fa-edit" @click="onEdit(card)"></i>
            <i class="fa fa-copy" @click="onDuplicate(card)"></i>
            <i class="fa fa-trash" @click="onRemove(card)"></i>
            <!-- <i class="fa fa-edit"></i> -->
          </div>
          <img :src="card.image" />
          <div class="textual-content">
            <h1 v-text="card.name"></h1>
            <div v-show="card.manufacturer">
              <strong>Hlavní dodavatel: </strong> {{ card.manufacturer }}
            </div>
            <p v-text="card.brief"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MySection from "../../components/MySection.vue";
import { MultipleChoice } from "../../components/customElements";
import api from "../../../store/api";
import cardStaticData from "./cardStaticData";
import filterOptions from "./filterOptions";

export default {
  components: {
    MySection,
    MultipleChoice,
  },
  data() {
    return {
      staticData: {
        ...cardStaticData,
        ...filterOptions,
      },
      filter: {
        superCategory: "constructions",
        category: "verticalConstructionTemplate",
        meta: {
          wienerbergerClass: "",
          houseType: "",
          technicalSolution: "",
          thermalInsulationLevel: "",
          constructionSystem: "",
          windowMaterial: "",
          roofType: "",
          slopingRoofCoveringType: "",
          flatRoofCoveringType: "",
          roofSlope: "",
          // hvac
          heatingPrimarySource: "",
          heatingPrimarySourceType: "",
          heatingMainEmitter: "",
          heatingSecondarySource: "",
          photovoltaicSystemType: "",
          wastewaterHeatRecoveryType: "",
          // surroundings
          swimmingPoolConstructionType: "",
          fenceConstructionType: "",
          fenceFillingType: "",
          pavedSurfaceMaterial: "",
          pavedSurfaceConcretePavingType: "",
          playgroundMaterial: "",
          playgroundMaxNumberOfChildren: "",
          gardenHouseMaterial: "",
          pergolaMaterial: "",
        },
      },
      loading: false,
    };
  },
  methods: {
    isGservis() {
      return this.hasGservisFamily;
    },
    isWienerberger() {
      return this.hasWienerbergerFamily;
    },
    myFilter(card) {
      const filter = this.filter;
      if (
        card.superCategory !== filter.superCategory ||
        card.category !== filter.category
      ) {
        return false;
      }
      //console.log(Object.keys(this.filter.meta));
      const vector = Object.keys(this.filter.meta).map((criterion) => {
        //console.log("fil", filter.meta[criterion]);
        //console.log(card.meta);
        //if (this.isActive(criterion) && card.meta)
        //  console.log("card", card.meta[criterion]);
        return !(
          this.isActive(criterion) &&
          filter.meta[criterion] !== "" &&
          !card.meta[criterion].includes("") &&
          !card.meta[criterion].includes(filter.meta[criterion])
        );
        //return true;
      });
      //console.log(vector);
      return vector.reduce((acc, b) => acc && b, true);
    },
    isActive(filter) {
      //console.log(this.filter.category);
      //console.log(this.staticData.categoryToFilters[this.filter.category]);
      return this.staticData.categoryToFilters[this.filter.category].includes(
        filter
      );
    },
    onRemove(item) {
      api.post("/api/cards/remove-card", item).then((response) => {
        this.submiting = false;
        console.log("sent", response);
        this.$store.dispatch("cards/loadAllCards");
      });
    },
    onEdit(item) {
      this.$emit("edit", item);
    },
    onDuplicate(item) {
      api.post("/api/cards/duplicate-card", item).then((response) => {
        this.submiting = false;
        console.log("sent", response);
        this.$store.dispatch("cards/loadAllCards");
      });
    },
    refresh() {
      this.$store.dispatch("cards/loadAllCards");
    },
  },
  computed: {
    ...mapGetters("cards", ["cards"]),
    ...mapGetters([
      "hasWienerbergerFamily",
      "hasGservisFamily",
    ]),
  },
};
</script>

<style lang="scss">
.card-view {
  .filtration {
    padding: 12pt 12pt 0pt 12pt;
    //background: #f8f8f8;
    border: 1px solid lightgrey;
  }
  .preview {
    padding: 12pt;
    background: white;
    .refresh {
      color: $primary-color;
      cursor: pointer;
      margin: 10px;
    }
  }
  .container {
    min-height: 100pt;
    //border: 2px solid grey;
    margin: 10pt 0;
    padding: 20pt;
  }
  .container {
    flex-wrap: wrap;
    padding: 10px 0;
    img {
      width: 290px;
      height: 220px;
    }
    .card {
      position: relative;
      margin-right: 6px;
      background: white;
      .buttons {
        color: lighten($primary-color, 20%);
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 99;
        i {
          padding: 3px;
          font-size: 1.4em;
          cursor: pointer;
          &:hover {
            color: $primary-color;
          }
        }
      }
      .textual-content {
        padding: 10px;
      }
      //border: solid 2px lighten($primary-color, 40%);
      border: solid 1px lighten(#000, 40%);
    }
  }
}
</style>
