const { updateWhatever } = require("../utils");
const architecture = {
  namespaced: true,
  state: () => ({
    //houseShape: ["rectangle", "L shape"],
    houseType: "bungalow",
    roofType: "sloping",
    roofSlope: "low",
    glazingSize: "standard",
    buildingStyleLike: "",
    flags: {
      basement: false,
      occupiedAttic: false,
    },
    parking: {
      counters: {
        openRoofParkingSpace: 0,
        carport: 2,
        attachedGarage: 0,
        detachedGarage: 0,
      },
      storageSpaceInGarage: false,
    },
  }),
  getters: {
    //houseShape: (state) => state.houseShape,
    houseType: (state) => state.houseType,
    parkingCounters: (state) => state.parking.counters,
    flags: (state) => state.flags,
    storageSpaceInGarage: (state) => state.parking.storageSpaceInGarage,
    buildingStyleLike: (state) => state.buildingStyleLike,
    roofType: (state) => state.roofType,
    roofSlope: (state) => state.roofSlope,
    glazingSize: (state) => state.glazingSize,
  },
  mutations: {
    set(state, newState) {
      state = updateWhatever(state, newState);
    },
    /*setHouseShape(state, val) {
      state.houseShape = val;
    },*/
    setHouseType(state, val) {
      state.houseType = val;
    },

    setParkingCounter(state, { variableName, val }) {
      state.parking.counters[variableName] = val;
    },

    setStorageSpaceInGarage(state, val) {
      state.parking.storageSpaceInGarage = val;
    },

    setBuildingStyleLike(state, val) {
      state.buildingStyleLike = val;
    },
    setRoofType(state, val) {
      state.roofType = val;
    },
    setRoofSlope(state, val) {
      state.roofSlope = val;
    },
    setGlazingSize(state, val) {
      state.glazingSize = val;
    },
    setFlag(state, { variableName, val }) {
      state.flags[variableName] = val;
    },
  },
  actions: {},
};

export default architecture;
