<template>
  <div class="texts">

    <h1 class="title">Akce</h1>
    <div class="flex-column">
      <div class="flex-row">
        <button class="primary" @click="onImportTexts()">Importovat texty do API.</button>
        <p style="padding-left: 20px">Načte texty z online tabulek a vloží je do databáze API. Tato funkce přepíše všechny úpravy provedené v administraci.</p>
      </div>
      <div class="flex-row">
        <button class="primary" @click="onTextTest()">Zobrazit testovací texty.</button>
        <p style="padding-left: 20px">Místo obsahu textů, vloží jejích ID.</p>
      </div>
    </div>

    <h1 class="title">Úprava textů</h1>
    <h4>FILTRACE</h4>
    <strong>Záložka:</strong>
    <div class="flex-row">
      <button 
        v-for="tab in data.filter(text => text.tags.includes('tab'))" 
        @click="setTab(tab.id)" 
        v-text="tab.text" 
        :class="{
          active: isTabActive(tab.id)
        }"
        class="filter-button"
        :key="tab.id"
      ></button>
      <button 
        @click="setTab('')" 
        v-text="'vše'" 
        :class="{
          active: isTabActive('')
        }"
        class="filter-button"   
      ></button>
    </div>
    <div class="flex-row">
      <strong>Tagy:</strong>
      <span v-for="tag in filter.tags" :key="tag">{{tag}} <button @click="removeTag(tag)">x</button></span>
    </div>
    <div class="text-item" v-for="text in data
      .filter(t => filter.tab === '' || t.tab === filter.tab)
      .filter(t => filter.tags.every(tag => t.tags.includes(tag)))" :key="text.id">
      <TextPair :text="text" :data="data" v-on:tag="addTag" v-on:fetchall="fetchAll"/>
    </div>
    <!-- 
    <TextTool value="i dnes, I dnes, a je to, A neni! u nás, U nás, v lese, V zádech, o mně, O nás, Z hospody, z hor. K nám, k lesu. Ke dvěřím. Od nás. s. r. o. JUDr. M. Horákové." />
     -->

    <h1 class="title">Přidat nový text (Pozor! Nový text se nevkládá do tabulek.)</h1>
    <div class="form flex-column" @input="clearErrors()">
      <div class="flex-row">
        <label>Identifikátor</label>
        <input v-model="newTextItem.id" type="text" />
      </div>
      <span class="error" v-show="hasError('id')" v-text="getError('id')"></span>
      <TextTool :value="newTextItem.text" @input="newTextItem.text = $event" />
      <span class="error" v-show="hasError('text')" v-text="getError('text')"></span>
      <div class="tab">
        <label for="kat">Kategorie </label>
        <select v-model="newTextItem.tab" id="kat">
          <option 
            v-for="tab in data.filter(text => text.tags.includes('tab'))" 
            :value="tab.id"
            :key="tab.id" 
            v-text="tab.text"
          ></option>
        </select>
      </div>
      <div class="flex-row">
        <label>Tagy:</label>
        <input v-model="tagReciever" type="text" v-on:keydown.space.enter="tagRecieve">
      </div>
      <div class="flex-row">
        <span v-for="tag in newTextItem.tags" :key="tag">{{tag}} <button @click="newTextItemRemoveTag(tag)">x</button></span>
      </div>
      <button @click="addNew" class="primary">Přidat</button>
    </div>
  </div>
</template>

<script>
import api from "../../store/api";
import TextTool from "./texts/TextTool";
import TextPair from "./texts/TextPair";
const NewTextDefault = () => ({
  id: "",
  text: "Lorem ipsum",
  tab: 'application',
  tags: []
});
export default {
  components: { TextTool, TextPair },
  mounted() {
    this.fetchAll();
  },
  data() {
    return {
      newTextItem: NewTextDefault(),
      tagReciever: "",
      errors: {},
      data: [],
      filter: {
        tab: '',
        tags: []
      },
    };
  },
  methods: {
    onImportTexts() {
      this.$store.dispatch('importTextsToAPI');
    },
    onTextTest() {
      this.$store.dispatch('textTest');
    },
    setTab(tab) {
      this.filter.tab = tab;
    },
    addTag(tag) {
      if (!this.filter.tags.includes(tag)) {
        this.filter.tags.push(tag);
      }
    },
    removeTag(tag) {
      if (this.filter.tags.includes(tag)) {
        this.filter.tags = this.filter.tags.filter(t => t != tag);
      }
    },
    newTextItemAddTag(tag) {
      if (!this.newTextItem.tags.includes(tag)) {
        this.newTextItem.tags.push(tag);
      }
    },
    newTextItemRemoveTag(tag) {
      if (this.newTextItem.tags.includes(tag)) {
        this.newTextItem.tags = this.newTextItem.tags.filter(t => t != tag);
      }
    },
    removeTags() {
      this.filter.tags = [];
    },
    isTabActive(tab) {
      return this.filter.tab === tab;
    },
    hasError(param) {
      return this.errors[param];
    },
    getError(param) {
      return this.errors[param];
    },
    clearErrors() {
      this.errors = {};
    },
    fetchAll() {
      api.get('/api/texts/all')
      .then(data => {
        this.data = data;
        console.log(data);
      })
      .catch(err => {
        this.errors = err;
      })
    },
    addNew() {
      api.post("/api/texts/new", this.newTextItem)
      .then(data => {
        this.newTextItem = NewTextDefault();
        this.fetchAll();
        console.log(data);
      })
      .catch(err => {
        this.errors = err;
      })
    },
    tagRecieve() {
      const tag = this.tagReciever.trim();
      if (/^[a-z]+$/i.test(tag)) {
        this.newTextItemAddTag(tag);
        this.tagReciever = ''
      }
    }
  },
};
</script>

<style lang="scss" scope>
.texts {
  .form {
    label {
      font-weight: bold;
      padding-right: 20px;
      margin: auto;
    }
    input {
      line-height: 1.5em;
      flex-grow: 1;
    }
    .error {
      font-weight: bold;
      color: $primary-color;
    }
  }
  .filter-button {
    margin: 5px;
    color: white;
    border: hidden;
    background: darken(cornflowerblue, 20%);
    border-radius: 3px;
    cursor: pointer;
    &.active {
      cursor: default;
      background: cornflowerblue;
    }
  }
  .title-stripe {
    justify-content: space-between;
    i {
      font-size: 2em;
      color: $secondary-color;
      margin-top: auto;
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
    }
  }
  h2 {
    margin-bottom: 0px;
  }
  a {
    color: $secondary-color;
    text-decoration: none;
    &:hover {
      color: darken($secondary-color, 20%);
    }
  }
  .hash {
    font-size: 1.3em;
  }
}
</style>
