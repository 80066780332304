<template>
  <div class="item-diff">
    <div v-if="collection" class="wienerberger">
      <h3>{{collectionTitle}} (<span v-text="collection.length"></span>) <MoreLess v-if="collection.length > 0" :show="show" @click="show=!show" /></h3>
      <div :key="change._id" v-for="change in collection" v-show="show">
          <ItemDiff :change="change" :main="mainItemParam" />              
      </div>
    </div>
  </div>
</template>

<script>
import ItemDiff from './ItemDiff';
import MoreLess from './MoreLess';
export default {
  components: {ItemDiff, MoreLess},
  props: {
    collection: Object,
    collectionTitle: String,
    mainItemParam: String,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    getOperation() {
      return `[${this.change.op}]`
    },
    getMainText() {
      let text = this.change;
      const steps = this.main.split('.');
      steps.forEach(s => {
        if (s in text) {
          text = text[s];
        }
      })
      return text;
    }
  }
};
</script>

<style lang="scss">
.collection-diff {

}
</style>
