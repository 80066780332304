
function updateWhatever(old, update) {
  if (typeof old !== "object" || old == null) {
    if (Array.isArray(old)) return old = [...update];
    return (old = update);
  }

  for (let key in update) {
    if (!Object.prototype.hasOwnProperty.call(update, key)) continue;
    // result = {...result, [key]: updateWhatever(old[key], update[key])};
    old[key] = updateWhatever(old[key], update[key]);
  }
  return old;
}

function getPathValue(object, path) {
  let current = object;
  const steps = path.split('.');
  steps.forEach(step => {
    step = step.trim();
    if (step in current) {
      current = current[step];
    } else {
      const msg = `getPathValue: Unexpected step '${step}' in path '${path}'.`;
      console.error(msg);
      throw msg;
    }
  });
  return current;
}
/*
function test(old, update) {
  console.log({ old });
  console.log({ update });
  console.log({ result: updateWhatever(old, update) });
  console.log("------------------");
}

test({ a: 1, b: 2 }, { b: 1 });
test({ a: 1, b: 2 }, { b: 1, a: 3 });
test(
  { a: 1, nest: { bb: "hello", lock: false } },
  { a: 2, nest: { bb: "ahojda", lock: true } }
);
*/
module.exports = { updateWhatever, getPathValue };
