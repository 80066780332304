<template>
  <div class="text-pair">
    <div class="preview" v-if="preview" @click="preview = !preview">
      <label class="id" v-text="text.id" ></label>
      <div v-text="text.text" class="text ellipsis"></div>
      <div class="tags">
          <button class="filter-button" v-for="tag in text.tags" v-text="tag" :key="tag" @click.stop="emitTag(tag)"></button>
      </div>
    </div>
    <div class="advanced" v-else>
      <div class="title-stripe">
        <span>Editace textu: </span>
        <span v-text="text.id"></span>
        <i @click="remove(text)" v-if="false" class="icon fa fa fa-trash-alt"></i>
      </div>

      <TextTool :value="text.text" @input="saveText(text, $event)" />

      <div class="tab">
        <label for="kat">Kategorie </label>
        <select v-model="text.tab" v-on:change="saveText(text, text.text)" id="kat">
          <option 
            v-for="tab in data.filter(t => t.tags.includes('tab'))" 
            :value="tab.id"
            :key="tab.id" 
            v-text="tab.text"
          ></option>
        </select>
      </div>

      <div class="flex-row">
        <label>Tagy:</label>
        <input v-model="tagReciever" type="text" v-on:keydown.space.enter="tagRecieve">
      </div>

      <div class="flex-row tags">
        <span v-for="tag in text.tags" :key="tag" class="tag">{{tag}} <button @click="removeTag(tag)">x</button></span>
      </div>
      <div class="button-row">

      <button @click="preview = true" class="rollup"><i class="fa fa-arrow-up"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../store/api";

import TextTool from "./TextTool.vue";
export default {
  components: { TextTool },
  props: {
    text: Object,
    data: Array,
  },
  data() {
    return {
      preview: true,
      tagReciver: '',
    };
  },
  methods: {
    saveText(text, event) {
      text.text = event;
      api
        .post("/api/texts/update", text)
        .then((data) => {
          data;
          ////this.fetchAll();
          this.emit('fetchall')
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    remove(text) {
      if (confirm(`Opravdu si přejete smazat text #${text.id}?`)) {
        api
          .post("/api/texts/delete", text)
          .then((data) => {
            data;
            //this.fetchAll();
            this.emit('fetchall')
          })
          .catch((error) => {
            this.errors = error;
          });
      }
    },
    emitTag(tag) {
      this.$emit('tag', tag);
    },
    // tab and tags editor
    setTab(tab) {
      this.text.tab = tab;
    },
    addTag(tag) {
      if (!this.text.tags.includes(tag)) {
        this.text.tags.push(tag);
        this.saveText(this.text, this.text.text);
      }
    },
    removeTag(tag) {
      if (this.text.tags.includes(tag)) {
        this.text.tags = this.text.tags.filter(t => t != tag);
      }
    },
    tagRecieve() {
      const tag = this.tagReciever.trim();
      if (/^[a-z]+$/i.test(tag)) {
        this.addTag(tag);
        this.tagReciever = ''
      }
    }
  },
};
</script>

<style lang="scss" scope>
.text-pair > .preview {
    display: grid;
    width: 100%;
    line-height: 1.6em;
    background: white;
    border-bottom: 1px solid grey;
    grid-template-columns: 1fr 1fr 1fr;
  .id {
    padding: 0 10px;
  }
  .text {
  }
  /* .tags {
      .tag {
          background: pink;
          border: 1px solid darkred;
      } 
  }*/
}
.text-pair > .advanced {
  padding: 10px;
  background: white;
  .title-stripe {
    line-height: 2em;
  }
  .button-row {
    position: relative;
    height: 30px;
  }
  .rollup {
    width: 50px;
    height: 30px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .tags {
      display: inline;
      .tag {
          //background: lightblue;
          //border: 1px solid darkred;
          line-height: 1.5em;
          margin-right: 10px;
          height: 1.5em;
          font-size: 1em;
      }
  }
  border-bottom: 1px solid black;
  border-left: 5px solid lighten($primary-color, 30%);
}
</style>
