const result = {
  namespaced: true,
  state: () => ({
    computations: {
      price: {
        total: 50,
        house: 0,
        surroundings: 0,
        deltas: {},
        warnings: [],
      },
      energetics: {
        hasEnergetics: false,
        outputs: {},
        houseEnergySummary: {},
        referenceHouseEnergySummary: {},
        comparison: {},
        trafficLights:{
          lightTop: 0,
          lightMid: 0,
          lightBot: 0,
        },
        grant: {
          summary: {
            isGrantReached: false,
            grantTotal: 0,
            grantProjectDocumentation: 0,
          },
          partials: {},
        },
        warnings: [],
      }
    },
    compositions: [],
    view3d: [],
    currentTab: "rooms",
  }),
  getters: {
    totalPrice: (state) => state.computations.price.total,
    housePrice: (state) => state.computations.price.house,
    deltas: state => state.computations.price.deltas,
    surroundingsPrice: (state) => state.computations.price.surroundings,
    warnings: state => state.computations.price.warnings,
    currentTab: state => state.currentTab,
    energeticsTrafficLights: state => state.computations.energetics.trafficLights,
    energeticsHouseSummary: state => state.computations.energetics.houseEnergySummary,
    energetics: state => state.computations.energetics,
    energeticsOutputs: state =>  state.computations.energetics.outputs,
    energeticsWarnings: state => state.computations.energetics.warnings,
    hasEnergetics: state => { return state.computations.energetics.hasEnergetics },
    grantNZUSummary: state => state.computations.energetics.grant.summary,
    grantNZUPartials: state => state.computations.energetics.grant.partials,    
  },
  mutations: {
    updatePrice(state, price) {
      state.computations.price = price;
    },
    updateEnergetics(state, energetics) {
      state.computations.energetics = energetics;
    },
    addComposition(state, composition) {
      state.compositions.push(composition);
    },
    addView3d(state, view3d) {
      state.view3d.push(view3d);
    },
    setCurrentTab(state, val) {
      state.currentTab = val;
    },
  },
  actions: {},
};

export default result;