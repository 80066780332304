<template>
  <div class="selectable-id-list">
    <div class="flex-row cat-heading">

    <div class="cat-row">
        <span v-text="tabToName(prerequisite.superCategory)"></span>
        >
        <span v-text="catToName(prerequisite.category, prerequisite.superCategory)"></span>
      </div>
    <div class="flex-row">
      <span>chytrý výběr</span>
      <ToggleButtonVModel v-model="smartMode" />
    </div>
    </div>
    <div class="container" v-if="smartMode">
      <CardSelectionMultiple width="200" :options="relevantCards" :value="prerequisite.list" @input="prerequisite.list = $event" multiple-answer />
    </div>
    <div class="flex-row" v-else>
      <div class="container flex-row wrap">
        <div v-for="idx in prerequisite.list" :key="idx" class="list">
          <div class="idx">
            <label v-text="idx"></label>
            <i @click="remove(idx)" class="fa fa-trash"></i>
          </div>
        </div>
      </div>
      <input v-model="newValue" @keydown.enter="add" placeholder="další ENTER" type="text" class="custom add-next" > 
      <button class="button" @click.prevent="add">přidat</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CardSelectionMultiple, ToggleButtonVModel } from "../../components/customElements";
import cardStaticData from "./cardStaticData";

export default {
  components: { CardSelectionMultiple, ToggleButtonVModel },
  props: {
    prerequisite: Object,
  },
  methods: {
    add() {
      const value = this.newValue.trim();
      console.log(value);
      if (value.length > 0 && 
          this.prerequisite.list.indexOf(value) < 0) { 
        this.prerequisite.list.push(value);
        this.newValue = '';
      }
    },
    remove(id) {
      this.prerequisite.list = this.prerequisite.list.filter(item => item !== id); 
    },
    tabToName(tabValue) {
      const filter = this.staticData.tabs.filter(t => t.value === tabValue);
      if (filter.length > 0) {
        return filter[0].name;
      }
      return "nedohledano";
    },
    catToName(catValue, tabValue) {
      const filter = this.staticData.categories[tabValue].filter(cat => cat.value === catValue);
      if (filter.length > 0) {
        return filter[0].name;
      }
      return "nedohledano";
    },
    
  },
  data() {
    return {
      newValue: "",
      smartMode: false,
      staticData: {
        ...cardStaticData,
      },
    };
  },
  computed: {
    ...mapGetters("cards", ["cards"]),
    relevantCards() {
      const isRelevant = card => card.superCategory === this.prerequisite.superCategory &&
        card.category === this.prerequisite.category;
      return this.cards.filter(isRelevant);
    }
  },
};
</script>

<style lang="scss">
.selectable-id-list {
  .cat-heading {
    justify-content: space-between;
  }
  .container {
    flex-grow: 1;
    min-height: 35px;
    background: white;
    .idx {
      padding: 0 6px 0 12px;
    }
    i {
      padding: 3px;
      font-size: 0.85em;
      cursor: pointer;
      color: lighten($primary-color, 20%);
      &:focus {
        color: lighten($primary-color, 50%);
      }
    }
  }
  input.add-next {
    width: 100px;
     margin-left: 5px;
  }
}
</style>