<template>
  <div class="counter flex-inline">
    <div class="button left" :class="{disabled: disableMinus}" @click="decrement">
      <i class="fa fa-minus-circle"></i>
    </div>
    <input type="number" :value="value" @input="onInput" ref="inputElement" />
    <div class="button right" :class="{disabled: disablePlus}" @click="increment">
      <i class="fa fa-plus-circle"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "CounterInput",
  props: {
    value: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: -1e10
    },
    max: {
      type: Number,
      default: 1e10
    }
  },
  methods: {
    clip(num) {
      return Math.min(this.max, Math.max(this.min, num));
    },
    onInput() {
      this.$emit("input", this.clip(Number(this.$refs.inputElement.value)));
    },
    increment() {
      this.$emit("input", this.clip(Number(this.value) + 1));
    },
    decrement() {
      this.$emit("input", this.clip(Number(this.value) - 1));
    },
  },
  computed: {
    disableMinus() {
      return this.disabled || this.value <= this.min;
    },
    disablePlus() {
      return this.disabled || this.value >= this.max;
    }
  }
};
</script>

<style lang="scss">
$height: 38px;
$gap-size: 6px;
$font-size: 24px;
$width: 120px;
$border-color: #c2c2c2;

/* remove chrome's outline */
textarea:focus,
input:focus,
.button:focus {
  outline: none;
  border: none;
}

.counter {
  align-self: center;
  width: $width;
  font-size: $font-size;
  .button {
    padding: auto;
    margin: auto auto;
    border: 0px solid;
    vertical-align: middle;
    line-height: $height;
    height: $height;
    width: 0;
    //width: $height;
    i {
      line-height: $height;
    }
    font-weight: 900;
    color: lighten(black, 50%);
    background: transparent;
    &.disabled {
        color: lighten(black, 88%);
    }
    //background: lighten(green, 50%);
  }

  input[type="number"] {
    font-weight: 900;
    font-size: $font-size * 0.9;
    border: solid 1px $border-color;
    border-radius: 4px;
    height: $height;
    padding: 0px;
    width: $width;
    text-align: center;
    vertical-align: center;
  }
  .button.left {
    /*position: relative;
    left: $height/2;
*/
    transform: translateX($gap-size);
  }
  .button.right {
    /* position: relative;
    right: $height; */
    transform: translateX(-$font-size - $gap-size);
  }

  .button:not(.disabled) > i {
    cursor: pointer;
  }
  /**/
}

/** Remove spinners */
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
