<template>
  <button 
    @click="$emit('click')" 
    class="primary moreless" 
    :class="{show: show}" 
    v-text="show? 'méně' : 'více'"
  ></button>
</template>

<script>
export default {
    props: {
        show: Boolean
    }
}
</script>

<style lang='scss'>
button.moreless {
    /*cursor: pointer;
    background: $primary-color;
    color: white;
    border: none;
    border-radius: 4px;*/
    width: auto;
    height: auto;
    font-size: 1.0em;    
}
</style>